<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ "ADMINISTRATOR_DASHBOARD" | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-buyer-both">
                            Manage Buyer status requests</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link">
                            Decline right to buy status</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title header-bread-align">Please confirm that you want to decline right to
                buy status for the following organisation {{routeDetails.organisationName}}</h1>
            <div>
                <p class="govuk-body-l">
                    The organisation’s administrator(s) will be notified that the right to buy status has been declined.
                </p>
                <div class="govuk-button-group save-cancel-button-group">
                    <button type="submit" class="govuk-button govuk-button--warning" data-module="govuk-button"
                        (click)="confirmAndDecline('Decline')">
                        Decline
                    </button>
                    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
                        (click)="Back('Cancel')">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>