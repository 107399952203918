<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="content flex">
      <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a *ngIf="this.isOrgAdmin" class="govuk-breadcrumbs__link" routerLink="/home">
                    {{ 'ADMINISTRATOR_DASHBOARD' | translate }}
                </a>
                <a *ngIf="!this.isOrgAdmin" class="govuk-breadcrumbs__link" routerLink="/home">
                    {{"PUBLIC_PROCUREMENT_GATEWAY_DASHBOARD" | translate}}
                </a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
              <a class="govuk-breadcrumbs__link" routerLink="/profile">{{ 'MANAGE_MY_ACCOUNT' | translate }}</a>
          </li>
            <li class="govuk-breadcrumbs__list-item">
                <span class="govuk-breadcrumbs__link"> {{ 'CHANGE_PASSWORD_BTN' | translate }}</span>
            </li>
        </ol>
    </div>
      <h1 class="govuk-heading-xl page-title">{{ 'CHANGE_YOUR_PASSWORD' | translate }}</h1>
      <div class="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
        id="error-summary" data-module="govuk-error-summary" *ngIf="!formValid(formGroup) && submitted">
        <h2 class="govuk-error-summary__title" id="error-summary-title">
          {{ 'ERROR_SUMMARY' | translate }}
        </h2>
        <div class="govuk-error-summary__body">
          <ul class="govuk-list govuk-error-summary__list">
            <li *ngIf="submitted && formGroup.controls.currentPassword.hasError('required')">
              <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">{{
                'ERROR_CURRENT_PASSWORD' | translate }}</a>
            </li>
            <li *ngIf="submitted && formGroup.controls.currentPassword.hasError('invalidCurrentPassword') || formGroup.controls.currentPassword.hasError('required')">
              <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">{{
                'ERROR_INVALID_CURRENT_PASSWORD' | translate }}</a>
            </li>
            <li *ngIf="submitted && formGroup.controls.newPassword.hasError('required')">
              <a href="javascript:;" onClick="return false;" (click)="setFocus(1)">{{
                'ERROR_NEW_PASSWORD' | translate }} </a>
            </li>
            <li
              *ngIf="submitted && (formGroup.controls.newPassword.hasError('pattern') || formGroup.controls.newPassword.hasError('minLength'))">
              <a href="javascript:;" onClick="return false;" (click)="setFocus(1)">{{
                'ERROR_PASSWORD_POLICY' | translate }}</a>
            </li>
            <li *ngIf="submitted && formGroup.controls.newPassword.hasError('passwordContainsUserInfo')">
              <a href="javascript:;" onClick="return false;" (click)="setFocus(1)">{{
                'ERROR_NEW_PASSWORD_CONTAINS_USER_INFO' | translate }} </a>
            </li>
            <li *ngIf="submitted && formGroup.controls.newPassword.hasError('recentlyUsedPassword')">
              <a href="javascript:;" onClick="return false;" (click)="setFocus(1)">{{
                'ERROR_NEW_PASSWORD_RECENTLY_USED' | translate }} </a>
            </li>
            <li
              *ngIf="submitted && (formGroup.controls.confirmPassword.hasError('required') || formGroup.hasError('errorConfirmPassword'))">
              <a href="javascript:;" onClick="return false;" (click)="setFocus(2)">{{
                'ERROR_PASSWORD_MATCH' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
      <p class="govuk-body">Your password must have:</p>
      <ul class="govuk-list govuk-list--bullet">
        <li>at least 10 characters</li>
        <li>at least one symbol (eg !&#64;#$%^&*_)</li>
        <li>at least one uppercase character</li>
        <li>at least one lowercase character</li>
        <li>at least one number</li>
      </ul>
      <p class="govuk-body">Your password should not:</p>
      <ul class="govuk-list govuk-list--bullet">
        <li>contain your user information</li>
        <li>be the same as the last {{usedPasswordThreshold}} passwords used</li>
      </ul>
      <form [class.error]="!formValid(formGroup)" (submit)="onSubmit(formGroup)" [formGroup]="formGroup">
        <div class="govuk-form-group" id="current-password-form-group"
          [class.govuk-form-group--error]="submitted && formGroup.controls.currentPassword.invalid">
          <label class="govuk-label" for="current-password"> {{ 'CURRENT_PASSWORD' | translate }} </label>
          <span id="current-password-error" class="govuk-error-message"
            *ngIf="submitted && formGroup.controls.currentPassword.hasError('required')">
            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{ 'ERROR_CURRENT_PASSWORD' |
            translate }}
          </span>
          <span id="current-password-error" class="govuk-error-message"
           *ngIf="submitted && formGroup.controls.currentPassword.hasError('invalidCurrentPassword')">
          <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{ 'ERROR_INVALID_CURRENT_PASSWORD' |
            translate }}
          </span>
          <input #input formControlName="currentPassword" autocomplete="off" class="govuk-input" id="current-password" name="current-password"
            type="password" [class.govuk-input--error]="submitted && formGroup.controls.currentPassword.invalid">
        </div>
        <div class="govuk-form-group" id="new-password-form-group"
          [class.govuk-form-group--error]="submitted && formGroup.controls.newPassword.invalid">
          <label class="govuk-label" for="new-password"> {{ 'NEW_PASSWORD' | translate }} </label>
          <span id="new-password-error" class="govuk-error-message"
            *ngIf="submitted && formGroup.controls.newPassword.hasError('required')">
            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{ 'ERROR_NEW_PASSWORD' |
            translate
            }}
          </span>
          <span id="new-password-error" class="govuk-error-message"
            *ngIf="submitted && (formGroup.controls.newPassword.hasError('pattern') || formGroup.controls.newPassword.hasError('minLength'))">
            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{ 'ERROR_PASSWORD_POLICY' |
            translate }}
          </span>
          <span id="new-password-error" class="govuk-error-message"
            *ngIf="submitted && formGroup.controls.newPassword.hasError('passwordContainsUserInfo')">
            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
            'ERROR_NEW_PASSWORD_CONTAINS_USER_INFO' | translate
            }}
          </span>
          <span id="new-password-error" class="govuk-error-message"
            *ngIf="submitted && formGroup.controls.newPassword.hasError('recentlyUsedPassword')">
            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
            'ERROR_NEW_PASSWORD_RECENTLY_USED' | translate
            }}
          </span>
          <input #input formControlName="newPassword" class="govuk-input" id="new-password" name="new-password"
            type="password" autocomplete="off" [class.govuk-input--error]="submitted && formGroup.controls.newPassword.invalid">
        </div>
        <div class="govuk-form-group" id="confirm-password-form-group"
          [class.govuk-form-group--error]="submitted && (formGroup.controls.confirmPassword.invalid || formGroup.hasError('errorConfirmPassword'))">
          <label class="govuk-label" for="confirm-password"> {{ 'CONFIRM_NEW_PASSWORD' | translate }} </label>
          <span id="confirm-password-error" class="govuk-error-message"
            *ngIf="submitted && (formGroup.controls.confirmPassword.hasError('required') || formGroup.hasError('errorConfirmPassword'))">
            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{ 'ERROR_PASSWORD_MATCH' |
            translate }}
          </span>
          <input #input formControlName="confirmPassword" class="govuk-input" id="confirm-password" name="confirm-password"
            type="password" autocomplete="off" [class.govuk-input--error]="submitted && (formGroup.controls.confirmPassword.invalid || formGroup.hasError('errorConfirmPassword'))">
        </div>
        <div class="govuk-button-group save-cancel-button-group">
          <button type="submit" class="govuk-button" (click)="pushDataLayerEvent('Change password')" data-module="govuk-button">
            {{'CHANGE_PASSWORD_BTN' | translate}}
          </button>
          <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
            data-module="govuk-button">
            {{ 'CANCEL_BTN' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>