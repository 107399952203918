<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex" *ngIf="item$ | async as i">
    <a routerLink="/manage-org/register/search" class="govuk-back-link">Back</a>
    <h1 class="govuk-heading-xl page-title">Confirm your organisation's details</h1>
    <div>
      <div>
        <p><strong style="font-weight: 500;">{{ i?.name }}</strong></p>
        <p>
          {{ i?.address?.streetAddress }}<br *ngIf="i?.address?.streetAddress" />
          {{ i?.address?.locality }}<br *ngIf="i?.address?.locality" />
          {{ i?.address?.region }}<br *ngIf="i?.address?.region" />
          {{ i?.address?.postalCode }}<br *ngIf="i?.address?.postalCode" />
          {{ i?.address?.countryName }}
        </p>
        <p><strong style="font-weight: 500;">{{ schemeName }} registration number</strong><br />{{ i?.identifier?.id }}
        </p>
        <div class="govuk-form-group">
          <fieldset class="govuk-fieldset">
            <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
              Is this your organisation?
            </legend>
            <div class="govuk-radios">
              <div class="govuk-radios__item" id="correctDetailsRadioSelection">
                <input class="govuk-radios__input" type="radio" name="orgGroup" checked="checked"
                  value="manage-org/register/user" [(ngModel)]="orgGroup" id="correctDetailsRadioButton">
                <label class="govuk-label govuk-radios__label" for="correctDetailsRadioButton">
                  Yes, and the details are correct
                </label>
              </div>
              <div class="govuk-radios__item" id="wrongAddressRadioSelection">
                <input class="govuk-radios__input" type="radio" name="orgGroup"
                  value="manage-org/register/error/wrong-details" [(ngModel)]="orgGroup"id="wrongAddressRadioButton">
                <label class="govuk-label govuk-radios__label" for="wrongAddressRadioButton">
                  Yes, but the address is wrong
                </label>
              </div>
              <div class="govuk-radios__item" id="notMyOrgRadioSelection">
                <input class="govuk-radios__input" type="radio" name="orgGroup"
                  value="manage-org/register/error/not-my-details" [(ngModel)]="orgGroup" id="notMyOrgRadioButton">
                <label class="govuk-label govuk-radios__label" for="notMyOrgRadioButton">
                  No, this isn't my organisation
                </label>
              </div>
            </div>
  
          </fieldset>
        </div>
  
        <button type="button" class="govuk-button" data-module="govuk-button"
          (click)="onSubmit()">Continue</button>
      </div>
    </div>
  </div>