<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex" *ngIf="org$ | async as o">
    <div class="govuk-breadcrumbs">
      <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/home">{{
            "ADMINISTRATOR_DASHBOARD" | translate
          }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/buyer-supplier/search"
            >Manage service eligibility</a
          >
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <span class="govuk-breadcrumbs__link"
            >Review organisations’ type and services</span
          >
        </li>
      </ol>
    </div>
    <h1 class="govuk-heading-xl page-title">
        Review organisations’ type and services
    </h1>
    <div class="govuk-notification-banner" role="region" aria-labelledby="govuk-notification-banner-title" data-module="govuk-notification-banner" *ngIf="autoValidationPending">
      <div class="govuk-notification-banner__header">
        <h2 class="govuk-notification-banner__title" id="govuk-notification-banner-title">
          Organisation Under Review
        </h2>
      </div>
      <div class="govuk-notification-banner__content">
        <p class="govuk-notification-banner__heading">
          This organisation is currently awaiting verification of its buyer status. You won't be able to manage this organisation's eligibility until the verification process is complete.
        </p>
      </div>
    </div>
    <div class="govuk-form-group">
      <fieldset class="govuk-fieldset">
        <h2 class="govuk-heading-s">
          Update organisation type
        </h2>
          <div class="govuk-radios">
              <div class="govuk-radios__item" id="orgAdminRadioSelection" >
                  <input class="govuk-radios__input" type="radio" id="supplierRadioButton" value="0"
                      [(ngModel)]="adminSelectionMode" (click)="onSelect(0,'html')" [attr.disabled]="autoValidationPending"/>
                  <label for="supplierRadioButton" class="govuk-label govuk-radios__label">
                    Supplier
                  </label>
              </div>
              <div class="govuk-radios__item" id="nominateAdminRadioSelection">
                  <input class="govuk-radios__input" type="radio" id="buyerRadioButton" value="1"
                      [(ngModel)]="adminSelectionMode"  (click)="onSelect(1,'html')" [attr.disabled]="autoValidationPending" />
                  <label for="buyerRadioButton" class="govuk-label govuk-radios__label">
                    Buyer
                  </label>
              </div>
              <div class="govuk-radios__item" id="unknownAdminRadioSelection">
                  <input class="govuk-radios__input" type="radio" id="bothRadioButton" value="2"
                      [(ngModel)]="adminSelectionMode" (click)="onSelect(2,'html')"  [attr.disabled]="autoValidationPending"/>
                  <label for="bothRadioButton" class="govuk-label govuk-radios__label">
                    Both
                  </label>
              </div>
          </div>
      </fieldset>
    </div>
    <div
    class="govuk-form-group"
    style="margin-top: 30px"
  >
    <fieldset class="govuk-fieldset">
      <h2 class="govuk-heading-s">
        Services for this organisation, select/unselect where appropriate
      </h2>
      <div class="govuk-checkboxes" *ngIf="orgEligableRoles$ | async as o">
        <ng-container *ngFor="let role of roles">
          <div class="govuk-checkboxes__item govuk-item-custom" *ngIf="role.isDeleted === false && tradeEligibilityStatus(role) && role.orgTypeEligibility !== 0 && !hideService(role.roleKey)">
            <input
              type="checkbox"
              [value]="role.roleId"
              [checked]="role.enabled"
              (change)="onChange($event, role?.enabled, role)"
              class="govuk-checkboxes__input"
              id="orgRoleControl_{{ role.roleId }}"
              [attr.disabled]="autoValidationPending"
            />
            <label
              class="govuk-label govuk-checkboxes__label"
              [ngClass]="{'role_lable': showRoleView === false,'govuk-!-padding-top-0': role.description != ''}"
              for="orgRoleControl_{{ role.roleId }}"
              >{{ role.roleName }}
              </label>
              <p class="govuk-body description_para">{{role.description}} </p>
          </div>
        </ng-container>
      </div>
    </fieldset>
  </div>
    <div class="govuk-button-group" style="margin-top: 40px">
      <button
       *ngIf="!autoValidationPending"
        type="button"
        class="govuk-button"
        data-module="govuk-button"
        (click)="onSubmitClick('Continue')"
      >
        Continue
      </button>
      <button
        type="button"
        class="govuk-button govuk-button--secondary"
        data-module="govuk-button"
        (click)="onCancelClick('Cancel')"
      >
        Cancel
      </button>
    </div>
  </div>