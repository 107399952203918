<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
    </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
     </li>
        <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link"> {{ 'BUYER_TYPE_BC' | translate }}</span>
        </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">What type of buyer are you?</h1>
  <h2 style="margin-bottom: 35px;">To help us sign you in as fast as possible, please state what type of buyer you are. Crown Commercial Service is open to government, public sector, charity, and third party sector.</h2>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-three-quarters">
      <form class="govuk-!-width-three-quarters">
        <div class="govuk-form-group">
          <fieldset class="govuk-fieldset">
            <div class="govuk-radios">
              <div class="govuk-radios__item">
                <input class="govuk-radios__input" id="centralGovernmentRadioButton" name="buyerType" type="radio" value="Central Government" [(ngModel)]="defaultChoice">
                <label class="govuk-label govuk-radios__label" for="centralGovernmentRadioButton">
                  Central Government
                </label>
              </div>
              <div class="govuk-radios__item">
                <input class="govuk-radios__input" id="publicSectorRadioButton" name="buyerType" type="radio" value="Wider public sector/Local authority" [(ngModel)]="defaultChoice">
                <label class="govuk-label govuk-radios__label" for="publicSectorRadioButton">
                  Wider public sector/Local authority
                </label>
              </div>
              <div class="govuk-radios__item">
                <input class="govuk-radios__input" id="charityRadioButton" name="buyerType" type="radio" value="Charity" [(ngModel)]="defaultChoice">
                <label class="govuk-label govuk-radios__label" for="charityRadioButton">
                  Charity
                </label>
              </div>
              <div class="govuk-radios__item">
                <input class="govuk-radios__input" id="thirdSectorRadioButton" name="buyerType" type="radio" value="Third sector" [(ngModel)]="defaultChoice">
                <label class="govuk-label govuk-radios__label" for="thirdSectorRadioButton">
                  Third sector
                  <span style="display: block; font-size: 14px; color: #6F777B;">(Not for personal profit organisation)</span>
                </label>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="govuk-form-group">
          <button id="continueButton" type="button" class="govuk-button" data-module="govuk-button" (click)="onSubmit()">Continue</button>
        </div>
      </form>
    </div>
  </div>
</div>