<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/buyer-supplier/search">Manage service eligibility</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <span class="govuk-breadcrumbs__link">Success</span>
        </li>
    </ol>
  </div>
  <p class="govuk-body-l" style="margin-top: 50px;">You have successfully saved the changes </p>
  <p><a class="navigation-text" href="javascript:;" [routerLink]="['/buyer-supplier/search']" [queryParams]="{isNewTab:true}" >Return to Manage service eligibility</a></p>
</div>