<div
  class="govuk-error-summary contact-form-error"
  aria-labelledby="error-summary-title"
  role="alert"
  tabindex="-1"
  id="error-summary"
  data-module="govuk-error-summary"
>
  <h2 class="govuk-error-summary__title" id="error-summary-title">
    {{ "ERROR_SUMMARY" | translate }}
  </h2>
  <div class="govuk-error-summary__body">
    <ul class="govuk-list govuk-error-summary__list">
      <li>
        <a href="javascript:;" onClick="return false;">An unexpected error has occurred. Please try again in a few minutes</a>
      </li>
    </ul>
  </div>
</div>
