<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="content flex">
      <div>
        <p class="govuk-body-l">
          {{ 'MFA_RESET_EMAIL_SENT' | translate }} {{ userName }}
        </p>

        <a href="javascript:;" class="navigation-text" (click)="onNavigateLinkClick()"> {{
          'RETURN_TO_LOGIN_PAGE' | translate }} </a>
        <br>
      </div>
    </div>
  </div>
</div>