<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="govuk-breadcrumbs">
            <ol class="govuk-breadcrumbs__list">
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/manage-users">{{ 'MANAGE_YOUR_USER_ACCOUNTS' |
                        translate
                        }}</a>
                </li>
                <li *ngIf="isEdit != true" class="govuk-breadcrumbs__list-item">
                    <span class="govuk-breadcrumbs__link"> {{ 'CREATE_NEW_USER_ACCOUNT' | translate }}</span>
                </li>
                <li *ngIf="isEdit == true" class="govuk-breadcrumbs__list-item">
                    <span class="govuk-breadcrumbs__link"> {{ 'EDIT_USER_ACCOUNTS' | translate }}</span>
                </li>
            </ol>
        </div>

        <h1 *ngIf="isEdit != true" class="govuk-heading-xl page-title">{{ 'CREATE_NEW_USER_ACCOUNT' | translate }}</h1>
        <h1 *ngIf="isEdit == true" class="govuk-heading-xl page-title">{{ 'EDIT_USER_ACCOUNTS' | translate }}</h1>
        <div *ngIf="isEdit && isDormantUser" class="govuk-notification-banner" role="region"
            aria-labelledby="govuk-notification-banner-title" data-module="govuk-notification-banner">
            <div class="govuk-notification-banner__header">
                <h2 class="govuk-notification-banner__title" id="govuk-notification-banner-title">
                    {{'DORMANT_STATE_HEADING' | translate}}
                </h2>
            </div>
            <div class="govuk-notification-banner__content">
              <p class="govuk-notification-banner__heading">
                {{'DORMANT_STATE_MESSAGE' | translate}} <br>
                <a class="govuk-notification-banner__link" [routerLink]="['/manage-users/confirm-user-reactivate']" [queryParams]="{isNewTab:true}" (click)="onReactivateUserClick()">
                  {{'REACTIVATE_ACCOUNT' | translate}}
                </a>
              </p>
            </div>
        </div>
        <h2 *ngIf="isEdit == true" class="govuk-heading-m page-title">{{ 'RESET_PASSWORD_OR_DELETE_USER_ACCOUNTS' |
            translate }}</h2>
        <div class="govuk-error-summary user-profile-form-error" aria-labelledby="error-summary-title" role="alert"
            tabindex="-1" id="error-summary" data-module="govuk-error-summary"
            *ngIf="(formValid(formGroup) == false || mfaAdminValidationError || formGroup.hasError('identityProviderRequired')) && submitted">
            <h2 class="govuk-error-summary__title" id="error-summary-title">
                {{ 'ERROR_SUMMARY' | translate }}
            </h2>
            <div class="govuk-error-summary__body">
                <ul class="govuk-list govuk-error-summary__list">
                    <li
                        *ngIf="submitted && ((!formGroup.controls.lastName.errors?.required && formGroup.controls.lastName.invalid) || (!formGroup.controls.firstName.errors?.required && formGroup.controls.firstName.invalid))">
                        <a href="javascript:;" onClick="return false;" (click)="customFocus()">
                            {{ 'ENTER_NAME_WITHOUT_NUM_SPECIAL' | translate }}</a>
                    </li>
                    <li *ngIf="submitted && formGroup.controls.firstName.errors?.required">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{'ERROR_FIRST_NAME' | translate }} </a>
                    </li>
                    <li *ngIf="submitted && formGroup.controls.lastName.errors?.required">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(1)">
                            {{'ERROR_LAST_NAME' | translate }}</a>
                    </li>                    
                    <li *ngIf="submitted && formGroup.controls.userName.hasError('required')">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(2)">
                            {{'ERROR_EMAIL_REQUIRED' | translate }}</a>
                    </li>
                    <li *ngIf="submitted && formGroup.controls.userName.hasError('alreadyExists')">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(2)">
                            {{'ERROR_USER_ALREADY_EXISTS' | translate }}</a>
                    </li>
                    <li *ngIf="submitted && mfaAdminValidationError">
                        <a href="javascript:;" onClick="return false;" (click)="scrollToAnchor('user-mfa-form-group')">
                            {{ 'MFA_ENABLED_ADMIN_USER'| translate }}</a>
                    </li>
                    <li
                        *ngIf="submitted && !formGroup.controls.userName.errors?.required && formGroup.controls.userName.invalid && !emailHaserror">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(2)">
                            {{ 'ERROR_INVALID_EMAIL'| translate }}</a>
                    </li>
                    <li *ngIf="submitted && formGroup.hasError('identityProviderRequired')">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(3)">
                            {{ 'ERROR_SIGN_IN_PROVIDER_REQUIRED'| translate }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form">
            <form class="ui form profile mb-two" [formGroup]="formGroup" (submit)="onSubmit(formGroup)">
                <div class="ui segment">
                    <div class="govuk-form-group" id="user-title-form-group">
                        <label class="govuk-label" for="userTitle">{{ 'TITLE' | translate }} (Optional)</label>
                        <select [class.disabled]="isEdit && isDormantUser"
                            [attr.disabled]="isEdit && isDormantUser ? 'disabled' : null" class="govuk-select"
                            id="userTitle" name="userTitle" formControlName="userTitle">
                            <option hidden disabled selected value style="display:none" [ngValue]='null'></option>
                            <option *ngFor="let item of userTitleArray" [ngValue]="item">{{item}}</option>
                        </select>
                    </div>
                    <div class="govuk-form-group" id="first-name-form-group"
                        [class.govuk-form-group--error]="submitted && formGroup.controls.firstName.invalid">
                        <label class="govuk-label" for="first-name"> {{ 'FIRSTNAME' | translate }} </label>
                        <span id="first-name-error" class="govuk-error-message"
                            *ngIf="submitted && formGroup.controls.firstName.errors?.required">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_FIRST_NAME' | translate }}
                        </span>
                        <span id="firstname-error" class="govuk-error-message"
                            *ngIf="submitted && !formGroup.controls.firstName.errors?.required && formGroup.controls.firstName.invalid">
                            <span class="govuk-visually-hidden">Error:</span>{{ 'ENTER_NAME_WITHOUT_NUM_SPECIAL' |
                            translate }}
                        </span>
                        <input #input [readonly]="isEdit && isDormantUser" [class.disabled]="isEdit && isDormantUser"
                            formControlName="firstName" class="govuk-input" id="first-name" name="first-name"
                            type="text" [class.govuk-input--error]="submitted && formGroup.controls.firstName.invalid">
                    </div>
                    <div class="govuk-form-group" id="last-name-form-group"
                        [class.govuk-form-group--error]="submitted && formGroup.controls.lastName.invalid">
                        <label class="govuk-label" for="last-name"> {{ 'LASTNAME' | translate }} </label>
                        <span id="last-name-error" class="govuk-error-message"
                            *ngIf="submitted && formGroup.controls.lastName.errors?.required">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_LAST_NAME'
                            | translate }}
                        </span>
                        <span id="lastname-error" class="govuk-error-message"
                            *ngIf="submitted && !formGroup.controls.lastName.errors?.required && formGroup.controls.lastName.invalid">
                            <span class="govuk-visually-hidden">Error:</span>{{ 'ENTER_NAME_WITHOUT_NUM_SPECIAL' |
                            translate }}
                        </span>
                        <input #input [readonly]="isEdit && isDormantUser" [class.disabled]="isEdit && isDormantUser"
                            formControlName="lastName" class="govuk-input" id="last-name" name="last-name" type="text"
                            [class.govuk-input--error]="submitted && formGroup.controls.lastName.invalid">
                    </div>
                    <div class="govuk-form-group" id="user-name-form-group" [class.govuk-form-group--error]="submitted && (formGroup.controls.userName.invalid
                        || formGroup.controls.userName.hasError('alreadyExists'))">
                        <label class="govuk-label" for="email">{{ 'EMAIL' | translate }}</label>
                        <span id="user-name-error" class="govuk-error-message"
                            *ngIf="submitted && formGroup.controls.userName.hasError('required')">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                            {{'ERROR_EMAIL_REQUIRED' | translate }}
                        </span>
                        <span id="user-name-error" class="govuk-error-message"
                            *ngIf="submitted && !formGroup.controls.userName.errors?.required && formGroup.controls.userName.invalid  && !emailHaserror">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                            {{ 'ERROR_INVALID_EMAIL'| translate }}
                        </span>
                        <span id="user-name-error" class="govuk-error-message"
                            *ngIf="submitted && formGroup.controls.userName.hasError('alreadyExists')">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                            {{ 'ERROR_USER_ALREADY_EXISTS'| translate }}
                        </span>
                        <input #input [readonly]="isEdit == true" [class.disabled]="isEdit == true"
                            formControlName="userName" class="govuk-input" id="email" name="email" type="email"
                            size="20" value="" autocomplete="email" spellcheck="false"
                            (input)="validateEmailLength($event)" [class.govuk-input--error]="(submitted && formGroup.controls.userName.invalid)
                            || formGroup.controls.userName.hasError('alreadyExists')">
                    </div>
                    <div class="govuk-radio">
                        <app-govuk-radio [details]="userTypeDetails"
                            (clickedData)="onUserTypeChanged($event)"></app-govuk-radio>
                    </div>
                    <div class="govuk-form-group" *ngIf="!isCustomMfaEnabled" id="user-mfa-form-group"
                        [class.govuk-form-group--error]="submitted && (mfaAdminValidationError)">
                        <fieldset class="govuk-fieldset">
                            <h2 class="govuk-heading-s">
                                Additional security
                            </h2>
                            <p class="govuk-hints">{{ detailsData[0] }}</p>
                            <span id="mfa-error-admin" class="govuk-error-message label-detail-text-gap"
                                *ngIf="submitted && mfaAdminValidationError">
                                <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                                {{ 'MFA_ENABLED_ADMIN_USER'| translate }}
                            </span>
                            <div class="govuk-checkboxes__item label-detail-text-gap">
                                <input class="govuk-checkboxes__input" id="mfachkBox" formControlName="mfaEnabled"
                                    [attr.disabled]="(isAutoDisableMFA == true || isDormantUser ==true) ? true : null" type="checkbox">
                                <label class="govuk-label govuk-checkboxes__label" for="mfachkBox">
                                    Additional security using digital device
                                </label>
                            </div>
                        </fieldset>
                    </div>
                    <div class="govuk-form-group" *ngIf="isCustomMfaEnabled && isEdit">
                        <fieldset class="govuk-fieldset">
                            <h2 class="govuk-heading-s">
                                {{'TWO_FACTOR_AUTHENTICATION_ORG_REGISTERATION' | translate}}
                            </h2>
                            <p *ngIf="isMfaEnabledForUser && isUserMfaOpted" class="govuk-hint" style="color: black;">
                                Currently <strong>{{'ON' | translate}}</strong> for this account.
                                 <!-- using the <strong>{{authenticationType}}.</strong> -->
                            </p>
                            <p *ngIf="!isMfaEnabledForUser && isUserMfaOpted" class="govuk-hint" style="color: black;">
                                Currently <strong> {{'MFA_OFF'| translate }} </strong> for this account.</p>
                            <p *ngIf="!isUserMfaOpted" class="govuk-hint" style="color: black;">
                                Waiting for user action.</p>
                            <div>
                                <details class="govuk-details" data-module="govuk-details">
                                    <summary class="govuk-details__summary">
                                        <span class="govuk-details__summary-text">
                                            {{'WHAT_IS_2FA' | translate}}
                                        </span>
                                    </summary>
                                    <div class="govuk-details__text">
                                        <p>
                                            {{'2FA_HELPTEXT' | translate}}
                                        </p>
                                    </div>
                                </details>
                            </div>
                            <div class="govuk-radios" data-module="govuk-radios">
                                <div class="govuk-radios__item">
                                    <input class="govuk-radios__input" id="mfa-on" name="mfaEnabled" type="radio"
                                        [value]="true" formControlName="mfaEnabled" [attr.disabled]="true">
                                    <label class="govuk-label govuk-radios__label" for="mfa-on">
                                        <strong> {{'MFA_ON' | translate}} </strong>
                                    </label>
                                </div>
                                <div class="govuk-radios__item">
                                    <input class="govuk-radios__input" id="mfa-off" name="mfaEnabled" type="radio"
                                        [value]="false" formControlName="mfaEnabled" [attr.disabled]="true">
                                    <label class="govuk-label govuk-radios__label" for="mfa-off">
                                        <strong> {{'MFA_OFF'| translate }} </strong>
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div
                        *ngIf="(isCustomMfaEnabled && isEdit && isMfaEnabledForUser) || (!isUserMfaOpted && isCustomMfaEnabled && isEdit)">
                        <div id="reset-mfa-hint" class="govuk-hint" style="margin-top: 15px;">
                            {{ 'RESET_USER_MFA_HINT' | translate }}
                        </div>
                        <button type="button" (click)="ResetAdditionalSecurity('Reset user\'s 2FA')" class="govuk-button"
                            data-module="govuk-button" [disabled]="!isUserMfaOpted || isDormantUser">{{
                            'RESET_2FA_USER_BTN' |
                            translate }}
                        </button>
                    </div>
                    <fieldset class="govuk-fieldset">
                        <h2 class="govuk-heading-s" id="GROUPS">
                            {{ 'GROUPS'| translate }} (optional)
                        </h2>
                        <app-details [detailsData]="detailsData[1]"></app-details>
                    </fieldset>
                    <div *ngIf="isEdit" class="sadf">
                        <app-accordion [headerTextKeys]="groupsMember.headerTextKey"
                            [headerText]="groupsMember.headerText" [accessTable]="groupsMember.accessTable"
                            [data]="groupsMember.data" [isAdmin]="groupsMember.isAdmin"
                            [groupShow]="groupsMember.groupShow" [noRoleText]="groupsMember.noRoleText"
                            (checkBoxAddRoles)="groupsMemberCheckBoxAddRoles($event)"
                            (checkBoxRemoveRoles)="groupsMemberCheckBoxRemoveRoles($event)"></app-accordion>
                    </div>
                    <div>
                        <app-accordion [headerTextKeys]="noneGroupsMember.headerTextKey"
                            [headerText]="noneGroupsMember.headerText" [accessTable]="noneGroupsMember.accessTable"
                            [data]="noneGroupsMember.data" [isAdmin]="noneGroupsMember.isAdmin"
                            [groupShow]="noneGroupsMember.groupShow" [noRoleText]="noneGroupsMember.noRoleText"
                            (checkBoxAddRoles)="noneGroupsMemberCheckBoxAddRoles($event)"
                            (checkBoxRemoveRoles)="noneGroupsMemberCheckBoxRemoveRoles($event)"></app-accordion>
                    </div>
                    <!-- <div class="govuk-form-group" id="user-group-form-group">
                        <fieldset class="govuk-fieldset">
                            <legend class="govuk-heading-s">
                                {{ 'GROUPS'| translate }} (Optional)
                            </legend>
                            <app-details [detailsData]="detailsData[1]"></app-details>
                            <div *ngIf="orgGroups.length == 0">
                                <p>{{'NONE'| translate}}
                                <p>
                            </div>
                            <div *ngIf="orgGroups.length > 0" class="govuk-checkboxes">
                                <div class="govuk-checkboxes__item" *ngFor="let orgGroup of orgGroups; let i=index">
                                    <input class="govuk-checkboxes__input"
                                        id="{{'orgGroupControl_' + orgGroup.groupId}}"
                                        [formControlName]="'orgGroupControl_' + orgGroup.groupId" type="checkbox"
                                        value="hmrc">
                                    <label class="govuk-label govuk-checkboxes__label"
                                        for="{{'orgGroupControl_' + orgGroup.groupId}}">
                                        {{orgGroup.groupName}}
                                    </label>
                                    <a class="navigation-text" href="javascript:;" *ngIf="hasGroupViewPermission"
                                        (click)="onGroupViewClick(orgGroup.groupId)">
                                        {{'VIEW_DETAILS' | translate}}</a>
                                </div>
                            </div>

                        </fieldset>
                    </div> -->

                    <div class="govuk-form-group" id="user-role-form-group" *ngIf="showRoleView">
                        <fieldset class="govuk-fieldset">
                            <legend class="govuk-heading-s">
                                {{ 'ROLES'| translate }}
                            </legend>
                            <app-details [detailsData]="detailsData[2]"></app-details>
                            <div>
                                <span class="table_caption">To help you choose the correct roles use the table
                                    below:</span>
                                <table style="width:100%">
                                    <tr>
                                        <th>For whom</th>
                                        <th>Task</th>
                                        <th>Roles to tick</th>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="column_one_space">
                                            Organisational Administrators of Public Procurement Gateway (PPG)
                                        </td>
                                        <td class="litrel_space">Add an additional PPG administrator</td>
                                        <td class="roles_padding">
                                            <ul class="listing">
                                                <li>Organisation Administrator - Dashboard Service</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="litrel_space" class="column_one_space">Add user to PPG</td>
                                        <td class="roles_padding">
                                            <ul class="listing">
                                                <li>Organisation User - Dashboard Service</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td rowspan="2" class="column_one_space">
                                            Buyers
                                        </td>
                                        <td class="litrel_space">Enable Contract Award Service</td>
                                        <td class="roles_padding">
                                            <ul class="listing">
                                                <li>eSourcing Service as a buyer</li>
                                                <li>Contract Award Service (CAS) - add service</li>
                                                <li>Contract Award Service (CAS) - add to dashboard</li>
                                                <li>eSourcing Service - add service</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="litrel_space">Enable eSourcing</td>
                                        <td class="roles_padding">
                                            <ul class="listing">
                                                <li>eSourcing Service as a buyer
                                                </li>
                                                <li>eSourcing Service - add service
                                                </li>
                                                <li>eSourcing Service - add to dashboard
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="column_one_space">Suppliers</td>
                                        <td class="litrel_space">Enable eSourcing</td>
                                        <td class="roles_padding">
                                            <ul class="listing">
                                                <li>eSourcing Service as a supplier</li>
                                                <li>eSourcing Service - add service</li>
                                                <li>eSourcing Service - add to dashboard</li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                </table>
                            </div>
                            <br>
                            <div class="govuk-checkboxes">
                                <div class="govuk-checkboxes__item" *ngFor="let orgRole of orgRoles; let i=index">
                                    <input class="govuk-checkboxes__input" id="{{'orgRoleControl_' + orgRole.roleId}}"
                                        [attr.disabled]="isEdit && orgRole.roleKey === 'ORG_DEFAULT_USER' ? true : null"
                                        [formControlName]="'orgRoleControl_' + orgRole.roleId" type="checkbox"
                                        (ngModelChange)="onUserRoleChecked(orgRole, $event)" value="hmrc">
                                    <label *ngIf="orgRole.serviceName == null"
                                        class="govuk-label govuk-checkboxes__label"
                                        for="{{'orgRoleControl_' + orgRole.roleId}}">
                                        {{orgRole.roleName}}
                                    </label>
                                    <label *ngIf="orgRole.serviceName != null"
                                        class="govuk-label govuk-checkboxes__label"
                                        for="{{'orgRoleControl_' + orgRole.roleId}}">
                                        {{orgRole.roleName}} - {{orgRole.serviceName}}
                                    </label>
                                    <strong class="govuk-tag govuk-tag--grey" *ngIf="orgRole.pendingStatus">
                                        Pending Approval
                                    </strong>
                                </div>
                            </div>

                        </fieldset>
                    </div>
                    <div class="govuk-form-group" id="user-role-form-group" *ngIf="!showRoleView">
                        <fieldset class="govuk-fieldset">
                            <h2 class="govuk-heading-s">
                                {{ 'SERVICES'| translate }}
                            </h2>
                            <p class="govuk-hints">{{ 'ACCESS_TO_USER_ROLE'| translate }}</p>

                            <div class="govuk-tabs" data-module="govuk-tabs">
                                <ul class="govuk-tabs__list">
                                    <li class="govuk-tabs__list-item"
                                        [ngClass]="{'govuk-tabs__list-item--selected' : tabConfig.userservices}"
                                        (click)="tabChanged('user-service')">
                                        <a class="govuk-tabs__tab" href="javascript:;" onClick="return false;">
                                            Individual access
                                        </a>
                                    </li>
                                    <li class="govuk-tabs__list-item"
                                        [ngClass]="{'govuk-tabs__list-item--selected' : tabConfig.groupservices}"
                                        (click)="tabChanged('group-service')">
                                        <a class="govuk-tabs__tab" href="javascript:;" onClick="return false;">
                                            Group access
                                        </a>
                                    </li>
                                </ul>
                                <div class="govuk-tabs__panel" id="user-service"
                                    [ngClass]="{'govuk-tabs__panel--hidden' : !tabConfig.userservices}">
                                    <div class="govuk-grid-row">
                                        <div class="govuk-grid-column-full">
                                            <h2 class="govuk-heading-m">Individual access</h2>
                                            <p class="govuk-hints">{{ 'ACCESS_TO_USER_SERVICE'| translate }}</p>
                                            <div class="govuk-checkboxes">
                                                <ng-container *ngFor="let orgRole of orgRoles; let i=index">
                                                    <div class="govuk-checkboxes__item govuk-item-custom"
                                                        *ngIf="orgRole.roleKey != 'ORG_DEFAULT_USER' && orgRole.roleKey != 'ORG_ADMINISTRATOR'">
                                                        <input class="govuk-checkboxes__input"
                                                            id="{{'orgRoleControl_' + orgRole.roleId}}"
                                                            [disabled]="((isEdit && isDormantUser ) ||  (isEdit && getDisbleRoleForService(orgRole.roleKey)))"
                                                            [formControlName]="'orgRoleControl_' + orgRole.roleId"
                                                            type="checkbox"
                                                            (ngModelChange)="onUserRoleChecked(orgRole, $event)"
                                                            value="hmrc">
                                                        <label class="govuk-label govuk-checkboxes__label role_lable"
                                                            [ngClass]="{'govuk-!-padding-top-0': orgRole.description != ''}"
                                                            for="{{'orgRoleControl_' + orgRole.roleId}}">
                                                            {{orgRole.roleName}}
                                                        </label>
                                                        <strong class="govuk-tag govuk-tag--grey"
                                                            *ngIf="orgRole.pendingStatus">
                                                            Pending Approval
                                                        </strong>
                                                        <p class="govuk-body description_para govuk-!-padding-top-0">
                                                            {{orgRole.description}} </p>
                                                    </div>
                                                </ng-container>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="govuk-tabs__panel" id="group-service"
                                    [ngClass]="{'govuk-tabs__panel--hidden' : !tabConfig.groupservices}">
                                    <h2 class="govuk-heading-m">Group access</h2>
                                    <p class="govuk-hints" *ngIf="orgUserGroupRoles.length > 0 ? true : false">{{
                                        'ACCESS_TO_GROUP_SEVICE'| translate }}
                                        <a class="navigation-text" (click)="scrollContent('GROUPS')">manage group
                                            membership</a> {{ 'ORG_ADMIN_GROUP_MEMBER'| translate }}
                                    </p>
                                    <p class="govuk-hints" *ngIf="orgUserGroupRoles.length <= 0 ? true : false">{{
                                        'NO_GROUP_ROLE_ACCESS_ADDUSER'| translate }}
                                        <a class="navigation-text" (click)="scrollContent('GROUPS')">manage group
                                            membership</a> {{'NO_GROUP_ROLE_ACCESS_ADDUSER_END' | translate}}
                                    </p>
                                    <div class="govuk-checkboxes govuk-checkboxes-custom-input">
                                        <div class="govuk-checkboxes__item govuk-item-custom"
                                            *ngFor="let orgRole of orgUserGroupRoles; let i=index">
                                            <input class="govuk-checkboxes__input"
                                                id="{{'orgRoleControlGroup_' + orgRole.id}}" [attr.disabled]="true"
                                                [attr.checked]="true"
                                                [formControlName]="'orgRoleControlGroup_' + orgRole.id" type="checkbox"
                                                value="hmrc">
                                            <label class="govuk-label govuk-checkboxes__label role_lable"
                                                [ngClass]="{'govuk-!-padding-top-0': orgRole.description != ''}"
                                                for="{{'orgRoleControlGroup_' + orgRole.id}}">
                                                {{orgRole.name}}
                                            </label>
                                            <strong class="govuk-tag govuk-tag--grey"
                                                *ngIf="orgRole.approvalStatus == 0 ? true : false">
                                                Pending Approval
                                            </strong>
                                            <p class="govuk-body description_para govuk-!-padding-top-0">
                                                {{orgRole.description}} </p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </fieldset>
                    </div>
                    <div class="govuk-form-group" id="signin-provider-form-group"
                        [class.govuk-form-group--error]="submitted && formGroup.hasError('identityProviderRequired')">
                        <fieldset class="govuk-fieldset">
                            <h2 class="govuk-heading-s">
                                {{ 'SIGN_IN_PROVIDER'| translate }}
                            </h2>
                            <span class="sign_in_prov">
                                {{ idpStatus == false ? 'A sign in provider gives users the option to use a different
                                service to sign in. Sign in
                                providers are set at an organisational level. To update the sign in providers for your
                                Organisation’s users please go to Manage organisations' : (idpStatus == true ? 'You can
                                only use a User ID and password to sign in. You may be able to use additional sign-in
                                methods in the future.' : '')}}
                            </span>
                            <span id="group-error" class="govuk-error-message label-detail-text-gap"
                                *ngIf="submitted && formGroup.hasError('identityProviderRequired')">
                                <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                                {{'ERROR_SIGN_IN_PROVIDER_REQUIRED' | translate }}
                            </span>

                            <div class="govuk-checkboxes label-detail-text-gap">
                                <div class="govuk-checkboxes__item" *ngFor="let identityProvider of allIdps">
                                    <input #input class="govuk-checkboxes__input" [attr.disabled]="true"
                                        id="{{'signInProviderControl_' + identityProvider.id}}"
                                        [formControlName]="'signInProviderControl_' + identityProvider.id"
                                        type="checkbox" value="idp">
                                    <label class="govuk-label govuk-checkboxes__label"
                                        for="{{'signInProviderControl_' + identityProvider.id}}">
                                        {{identityProvider.name}}
                                    </label>
                                </div>
                            </div>

                        </fieldset>
                    </div>

                    <div class="govuk-button-group save-cancel-button-group">
                        <button type="submit"  (click)="pushDataLayerEvent('Save changes')" class="govuk-button" [disabled]="!isFormChanges || isDormantUser"
                            data-module="govuk-button">
                            {{ 'SAVE_BTN' | translate }}
                        </button>
                        <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
                            data-module="govuk-button">
                            {{ 'CANCEL_BTN' | translate }}
                        </button>
                    </div>

                    <button *ngIf="isEdit == true && userProfileResponseInfo.detail.canChangePassword" type="button"
                        (click)="onResetPasswordClick('Reset user password')" class="govuk-button govuk-button--secondary"
                        [disabled]="isDormantUser" data-module="govuk-button">
                        {{ 'RESET_USER_PASSWORD_BTN' | translate }}
                    </button>
                    <br>
                    <button *ngIf="isEdit && !isCustomMfaEnabled" (click)="ResetAdditionalSecurity('Reset user\'s additional security')" type="button"
                        class="govuk-button govuk-button--secondary" data-module="govuk-button"
                        [disabled]="!MFA_Enabled || isDormantUser">
                        Reset user’s additional security
                    </button>
                    <!-- <div *ngIf="isEdit==true">

                        <a routerLink="/manage-users/confirm-user-delete" (click)="onDeleteClick()"
                            class="navigation-text delete-link">{{
                            'DELETE_USER'
                            | translate }}</a>
                    </div> -->
                    <div *ngIf="isEdit==true">
                        <a *ngIf="isEdit && !isDormantUser" routerLink="/manage-users/confirm-user-deactivate"
                            (click)="onDeactivateClick()" class="navigation-text delete-link">
                            {{'DEACTIVATE_USER' | translate }}</a>
                        <a *ngIf="isEdit && isDormantUser" routerLink="/manage-users/confirm-user-reactivate"
                            (click)="onReactivateUserClick()" class="navigation-text delete-link">
                            {{'REACTIVATE_USER' | translate}}</a>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>