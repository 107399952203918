<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list" *ngIf="routeData.accessFrom != 'users' && routeData.accessFrom != 'profile'">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-groups">{{ 'MANAGE_GROUPS' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span *ngIf="isEdit==true" class="govuk-breadcrumbs__link">{{group.groupName}}</span>
                        <span *ngIf="isEdit!=true" class="govuk-breadcrumbs__link">{{ 'VIEW_GROUP' | translate}}</span>
                    </li>
                </ol>
                <ol class="govuk-breadcrumbs__list"
                    *ngIf="(routeData.accessFrom === 'users' && routeData.isUserAccess)">

                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-users">{{ 'MANAGE_YOUR_USER_ACCOUNTS' |
                            translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" (click)="navigateBackToUser()" *ngIf="routeData.userEditStatus === false"> {{ 'CREATE_NEW_USER_ACCOUNT' | translate }}</a>
                        <a class="govuk-breadcrumbs__link" (click)="navigateBackToUser()" *ngIf="routeData.userEditStatus === true"> {{ 'EDIT_YOUR_USER_ACCOUNTS' | translate }}</a>
                    </li>

                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link">{{ 'VIEW_GROUP' | translate}}</span>
                    </li>
                </ol>
                <ol class="govuk-breadcrumbs__list"
                    *ngIf="(routeData.accessFrom === 'users' && !routeData.isUserAccess)">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" (click)="history.back()">{{ 'MANAGE_MY_ACCOUNT' |
                            translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link">{{ 'VIEW_GROUP' | translate}}</span>
                    </li>
                </ol>
                <ol class="govuk-breadcrumbs__list"
                    *ngIf="(routeData.accessFrom === 'profile')">

                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/profile">{{ 'MANAGE_MY_ACCOUNT' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link">{{ 'VIEW_GROUP' | translate}}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <!-- <a href="javascript:;" *ngIf="isEdit!=true" (click)="onGoBackClick()" class="govuk-back-link back-button">{{ 'BACK' | translate }}</a> -->
        <h1 *ngIf="isEdit==true" class="govuk-heading-xl page-title">
            Group : {{group.groupName}} </h1>
        <h1 *ngIf="isEdit!=true" class="govuk-heading-xl page-title">
            {{ 'VIEW_GROUP' | translate }} : {{group.groupName}} </h1>
         <p class="govuk-hints" *ngIf="isAdminGroup">
           <span>This group has been created automatically for all users in your organisation with administrator privileges. You can use it to easily add or remove the administrator role from users, but you can’t use it to add or remove services from users.</span>
        </p>
            <details class="govuk-details" data-module="govuk-details" *ngIf="isAdminGroup">
                <summary class="govuk-details__summary">
                  <span class="govuk-details__summary-text">
                    More about this group
                  </span>
                </summary>
                <div class="govuk-details__text">
                    <span>If you add a user to this group then they will become an administrator. Removing someone from this group will remove their administrator privileges.</span>
                    <br>
                    <br>
                    <span>You cannot delete this group or change its name.</span>
                </div>
              </details>
    </div>
</div>

<div class="govuk-grid-row" *ngIf="isEdit==true && !isAdminGroup">
    <div class="govuk-grid-column-two-thirds">
        <div class="govuk-button-group">
            <button type="button" (click)="onNameEditClick('Change group name')" class="govuk-button govuk-button--secondary"
                data-module="govuk-button">
                {{ 'CHANGE_GROUP_NAME_BTN' | translate }}
            </button>
        </div>
    </div>
</div>
<div class="top-margin" *ngIf="!isAdminGroup">
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <div *ngIf="showRoleView">
                <h2 class="govuk-heading-s">{{ 'ROLES_FOR_GROUP' | translate }}</h2>
                <app-details [detailsData]="detailsData[0]"></app-details>
            </div>
            <div *ngIf="!showRoleView">
                <h2 class="govuk-heading-s">{{ 'SERVICE_FOR_GROUP' | translate }}</h2>
                <p class="govuk-hints">This group has access to the services below. To manage these, select 'Manage
                    group services'.</p>
            </div>
            <div *ngIf="group.roles.length == 0">
                <p>{{'NONE'| translate}}
                <p>
            </div>
        </div>
    </div>
    <div *ngIf="group.roles.length != 0" class="govuk-grid-row">
        <div class="govuk-grid-column-full">
            <div class="user-table">
                <app-govuk-table [headerTextKeys]="rolesTableHeaders" [data]="group.roles"
                    [dataKeys]="roesColumnsToDisplay" [useClientPagination]="true">
                </app-govuk-table>
            </div>
        </div>
    </div>
    <div class="govuk-grid-row" *ngIf="isEdit==true">
        <div class="govuk-grid-column-two-thirds">
            <div class="govuk-button-group">
                <button *ngIf="showRoleView" type="button" (click)="onRoleEditClick('Manage group roles')"
                    class="govuk-button govuk-button--secondary" data-module="govuk-button">
                    {{ 'MANAGE_GROUP_ROLE_BTN' | translate }}
                </button>
                <button *ngIf="!showRoleView" type="button" (click)="onRoleEditClick('Manage group services')"
                    class="govuk-button govuk-button--secondary" data-module="govuk-button">
                    {{ 'MANAGE_GROUP_SERVICE_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="govuk-form-group" id="user-mfa-form-group" *ngIf="!isAdminGroup">
    <fieldset class="govuk-fieldset">
        <legend class="govuk-heading-s">
            Additional security
        </legend>
        <p class="govuk-hints">{{ detailsData[1] }}</p>        
        <div class="govuk-form-group govuk-checkboxes__item" id="user-mfa-form-group">
            <input [attr.disabled]=true [checked]="group.mfaEnabled" class="govuk-checkboxes__input" id="mfaEnabled"
                formControlName="mfaEnabled" name="mfaEnabled" type="checkbox">
            <label class="govuk-label govuk-checkboxes__label" for="mfaEnabled">
                Additional security using digital device
            </label>
        </div>
    </fieldset>
</div> -->

<div class="top-margin">
    <div>
        <div class="govuk-grid-row">
            <div class="govuk-grid-column-two-thirds">
                <h2 class="govuk-heading-s">{{ 'LIST_OF_USERS_GROUP' | translate }}</h2>
                <div *ngIf="group.users.length == 0">
                    <p>{{'NONE'| translate}}
                    <p>
                </div>
            </div>
        </div>
        <div *ngIf="group.users.length != 0" class="govuk-grid-row">
            <div class="govuk-grid-column-full">
                <div class="user-table">
                    <app-govuk-table [headerTextKeys]="usersTableHeaders" [data]="group.users"
                        [dataKeys]="usersColumnsToDisplay" [useClientPagination]="true">
                    </app-govuk-table>
                </div>
            </div>
        </div>
    </div>
    <div class="govuk-grid-row" *ngIf="isEdit==true">
        <div class="govuk-grid-column-two-thirds">
            <div class="govuk-button-group">
                <button type="button" (click)="onUserEditClick('Manage users')" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button">
                    {{ 'MANAGE_USER_BTN' | translate }}
                </button>
            </div>
            <div *ngIf="!isAdminGroup">
                <!-- <a href="javascript:;" (click)="onDeleteClick()" class="navigation-text delete-link">{{ 'DELETE_GROUP' |
                    translate }}</a> -->
                <a [routerLink]="['/manage-groups/delete-group-confirm']"  [queryParams]="{ data: getQueryData() }" (click)="onDeleteClick()" class="navigation-text delete-link">{{ 'DELETE_GROUP' |
                    translate }}</a>
            </div>
        </div>
    </div>
</div>
