<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list" *ngIf="pageAccessMode != 0">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{
                            'ENTER_DETAIL' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link"
                            routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' |
                            translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' |
                            translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="buyerFlow == 3">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{
                            'BUYER_TYPE_BC' |
                            translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' |
                            translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'ORG_ALREADY_REG' | translate }}</span>
                    </li>
                </ol>
                <ol class="govuk-breadcrumbs__list" *ngIf="pageAccessMode == 0">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{
                            'ENTER_DETAIL' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'ORG_ALREADY_REG' | translate }}</span>
                    </li>
                </ol>
            </div>
            <div>
                <h1 class="govuk-heading-xl page-title">
                    Your organisation is already registered</h1>
                <app-reg-org-details ciiOrgId={{this.orgreginfo.ciiOrgId}}></app-reg-org-details>
                <p class="govuk-body-l">Press 'Continue' to confirm that the information above is correct and request
                    access from the administrator.</p>
                <div class="govuk-button-group continue-cancel-button-group">
                    <button id="continueButton" type="button" class="govuk-button" data-module="govuk-button"
                        (click)="onContinueSingleOrgRegistered('Continue')">Continue</button>
                    <button type="button" (click)="goBack('This is not my organisation')" class="govuk-button govuk-button--secondary"
                        data-module="govuk-button">
                        {{'THIS_IS_NOT_MY_ORGANISATION_BTN' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>