<div class="content flex">
    <a (click)="onBack()" class="govuk-back-link">Back</a>
<div
  class="govuk-error-summary contact-form-error"
  aria-labelledby="error-summary-title"
  role="alert"
  tabindex="-1"
  id="error-summary"
  data-module="govuk-error-summary"
>
  <h2 class="govuk-error-summary__title" id="error-summary-title">
    {{ "ERROR_SUMMARY" | translate }}
  </h2>
  <div class="govuk-error-summary__body">
    <ul class="govuk-list govuk-error-summary__list">
      <li>
        <a href="javascript:;" onClick="return false;" routerLink="/home">Due to suspicious activity on your account your password cannot be reset at this time please contact your Organisation Administrator</a>
      </li>
    </ul>
  </div>
</div>
</div>