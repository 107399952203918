<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ "ADMINISTRATOR_DASHBOARD" | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-buyer-both">
                            Manage Buyer status requests</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link">{{routeDetails.status === 'accept' ? 'Success': 'Declined'}}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title header-bread-align" *ngIf="routeDetails.status === 'decline'">You have declined the right to buy status for the organisation {{routeDetails.organisationName}}</h1>
            <h1 class="govuk-heading-xl page-title header-bread-align" *ngIf="routeDetails.status === 'accept'">You have accepted the right to buy status for the organisation {{routeDetails.organisationName}}</h1>
            <div>
                
                <a  [routerLink]="['/manage-buyer-both']"  [queryParams]="{isNewTab:true}" class="navigation-text">Return to Manage Buyer status requests</a>
                <br><br>
                
                <a  [routerLink]="['/home']" [queryParams]="{isNewTab:true}" class="navigation-text">Return to the
                    dashboard</a>
            </div>
        </div>
    </div>
