<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<h1 class="govuk-heading-xl page-title margine">Public Procurement Gateway Dashboard</h1>
<strong>Organisation: {{orgDetails}}</strong>
<h2 *ngIf="ccsModules.length > 0" class="gov_uk_header">Here are all of your CCS Services</h2>
<h3 class="govuk-heading-m" *ngIf="ccsModules.length > 0">Services will open in a new tab</h3>
<div class="row" *ngIf="ccsModules.length > 0">
  <div *ngFor="let ccsModule of ccsModules;" class="column">
    <div class="card" *ngIf="ccsModule.route">
      <a href={{ccsModule.route}}>
        <a [attr.id]="getModuleElementId(ccsModule.name)" tabindex="-1" role="link" class="card-title">{{ccsModule.name}}</a>
        <p class="tile-para">{{ccsModule.description}}</p>
      </a>
    </div>
    <div class="card" *ngIf="ccsModule.href">
      <a href="{{ccsModule.href}}" target="_blank" attr.aria-labelledby={{getModuleElementId(ccsModule.name)}}>
        <a [attr.id]="getModuleElementId(ccsModule.name)" tabindex="-1"  role="link"  class="card-title">{{ccsModule.name}}</a>
        <p class="tile-para">{{ccsModule.description}}</p>
      </a>
    </div>
  </div>
</div>
<br *ngIf="ccsModules.length > 0" />
<br *ngIf="ccsModules.length > 0" />
<div *ngIf="systemModules.length > 0">
  <h2 *ngIf="this.isOrgAdmin" class="gov_uk_header">Administrator Settings</h2>
  <h2 *ngIf="!this.isOrgAdmin" class="gov_uk_header">Account Settings</h2>
  <h3 *ngIf="this.isOrgAdmin">For your own organisation</h3>
  <div class="row">
    <div *ngFor="let systemModule of systemModules | permission;" class="column">
      <div class="card">
        <a routerLink={{systemModule.route}}>
          <a [attr.id]="getModuleElementId(systemModule.name)" tabindex="-1"  role="link" class="card-title">{{systemModule.name}}</a>
          <p class="tile-para">{{systemModule.description}}</p>
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="otherModules.length > 0">
  <h3>For other organisations</h3>
  <div class="row">
    <div *ngFor="let otherModule of otherModules | permission;" class="column">
      <div class="card">
        <a routerLink={{otherModule.route}}>
          <a [attr.id]="getModuleElementId(otherModule.name)" tabindex="-1" role="link" class="card-title">{{otherModule.name}}</a>
          <p class="tile-para">{{otherModule.description}}</p>
        </a>
      </div>
    </div>
  </div>
</div>

<!-- <iframe style="position: absolute;width:0;height:0;border:0;" [src]="opIFrameURL" id="opIFrame"></iframe>
<iframe style="position: absolute;width:0;height:0;border:0;" [src]="rpIFrameURL" id="rpIFrame"></iframe> -->