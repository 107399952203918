<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' |
          translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' |
          translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
    </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" *ngIf="buyerFlow == 3">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{ 'BUYER_TYPE_BC' |
          translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" href="javascript:;" (click)="goBack()">{{ 'CONFIRM_ORG_DETAILS' | translate
          }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link"> {{ 'NOT_MY_DETAILS' | translate }}</span>
      </li>
    </ol>
  </div>
  <p class="govuk-body-l">Please check your organisation's details and try again</p>
  <button type="button" data-module="govuk-button" class="govuk-button govuk-button--secondary"
    (click)="Onclick('Search again with a different registry or ID')">Search again with a different registry or ID</button>
  <!-- <p><a id="searchWithDifferentIdLink" class="navigation-text" routerLink="/manage-org/register/search">Search with a different registry / ID</a></p> -->
  <!-- <div style="margin-top:30px;" class="form-group">
    <div style="display:inline-block">
      <hr>
    </div>
    <div style="display:inline-block; position: relative; width: 70px;height: 20px;">
      <span style="position: absolute; left: 25px;font-weight: 500;">Or</span>
    </div>
    <div style="display:inline-block">
      <hr>
    </div>
  </div> -->
  <!-- <p><a class="navigation-text" id="submitClaimLink" href="https://www.crowncommercial.gov.uk/contact" target="_blank">Contact CCS</a></p> -->
</div>