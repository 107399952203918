<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
    <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <span class="govuk-breadcrumbs__link"> {{ 'ENTER_DETAIL' | translate }}</span>
            </li>
        </ol>
    </div>
    <h1 class="govuk-heading-xl page-title">
        Enter your details</h1>
    <!-- <p class="govuk-body-l">Enter your name, organisation, and work email address</p> -->
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-three-quarters">
            <form class="govuk-!-width-three-quarters" [class.error]="!formValid(formGroup)" [formGroup]="formGroup">
                <div class="govuk-form-group" *ngIf="formValid(formGroup) == false && submitted">
                    <div class="govuk-error-summary govuk-grid-column-three-quarters" 
                        aria-labelledby="error-summary-title" id="error-summary" role="alert" tabindex="-1"
                        data-module="govuk-error-summary">
                        <h2 class="govuk-error-summary__title" id="error-summary-title"> There is a problem</h2>
                        <div class="govuk-error-summary__body">
                            <ul class="govuk-list govuk-error-summary__list">
                                <a onClick="return false;" (click)="setFocus(0)" href="javascript:;"
                                    *ngIf="formGroup.controls['organisation'].hasError('required')">
                                    <li>Enter your organisation name</li>
                                </a>
                                <a onClick="return false;" (click)="setFocus(1)" href="javascript:;"
                                    *ngIf="formGroup.controls['firstName'].hasError('required')">
                                    <li>Enter your first name</li>
                                </a>
                                <a onClick="return false;" (click)="setFocus(2)" href="javascript:;"
                                    *ngIf="formGroup.controls['lastName'].hasError('required')">
                                    <li>Enter your last name</li>
                                </a>
                                <a onClick="return false;" (click)="setFocus(3)" href="javascript:;"
                                    *ngIf="formGroup.controls['email'].hasError('required')">
                                    <li>Enter your work email address</li>
                                </a>
                                <a onClick="return false;" (click)="setFocus(3)" href="javascript:;"
                                    *ngIf="submitted && !formGroup.controls.email.errors?.required && formGroup.controls.email.invalid">
                                    <li>Enter an email address in the correct format, like name&#64;example.com</li>
                                </a>
                                <a *ngIf="submitted && ((!formGroup.controls.lastName.errors?.required && formGroup.controls.lastName.invalid) || (!formGroup.controls.firstName.errors?.required && formGroup.controls.firstName.invalid))"
                                    href="javascript:;" onClick="return false;" (click)="customFocum()">
                                    <li>{{ 'ENTER_NAME_WITHOUT_NUM_SPECIAL' | translate }}</li>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="govuk-form-group"
                    [class.govuk-form-group--error]="submitted && formGroup.controls.organisation.invalid">
                    <label class="govuk-label" for="organisation">{{ 'NAME_OF_YOUR_ORGANISATION' | translate }}</label>
                    <span id="firstname-error" class="govuk-error-message"
                        *ngIf="submitted && formGroup.controls.organisation.invalid">
                        <span class="govuk-visually-hidden">Error:</span>Enter your organisation name
                    </span>
                    <mat-form-field class="example-full-width form-field-orgname">
                        <input id="organisation" [ngModel]="searchOrgName" (ngModelChange)="onSearchTextChange($event)"
                            #input type="search" class="govuk-input custom-input" matInput formControlName="organisation"
                            [class.govuk-input--error]="submitted && formGroup.controls.organisation.invalid"
                            maxlength="100" [matAutocomplete]="auto" >
                        <mat-autocomplete #auto="matAutocomplete" class="mat-country-search-registration">
                            <div *ngFor="let option of filteredOptions; index as i">
                                <mat-option *ngIf="(i<showMoreThreshold) || showMoreOptionsVisible"
                                    [value]="option.legalName">
                                    {{option.legalName}}
                                </mat-option>
                            </div>
                            <hr *ngIf="(filteredOptions?.length! > showMoreThreshold) && !showMoreOptionsVisible">
                            <div class="row no-gutters pb-1"
                                *ngIf="(filteredOptions?.length! > showMoreThreshold)  && !showMoreOptionsVisible">
                                <div class="col-sm-6 text-right pr-2">
                                    <button type="button" class="btn btn-primary clickable"
                                        (click)="showMoreClicked('Show more')">Show
                                        more</button>
                                </div>
                            </div>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="govuk-form-group"
                    [class.govuk-form-group--error]="submitted && formGroup.controls.firstName.invalid">
                    <label class="govuk-label" for="firstName">{{ 'FIRSTNAME' | translate }}</label>
                    <span id="firstname-error" class="govuk-error-message"
                        *ngIf="submitted && formGroup.controls.firstName.errors?.required">
                        <span class="govuk-visually-hidden">Error:</span>Enter your first name
                    </span>
                    <span id="firstname-error" class="govuk-error-message"
                        *ngIf="submitted && !formGroup.controls.firstName.errors?.required && formGroup.controls.firstName.invalid">
                        <span class="govuk-visually-hidden">Error:</span>{{ 'ENTER_NAME_WITHOUT_NUM_SPECIAL' | translate
                        }}
                    </span>
                    <input #input class="govuk-input" id="firstName" formControlName="firstName" maxlength="100"
                        name="firstName" size="20" type="text" value="" autocomplete="given-name"
                        [class.govuk-input--error]="submitted && formGroup.controls.firstName.invalid">
                </div>
                <div class="govuk-form-group"
                    [class.govuk-form-group--error]="submitted && formGroup.controls.lastName.invalid">
                    <label class="govuk-label" for="lastName">{{ 'LASTNAME' | translate }}</label>
                    <span id="lastname-error" class="govuk-error-message"
                        *ngIf="submitted && formGroup.controls.lastName.errors?.required">
                        <span class="govuk-visually-hidden">Error:</span>Enter your last name
                    </span>
                    <span id="lastname-error" class="govuk-error-message"
                        *ngIf="submitted && !formGroup.controls.lastName.errors?.required && formGroup.controls.lastName.invalid">
                        <span class="govuk-visually-hidden">Error:</span>{{ 'ENTER_NAME_WITHOUT_NUM_SPECIAL' | translate
                        }}
                    </span>
                    <input #input class="govuk-input" id="lastName" formControlName="lastName" maxlength="100"
                        name="lastName" size="20" type="text" value="" autocomplete="family-name"
                        [class.govuk-input--error]="submitted && formGroup.controls.lastName.invalid">
                </div>
                <div class="govuk-form-group"
                    [class.govuk-form-group--error]="submitted && formGroup.controls.email.invalid">
                    <label class="govuk-label" for="email">{{ 'WORK_EMAIL_ADDRESS' | translate }}</label>
                    <span id="email-error" class="govuk-error-message"
                        *ngIf="submitted && formGroup.controls.email.errors?.required">
                        <span class="govuk-visually-hidden">Error:</span>Enter your work email address
                    </span>
                    <span id="email-error" class="govuk-error-message"
                        *ngIf="submitted && !formGroup.controls.email.errors?.required && formGroup.controls.email.invalid">
                        <span class="govuk-visually-hidden">Error:</span>Enter an email address in the correct format,
                        like name&#64;example.com
                    </span>
                    <input #input class="govuk-input" id="email" formControlName="email" name="email" size="20"
                        type="email" value="" autocomplete="email" spellcheck="false"
                        (input)="validateEmailLength($event)"
                        [class.govuk-input--error]="submitted && formGroup.controls.email.invalid">
                </div>
                <div class="govuk-form-group">
                    <button id="continueButton" type="submit" class="govuk-button" data-module="govuk-button"
                        (click)="onSubmit(formGroup)">Continue</button>
                </div>
            </form>
        </div>
    </div>
</div>