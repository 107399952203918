<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate}}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/buyer-supplier/search">Manage service eligibility</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link">Confirm your organisation selection</span>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">Confirm your organisation selection</h1>
  <div *ngIf="org">
    <p style="margin-top:40px;">
      <span style="font-weight: 700;">{{ org?.identifier?.legalName }}</span><br />
      {{ org?.address?.streetAddress }}<br *ngIf="org?.address?.streetAddress" />
      {{ org?.address?.locality }}<br *ngIf="org?.address?.locality" />
      {{ org?.address?.region }}<br *ngIf="org?.address?.region" />
      {{ org?.address?.postalCode }}<br *ngIf="org?.address?.postalCode" />
      {{ org?.address?.countryName }}<br *ngIf="org?.address?.countryName" />
    </p>
  </div>
  <h2 class="govuk-heading-s">Registries</h2>
  <h2 class="govuk-heading-s">Below are all the registries currently linked to this organisation</h2>
  <app-registry-govuk-table [pageName]="'MSE'" [orgId]="selectedOrgId"></app-registry-govuk-table>
  <div class="govuk-button-group">
    <button type="button" class="govuk-button" data-module="govuk-button"
      (click)="onContinueClick('Continue')">Continue</button>
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
      (click)="onCancelClick('Cancel')">Cancel</button>
  </div>
</div>
