<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="govuk-breadcrumbs">
      <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/home">
            {{ 'ADMINISTRATOR_DASHBOARD' | translate}}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">{{ 'MANAGE_YOUR_ORGANISATION' | translate
            }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <span *ngIf="isEdit!=true" class="govuk-breadcrumbs__link">{{ 'ADD_SITE' | translate
            }}</span>
          <span *ngIf="isEdit==true" class="govuk-breadcrumbs__link">{{ 'EDIT_SITE' | translate
            }}</span>
        </li>
      </ol>
    </div>
    <h1 *ngIf="isEdit!=true" class="govuk-heading-xl page-title">{{ 'ADD_SITE' | translate }}</h1>
    <h1 *ngIf="isEdit==true" class="govuk-heading-xl page-title">{{ 'EDIT_SITE' | translate }}</h1>
    <div class="govuk-error-summary contact-form-error" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
      id="error-summary" data-module="govuk-error-summary"
      *ngIf="(!formValid(formGroup) || serverError.length > 0) && submitted">
      <h2 class="govuk-error-summary__title" id="error-summary-title">
        {{ 'ERROR_SUMMARY' | translate }}
      </h2>
      <div class="govuk-error-summary__body">
        <ul class="govuk-list govuk-error-summary__list">
          <li *ngIf="submitted && formGroup.controls.name.hasError('required')">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(0)"> {{
              'ERROR_SITE_NAME' | translate }}</a>
          </li>
          <li *ngIf="submitted && formGroup.controls.name.errors?.pattern">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(0)"> {{
              'ERROR_INVALID_SITE' | translate }}</a>
          </li>
          <li
            *ngIf="submitted && (formGroup.controls.name.errors?.minlength || formGroup.controls.name.errors?.maxlength)">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(0)"> {{
              'ERROR_INVALID_SITE_LENGTH' | translate }}</a>
          </li>
          <li *ngIf="submitted && !formGroup.controls.name.hasError('required') && serverError == 'INVALID_SITE_NAME'">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(0)"> Site name {{dublicateSiteName}}
              already exists, enter a different site name.</a>
          </li>
          <li *ngIf="submitted && formGroup.controls.streetAddress.hasError('required')">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(1)"> {{
              'ERROR_STREET_ADDRESS' | translate }} </a>
          </li>
          <li *ngIf="submitted && formGroup.controls.streetAddress.errors?.pattern">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(1)"> {{
              'ERROR_INVALID_STREET' | translate }} </a>
          </li>
          <li
            *ngIf="submitted && (formGroup.controls.streetAddress.errors?.minlength || formGroup.controls.streetAddress.errors?.maxlength)">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(1)"> {{
              'ERROR_INVALID_STREET_LENGTH' | translate }} </a>
          </li>
          <li *ngIf="submitted && formGroup.controls.locality.errors?.pattern">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(2)"> {{
              'ERROR_INVALID_LOCALITY' | translate }} </a>
          </li>
          <li
            *ngIf="submitted && (formGroup.controls.locality.errors?.minlength || formGroup.controls.locality.errors?.maxlength)">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(2)"> {{
              'ERROR_INVALID_LOCALITY_LENGTH' | translate }} </a>
          </li>
          <li *ngIf="submitted && formGroup.controls.postalCode.hasError('required')">
            <a href="javascript:;" onClick="return false;" (click)="setFocus(4)"> {{
              'ERROR_POSTAL_CODE' | translate }} </a>
          </li>
          <li
            *ngIf="submitted && (formGroup.controls.countryCode.hasError('required') || serverError == 'INVALID_COUNTRY_CODE')">
            <a href="javascript:;" onClick="return false;" (click)="scrollToAnchor('country-code-form-group')"> {{
              'ERROR_COUNTRY_CODE' | translate }} </a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>

<div class="form site-form">
  <form class="ui form login mb-two" [class.error]="!formValid(formGroup)" (submit)="onSubmit(formGroup)"
    [formGroup]="formGroup">
    <div class="ui segment">
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
          <div class="govuk-form-group" id="name-form-group"
            [class.govuk-form-group--error]="submitted && (formGroup.controls.name.hasError('required') || (!formGroup.controls.name.hasError('required') && serverError == 'INVALID_SITE_NAME') || (formGroup.controls.name.errors?.pattern || (formGroup.controls.name.errors?.minlength || formGroup.controls.name.errors?.maxlength)))">
            <label class="govuk-label" for="name"> {{ 'SITE_NAME' | translate }} </label>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && formGroup.controls.name.hasError('required')">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_SITE_NAME' | translate }}
            </span>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && !formGroup.controls.name.hasError('required') && serverError == 'INVALID_SITE_NAME'">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>Site name {{dublicateSiteName}}
              already exists, enter a different site name.
            </span>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && formGroup.controls.name.errors?.pattern">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_INVALID_SITE' | translate }}
            </span>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && (formGroup.controls.name.errors?.minlength || formGroup.controls.name.errors?.maxlength)">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_INVALID_SITE_LENGTH' | translate }}
            </span>
            <input #input formControlName="name" class="govuk-input" id="name" name="name" type="text"
              (input)="formValueChanged()"
              [class.govuk-input--error]="submitted && (formGroup.controls.name.hasError('required') || (!formGroup.controls.name.hasError('required') && serverError == 'INVALID_SITE_NAME') || (formGroup.controls.name.errors?.pattern || (formGroup.controls.name.errors?.minlength || formGroup.controls.name.errors?.maxlength)))">
          </div>

          <div class="govuk-form-group" id="street-address-form-group"
            [class.govuk-form-group--error]="submitted && (formGroup.controls.streetAddress.hasError('required') || formGroup.controls.streetAddress.errors?.pattern || (formGroup.controls.streetAddress.errors?.minlength || formGroup.controls.streetAddress.errors?.maxlength))">
            <label class="govuk-label" for="street-address"> {{ 'STREET_ADDRESS' | translate }} </label>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && formGroup.controls.streetAddress.hasError('required')">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_STREET_ADDRESS' | translate }}
            </span>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && formGroup.controls.streetAddress.errors?.pattern">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_INVALID_STREET' | translate }}
            </span>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && (formGroup.controls.streetAddress.errors?.minlength || formGroup.controls.streetAddress.errors?.maxlength)">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_INVALID_STREET_LENGTH' | translate }}
            </span>
            <input #input formControlName="streetAddress" class="govuk-input" id="street-address" name="street-address"
              type="text"
              [class.govuk-input--error]="submitted && (formGroup.controls.streetAddress.hasError('required') || formGroup.controls.streetAddress.errors?.pattern || formGroup.controls.streetAddress.errors?.minlength || formGroup.controls.streetAddress.errors?.maxlength)">
          </div>

          <div class="govuk-form-group" id="locality-form-group"
            [class.govuk-form-group--error]="submitted && (formGroup.controls.locality.errors?.pattern || (formGroup.controls.locality.errors?.minlength || formGroup.controls.locality.errors?.maxlength))">
            <label class="govuk-label" for="locality"> {{ 'LOCALITY' | translate }} (optional) </label>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && formGroup.controls.locality.errors?.pattern">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_INVALID_LOCALITY' | translate }}
            </span>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && (formGroup.controls.locality.errors?.minlength || formGroup.controls.locality.errors?.maxlength)">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_INVALID_LOCALITY_LENGTH' | translate }}
            </span>
            <input #input formControlName="locality" [class.govuk-input--error]="submitted && (formGroup.controls.locality.errors?.pattern || formGroup.controls.locality.errors?.minlength || formGroup.controls.locality.errors?.maxlength)" class="govuk-input" id="locality" name="locality" type="text">
          </div>

          <div class="govuk-form-group" id="region-form-group">
            <label class="govuk-label" for="region"> {{ 'REGION' | translate }} (optional) </label>
            <input #input formControlName="region" class="govuk-input" id="region" name="region" type="text">
          </div>

          <div class="govuk-form-group" id="postal-code-form-group"
            [class.govuk-form-group--error]="submitted && formGroup.controls.postalCode.hasError('required')">
            <label class="govuk-label" for="postal-code"> {{ 'POSTAL_CODE' | translate }} </label>
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && formGroup.controls.postalCode.hasError('required')">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_POSTAL_CODE' | translate }}
            </span>
            <input #input formControlName="postalCode" class="govuk-input" id="postal-code" name="postal-code"
              type="text" [class.govuk-input--error]="submitted && formGroup.controls.postalCode.hasError('required')">
          </div>

          <div class="govuk-form-group" id="country-code-form-group"
            [class.govuk-form-group--error]="submitted && formGroup.controls.countryCode.hasError('required')">
            <span class="govuk-label" for="country-code">
              {{ 'COUNTRY_CODE' | translate }}
            </span>
            
            <span id="name-error" class="govuk-error-message"
              *ngIf="submitted && (formGroup.controls.countryCode.hasError('required') ||  serverError == 'INVALID_COUNTRY_CODE')">
              <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
              'ERROR_COUNTRY_CODE' | translate }}
            </span>
            <mat-form-field class="form-field-country"
              [class.govuk-input--error]="submitted && formGroup.controls.countryCode.hasError('required')">
              <mat-select formControlName="countryCode" title="countrycodedropdown" #singleSelect
                panelClass="mat-country-select-site" id="country-code" class="custom-select">
                <mat-option>
                  <ngx-mat-select-search [formControl]="bankFilterCtrl" placeholderLabel="Search"
                    noEntriesFoundLabel="No matching country found">
                    <mat-icon ngxMatSelectSearchClear>clear</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>

                <mat-option *ngFor="let topCountry of topCountries" [value]="topCountry">
                  {{topCountry.countryName}} - {{topCountry.countryCode}}
                </mat-option>
                <mat-option *ngIf="topCountries.length > 0" disabled="true">────────────────────</mat-option>

                <mat-option *ngFor="let country of filteredCountryDetails | async" [value]="country">
                  {{country.countryName}} - {{country.countryCode}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </div>

      <div class="govuk-grid-row" *ngIf="isEdit==true">
        <div class="govuk-grid-column-full">
          <div>
            <h2> {{ 'CONTACT_DETAILS' | translate }}</h2>
            <app-govuk-table [headerTextKeys]="contactTableHeaders" [data]="contactData"
              [dataKeys]="contactColumnsToDisplay" [isHyperLinkVisible]="true" [hyperLinkText]="'EDIT'"
              (hyperLinkClickEvent)="onContactEditClick($event)" [useClientPagination]="true">
            </app-govuk-table>
          </div>
          <div class="govuk-form-group govuk-button-group">        
              <button type="button" (click)="onContactAddClick('Add another contact')" class="govuk-button govuk-button--secondary" data-module="govuk-button" *ngIf="contactAddAnother">
                {{ 'ADD_ANOTHER_CONTACT_BTN' | translate }}
              </button>
              <button type="button" (click)="onContactAddClick('Add new contact')" class="govuk-button govuk-button--secondary" data-module="govuk-button" *ngIf="!contactAddAnother">
                {{ 'ADD_NEW_CONTACT' | translate }}
              </button>
            <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
              (click)="onContactAssignClick('Find and assign contact')">
              {{ 'FIND_AND_ASSIGN_CONTACT_BTN' | translate }}</button>
          </div>
        </div>
      </div>

      <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
          <div class="govuk-button-group save-cancel-button-group">
            <button type="submit" class="govuk-button" (click)="pushDataLayerEvent(isEdit ? 'Save changes':'Save and Continue')" [disabled]="!formChanged" data-module="govuk-button">
              {{ isEdit === false ? 'Save and Continue' : ' Save changes'}}
            </button>
            <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
              data-module="govuk-button">
              {{ 'CANCEL_BTN' | translate }}
            </button>
          </div>
          <!-- <a href="javascript:;" *ngIf="isEdit==true" (click)="onDeleteClick()" class="navigation-text delete-link">{{
            'DELETE_SITE'
            | translate }}</a>   
           -->
          <a [routerLink]="['/manage-org/profile/site/delete']" *ngIf="isEdit==true"  [queryParams]="{ data: getQueryData(),isNewTab:true }" class="navigation-text delete-link">
              {{ 'DELETE_SITE' | translate }}
          </a>      
        </div>
      </div>
    </div>
  </form>
</div>
