<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/org-support/search">Organisation user support</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link">Success</a>
      </li>
    </ol>
  </div>
  <p class="govuk-body-l">{{displayMessage}}</p>
  <p><a class="navigation-text" [routerLink]="['/org-support/search']" [queryParams]="{isNewTab:true}">Return to Organisation user support</a></p>
</div>