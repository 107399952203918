<div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
        <div class="govuk-breadcrumbs">
            <ol class="govuk-breadcrumbs__list">
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <span class="govuk-breadcrumbs__link"> {{ 'DATA_MIGRATION' | translate }}</span>
                </li>
            </ol>
        </div>

        <h1 class="govuk-heading-xl page-title">{{ 'DATA_MIGRATION' | translate }}</h1>
            <p class="govuk-body-l">{{ 'DATA_MIGRATION_DESC' |
                translate }}</p>
        <div class="govuk-error-summary form-error form-error" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
            id="error-summary" data-module="govuk-error-summary"
            *ngIf="(errorRequired || errorInvalidFileFormat || errorServer || fileSizeExceedError) && submitted">
            <h2 class="govuk-error-summary__title" id="error-summary-title">
                {{ 'ERROR_SUMMARY' | translate }}
            </h2>
            <div class="govuk-error-summary__body">
                <ul class="govuk-list govuk-error-summary__list">
                    <li *ngIf="submitted && errorRequired">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{'ERROR_FILE_REQUIRED' | translate }}</a>
                    </li>
                    <li *ngIf="submitted && errorInvalidFileFormat">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{'ERROR_INVALID_FILE_FORMAT' | translate }}</a>
                    </li>
                    <li *ngIf="submitted && errorServer">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{ 'ERROR_FILE_INVALID'| translate }}</a>
                    </li>
                    <li *ngIf="submitted && fileSizeExceedError">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{ 'ERROR_FILE_SIZE_ERROR'| translate }} {{maxFileSize}}MB</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form">
            <form class="ui form profile mb-two">
                <div class="ui segment">

                    <div class="govuk-form-group"
                        [class.govuk-form-group--error]="submitted && ( fileSizeExceedError || errorRequired || errorInvalidFileFormat || errorServer)">
                        <label class="govuk-label" for="file-upload">
                            Upload a file
                        </label>
                        <span *ngIf="submitted && errorRequired" id="file-upload-error" class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_FILE_REQUIRED' | translate }}
                        </span>
                        <span *ngIf="submitted && errorInvalidFileFormat" id="file-upload-error"
                            class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_INVALID_FILE_FORMAT' | translate }}
                        </span>
                        <span *ngIf="submitted && errorServer" id="file-upload-error" class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_FILE_INVALID' | translate }}
                        </span>
                        <span *ngIf="submitted && fileSizeExceedError" id="file-upload-error" class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                            {{ 'ERROR_FILE_SIZE_ERROR'| translate }} {{maxFileSize}}MB
                        </span>
                        <input #input class="govuk-file-upload" id="file-upload" name="file-upload" type="file"
                            accept=".csv" (change)="readFile($event)"
                            [class.govuk-input--error]="submitted && ( errorRequired || errorInvalidFileFormat || errorServer)">
                    </div>
                    <div class="govuk-button-group save-cancel-button-group">
                        <button type="button" (click)="onContinueClick('Continue')" class="govuk-button"
                            data-module="govuk-button">
                            {{ 'CONTINUE_BTN' | translate }}
                        </button>
                        <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
                            data-module="govuk-button">
                            {{ 'Cancel' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="govuk-form-group" id="user-group-form-group">
            <fieldset class="govuk-fieldset">
                <legend class="govuk-heading-s">
                    {{ 'HISTORY_UPLOAD'| translate }}
                </legend>
                <div class="user-table" *ngIf="userUploadHistoryTable.userList.dataMigrationList.length > 0; else no_results">
                    <app-custom-govuk-table [headerTextKeys]="userUploadHistoryTable.usersTableHeaders" [data]="userUploadHistoryTable.userList.dataMigrationList"
                    [dataKeys]="userUploadHistoryTable.usersColumnsToDisplay" [isHyperLinkVisible]="false"
                    [pageName]="userUploadHistoryTable.pageName" [hyperArrayVisible]="true"   [hyperLinkArray]=userUploadHistoryTable.hyperTextrray
                     [useServerPagination]="true" [serverPageCount]="userUploadHistoryTable.userList.pageCount" (hyperLinkClickEvent)="onLinkClick($event)"
                    [serverPageCurrentPage]="userUploadHistoryTable.currentPage" (changeCurrentPageEvent)="setPage($event)"></app-custom-govuk-table>
                </div>
                <ng-template #no_results>
                    <p class="govuk-body govuk-!-font-weight-bold">
                        There are no historical uploads.
                    </p>
                </ng-template>
            </fieldset>
        </div>
    </div>
</div>
