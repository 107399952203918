<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ "ADMINISTRATOR_DASHBOARD" | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-buyer-both" (click)="goBack('Return to Manage Buyer status requests')">
                            Return to Manage Buyer status requests</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link">View Buyer status for the organisation</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title header-bread-align">View organisations’ Buyer status</h1>
            <div>
                <p class="govuk-body-l">
                    View details of organisations’ Buyer status
                </p>
            </div>
            <div class="span-group govuk-!-margin-top-4">
                <h2 class="govuk-heading-m">Organisation details</h2>
                <p class="govuk-body">
                    Organisation name&nbsp;:<strong
                        class="govuk-!-margin-left-2">{{routeDetails.event.organisationName}}</strong>
                </p>
                <p class="govuk-body">
                    Organisation type&nbsp;&nbsp;&nbsp;:<strong
                        class="govuk-!-margin-left-2">{{routeDetails.event.organisationType == 0 ? 'Supplier' :
                        (routeDetails.event.organisationType == 1 ? 'Buyer' : 'Both')}}</strong>
                </p>
                <p class="govuk-body">
                    Right to buy status&nbsp;:<strong class="govuk-!-margin-left-2">{{routeDetails.event.auditStatus==5?
                        'Not Applicable' : routeDetails.event.rightToBuy ?
                        'Approved' : 'Declined'}}</strong>
                </p>

            </div>
            <div class="span-group govuk-!-margin-top-4">
                <h2 class="govuk-heading-m">Organisation Administrator(s)</h2>
                <div class="govuk-grid-row">
                    <div class="govuk-grid-column-full">
                        <div class="user-table">
                            <app-govuk-table [headerTextKeys]="organisationAdministrator.usersTableHeaders"
                                [data]="organisationAdministrator.userListResponse.userList"
                                [dataKeys]="organisationAdministrator.usersColumnsToDisplay"
                                [useServerPagination]="true" [isHyperLinkVisible]="false"
                                [serverPageCount]="organisationAdministrator.pageCount"
                                [serverPageCurrentPage]="organisationAdministrator.currentPage"
                                (changeCurrentPageEvent)="setPageOrganisationAdministrator($event)"
                                [pageName]="pageName"></app-govuk-table>
                        </div>
                    </div>
                </div>
                <div class="span-group govuk-!-margin-top-4">
                <h2 class="govuk-heading-m">Organisation registries</h2>
                    <app-registry-govuk-table [pageName]="'MBSR'" [orgId]="routeDetails.event.organisationId"></app-registry-govuk-table>
                </div>
                <div class="span-group govuk-!-margin-top-4">
                    <h2 class="govuk-heading-m">Event log</h2>
                    <div class="govuk-grid-row">
                        <div class="govuk-grid-column-full">
                            <div class="user-table">
                                <app-govuk-table [headerTextKeys]="eventLog.usersTableHeaders"
                                    [data]="eventLog.organisationAuditEventListResponse.organisationAuditEventList"
                                    [dataKeys]="eventLog.usersColumnsToDisplay" [useServerPagination]="true"
                                    [serverPageCount]="eventLog.pageCount"
                                    [serverPageCurrentPage]="eventLog.currentPage"
                                    (changeCurrentPageEvent)="setPageOrganisationEventLogs($event)"></app-govuk-table>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="govuk-button-group" *ngIf="routeDetails.event.rightToBuy === true">
                    <button type="button" class="govuk-button govuk-button--warning" data-module="govuk-button"
                        (click)="removeRightToBuy('Remove right to buy status')" *ngIf="!isDeletedOrg">Remove right to buy status</button>
                    <button type="submit" class="govuk-button govuk-button--secondary" data-module="govuk-button"
                       routerLink="/manage-buyer-both"  (click)="goBack('Back')">Back</button>
                </div>
                <div *ngIf="routeDetails.event.rightToBuy != true">
                    <button type="submit" class="govuk-button govuk-button--secondary" data-module="govuk-button"
                        routerLink="/manage-buyer-both" (click)="goBack('Back')">Back</button>
                    <p *ngIf="!isDeletedOrg" class="govuk-body"><a class="govuk-link navigation-text"
                        [routerLink]="['/update-org-services/confirm']"  [queryParams]="{ data: getQueryData(),isNewTab:true }">View / edit organisations’ right to buy status</a></p>
                </div>
            </div>
        </div>
