<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
    <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">Manage your organisation</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link">{{'REMOVE_REG' | translate }}</span>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">Confirm you want to remove the below from your registries?</h1>
  <p *ngIf="item$ | async as i">
    <strong style="font-weight: 500;">{{schemeDetail.schemeName}}</strong>
    <br />{{ i?.identifier?.id }} - {{ i?.identifier?.legalName }}
  </p>
  <p class="para_custom" style="font-weight: bold;">This cannot be undone</p>
  <button type="submit" (click)="onSubmit('Confirm and delete')" class="govuk-button govuk-button--warning">Confirm and delete</button>
  <button type="button" style="margin-left:20px;" (click)="goBack('Cancel')"
    class="govuk-button govuk-button--secondary">Cancel</button>
</div>