<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="navigation-text" class="govuk-breadcrumbs__link" routerLink="/home">Home</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link">Reset your password</span>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title custom_heading">Reset your password</h1>
  <p class="govuk-body-l">Enter the email address that you used when you registered for the Public Procurement Gateway.</p>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-three-quarters">
      <form class="govuk-!-width-three-quarters"
        [class.error]="submitted && (resetForm.controls.userName.hasError('required') || submitted && resetForm.controls.userName.invalid)"
        [formGroup]="resetForm">
        <div class="govuk-form-group">
          <div id="error-summary"
            *ngIf="submitted && (resetForm.controls.userName.hasError('required') || submitted && resetForm.controls.userName.invalid )"
            class="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
            data-module="govuk-error-summary">
            <h2 class="govuk-error-summary__title" id="error-summary-title"> There is a problem</h2>
            <div class="govuk-error-summary__body">
              <ul class="govuk-list govuk-error-summary__list">
                <li *ngIf="submitted && resetForm.controls.userName.hasError('required')">
                  <a href="javascript:;" onClick="return false;" (click)="setFocus(0)"> {{
                    'ERROR_EMAIL_REQUIRED' | translate }}</a>
                </li>
                <li *ngIf="submitted && !resetForm.controls.userName.errors?.required && resetForm.controls.userName.invalid">
                  <a href="javascript:;" onClick="return false;" (click)="setFocus(0)"> {{
                    'ERROR_USERNAME_INCORRECT_FORMAT' | translate }} </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="govuk-form-group"
          [class.govuk-form-group--error]="submitted && (resetForm.controls.userName.hasError('required') || resetForm.controls.userName.invalid)">
          <label class="govuk-label" for="userName">{{ 'EMAIL' | translate }}</label>
          <span class="govuk-error-message" *ngIf="submitted && resetForm.controls.userName.hasError('required')">
            <span class="govuk-visually-hidden">Error:</span>{{
            'ERROR_EMAIL_REQUIRED' | translate }}
          </span>
          <span class="govuk-error-message" *ngIf="submitted && !resetForm.controls.userName.errors?.required && resetForm.controls.userName.invalid">
            <span class="govuk-visually-hidden">Error:</span>{{
            'ERROR_USERNAME_INCORRECT_FORMAT' | translate }}
          </span>
          <input #input class="govuk-input" id="userName" formControlName="userName" name="userName"
            size="20" type="email" value="" autocomplete="email" spellcheck="false"
           (input)="validateEmailLength($event)"
            [class.govuk-input--error]="submitted && (resetForm.controls.userName.hasError('required') || resetForm.controls.userName.invalid)">
        </div>
        <div class="govuk-form-group govuk-button-group">
          <button type="submit" class="govuk-button" data-module="govuk-button"
            (click)="onSubmit(resetForm,'Reset password')">{{ 'RESET_PASSWORD_BTN' | translate }}</button>
          <button class="govuk-button govuk-button--secondary" data-module="govuk-button" (click)="onCancelClick('Back')">{{
            'BACK' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>