<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" [hidden]="!isCustomMfaEnabled">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
    </li>
        <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link"> {{ 'ORG_TYPE' | translate }}</span>
        </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">Are you a supplier, buyer or both?</h1>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-three-quarters">
      <form class="govuk-!-width-four-quarters">
        <div class="govuk-form-group">
          <fieldset class="govuk-fieldset">
            <h2 class="govuk-heading-m">
                Select the organisation you work for
            </h2>
            <div class="govuk-radios">
              <div class="govuk-radios__item" id="supplierRadioSelection">
                <input class="govuk-radios__input" id="supplierRadioButton" name="radioSelectionGroup" type="radio"
                  value="supplier" [(ngModel)]="defaultChoice">
                <label class="govuk-label govuk-radios__label" for="supplierRadioButton">
                  <strong>Supplier</strong>
                </label>
                <div id="supplier-item-hint" class="govuk-hint govuk-radios__hint">
                  Your organisation is a supplier if you supply goods to CCS buyers
                </div>
              </div>
              <div class="govuk-radios__item" id="buyerRadioSelection">
                <input class="govuk-radios__input" id="buyerRadioButton" name="radioSelectionGroup" type="radio"
                  value="buyer" [(ngModel)]="defaultChoice">
                <label class="govuk-label govuk-radios__label" for="buyerRadioButton">
                  <strong>Buyer</strong>
                </label>
                <div id="buyer-item-hint" class="govuk-hint govuk-radios__hint">
                  Your organisation is a buyer if you buy goods using CCS
                </div>
              </div>
              <div class="govuk-radios__item" id="bothRadioSelection">
                <input class="govuk-radios__input" id="bothRadioButton" name="radioSelectionGroup" type="radio"
                  value="both" [(ngModel)]="defaultChoice">
                <label class="govuk-label govuk-radios__label" for="bothRadioButton">
                  <strong>Both</strong> 
                </label>
                <div id="buyerSupplier-item-hint" class="govuk-hint govuk-radios__hint">
                  Your organisation is both if you buy and sell goods using CCS
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="govuk-details__text detail-text govuk-!-margin-bottom-5 detail-text-bg" *ngIf="defaultChoice=='both'">
          <legend class="govuk-fieldset__legend">          
              <strong> How we define both</strong>         
          </legend>
          <p class="govuk-!-margin-0">        
              An example of both may be:
          </p>          
            <ul><li class="govuk-!-margin-left-5  govuk-!-margin-bottom-4">You buy goods and services from CCS, and you also supply goods and services to other CCS buyers.</li></ul>            
          <p class="govuk-!-margin-0">        
            You are <strong>not</strong> both if:
          </p>          
            <ul><li class="govuk-!-margin-left-5"><p class="govuk-!-margin-bottom-0">You supply to a CCS buyer, but you buy from third parties to meet the agreement. In other words,</p>
            <p class="govuk-!-margin-top-0"> periphery arrangements with third parties or subcontractors for example, are <strong>not</strong> relevant to this definition.</p>
            </li></ul>        
        </div>
        <div class="govuk-form-group">
          <button id="continueButton" type="button" class="govuk-button" data-module="govuk-button"
            (click)="onSubmit('Continue')">Continue</button>
        </div>
      </form>
    </div>
  </div>
</div>
