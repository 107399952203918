<div class="content-flex-a">
    <div class="content-left">
        <fieldset class="govuk-fieldset">
            <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 class="govuk-fieldset__heading">
                    {{'CHECK_YOUR_PHONE' | translate}}
                </h1>
            </legend>
            <p class="govuk-body-l govuk-margin-custom"> We have sent a code to
                <span><strong>{{phonenumber}}</strong></span>
            </p>
            <p class="govuk-body-l"> It might take a few minutes to arrive.</p>
            <form [formGroup]="formGroup" (ngSubmit)="onContinueBtnClick(otp.value)">
                <div class="govuk-text-input-group" id="otp-form-group"
                    [class.govuk-form-group--error]="submitted &&formGroup.controls.otp.invalid">
                    <span id="invalid-otp-error" class="govuk-error-message"
                        *ngIf="submitted && formGroup.controls.otp.invalid">
                        <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                        Please provide valid code.
                    </span>
                    <span id="invalid-sms-error" class="govuk-error-message" *ngIf="isTooManySms">
                        <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                        {{errorMsg}}
                    </span>
                    <label class="govuk-label" for ="message-otp"> Enter the code:</label>
                    <input class="govuk-input govuk-input govuk-input--width-20" maxlength="6" id="message-otp" name="otp"
                        type="text" formControlName="otp" #otp
                        [class.govuk-input--error]="submitted &&formGroup.controls.otp.invalid">
                </div>
                <details class="govuk-details" data-module="govuk-details">
                    <summary class="govuk-details__summary">
                        <span class="govuk-details__summary-text">
                            {{'PROBLEMS_WITH_CODE' | translate}}
                        </span>
                    </summary>
                    <div class="govuk-details__text">
                        <p> We can <a href="javascript:;" class="navigation-text" (click)="onResendOtpLinkClick()">send
                                the
                                code again</a> or you can use a <a href="javascript:;" class="navigation-text"
                                (click)="onReEnterPhoneNumberClick()"> different phone number.</a></p>
                    </div>
                </details>
                <div class="govuk-button-group">
                    <button type="submit" class="govuk-button"
                        [disabled]="formGroup.controls.otp.invalid" (click)="pushDataLayerEvent('Continue')" data-module="govuk-button">
                        {{ 'CONTINUE_BTN' | translate }}
                    </button>
                    <button type="button" class="govuk-button govuk-button--secondary" (click)="onBackBtnClick('Back')"
                        data-module="govuk-button">
                        {{ 'BACK' | translate }}
                    </button>
                </div>
                <div class="govuk-2mfa-link">
                    <a href="javascript:;" class="navigation-text" (click)="onNavigateToMFAClick()">
                        {{'BACK_TO_2MFA' | translate }}
                    </a>
                </div>
            </form>
        </fieldset>
    </div>
    <div class="content-right">
        <div class="govuk-custom-grid">
            <div class="card card-help">
                <h2 class="card-title">Need Help?</h2>
                <p class="govuk-body-s">If you are unsuccessful at signing in then please contact your<br> Organisation
                    administrator.</p>
            </div>
            <div class="card card-help">
                <h2 class="card-title">SMS service not working?</h2>
                <p class="govuk-body-s">If the SMS service not working for you, please contact us so we can set you back
                    up.</p>
            </div>

        </div>
    </div>
</div>