<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="content flex">
      <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
              <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
          </li>
          <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
        </li>
          <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
         </li>
         <li class="govuk-breadcrumbs__list-item" *ngIf="buyerFlow == 3">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{ 'BUYER_TYPE_BC' |
            translate }}</a>
        </li>
         <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' | translate }}</a>
         </li>
         <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link"  href="javascript:;" (click)="goBack()">{{ 'CONFIRM_ORG_DETAILS' | translate }}</a>
         </li>
            <li class="govuk-breadcrumbs__list-item">
                <span class="govuk-breadcrumbs__link"> {{ 'WRONG_ORG_DETAILS' | translate }}</span>
            </li>
        </ol>
      </div>
      <h1 class="govuk-heading-xl page-title">
        My organisation address is wrong
      </h1>
      <p class="govuk-body-l">Continue creating your administrator account. Once you have set up your administrator account please get in touch with the registry directly to change your details.</p>
      <div class="govuk-form-group">
        <button id="continueNotRegistered" type="button" class="govuk-button" data-module="govuk-button"
          (click)="onContinueClick('Continue')">Continue</button>
      </div>
    </div>
  </div>
</div>