<div class="contact-assign-container">
    <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate}}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">{{ 'MANAGE_YOUR_ORGANISATION'
                    |translate}}</a>
            </li>
            <li *ngIf="assigningSiteId != 0" class="govuk-breadcrumbs__list-item">
                <a href="javascript:;" class="govuk-breadcrumbs__link" [routerLink]="['/manage-org/profile/site/edit']" [queryParams]="{ data: getQueryData() }"(click)="onNavigateToSiteClick()">{{ 'EDIT_SITE' |
                    translate
                    }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item" *ngIf="!siteCreate">
                <span class="govuk-breadcrumbs__link"> {{ 'SUCCESS' | translate }}</span>
            </li>
            <li class="govuk-breadcrumbs__list-item" *ngIf="assigningSiteId != 0 && siteCreate">
                <span class="govuk-breadcrumbs__link"> Site {{siteInfo.siteName}} successfully created</span>
            </li>
        </ol>
    </div>
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <p class="govuk-body-l" *ngIf="assigningOrgId != ''">
                {{ 'ASSIGN_TO_ORG_CONTACT_SUCCESS' | translate }}
            </p>
            <div *ngIf="assigningSiteId != 0">
                <p class="govuk-body-l" *ngIf="siteCreate">You have successfully added site {{siteInfo.siteName}}</p>
                <p class="govuk-body govuk-!-font-size-19 gov_success_msg">
                    {{ 'ASSIGN_TO_SITE_CONTACT_SUCCESS' | translate }}
                </p>
            </div>
            <a [routerLink]="['/manage-org/profile']" [queryParams]="{isNewTab:true}" *ngIf="assigningOrgId != '' || assigningSiteId != 0" class="navigation-text">
                {{'RETURN_MANAGE_YOUR_ORGANISATION' | translate }} </a>&nbsp;&nbsp;&nbsp;&nbsp;

            <a [routerLink]="['/manage-org/profile/site/edit']"  [queryParams]="{ data: getQueryData() }" *ngIf="assigningSiteId != 0" class="navigation-text"
                (click)="onNavigateToSiteClick()">
                Return to {{siteInfo.siteName}} details </a>
        </div>
    </div>
</div>
