<div class="govuk-notification-banner govuk-notification-banner--success" *ngIf="cookiesUpdated">
    <div class="govuk-notification-banner__header">
        <h2 class="govuk-notification-banner__title" id="govuk-notification-banner-title">
            Success
        </h2>
    </div>
    <div class="govuk-notification-banner__content">
        <p class="govuk-notification-banner__heading">
            You’ve set your cookie preferences. <a class="govuk-notification-banner__link" (click)="onback()">Go back to
                the page you were looking at</a>.
        </p>
    </div>
</div>
<div class="govuk-width-container">
    <main class="govuk-main-wrapper govuk-!-padding-top-0 govuk-!-padding-bottom-12" id="main-content" role="main">
        <div class="govuk-grid-row">
            <div class="govuk-grid-column-two-thirds">
                <div class="govuk-breadcrumbs">
                    <ol class="govuk-breadcrumbs__list">
                        <li class="govuk-breadcrumbs__list-item">
                            <a *ngIf="this.isOrgAdmin && userName != ''" class="govuk-breadcrumbs__link" routerLink="/home">
                                {{ 'ADMINISTRATOR_DASHBOARD' | translate }}
                            </a>
                            <a *ngIf="!this.isOrgAdmin && userName != ''" class="govuk-breadcrumbs__link"
                                routerLink="/home">
                                {{"PUBLIC_PROCUREMENT_GATEWAY_DASHBOARD" | translate}}
                            </a>
                            <a *ngIf="userName == ''" class="govuk-breadcrumbs__link" routerLink="/home">
                                {{"REGITERATION_HOME" | translate}}
                            </a>
                        </li>
                        <li class="govuk-breadcrumbs__list-item">
                            <span class="govuk-breadcrumbs__link">Cookies</span>
                        </li>
                    </ol>
                </div>
                <div class="cookie-settings__confirmation banner banner-with-tick" data-cookie-confirmation="true"
                    role="group" tabindex="-1">
                </div>
                <h1 class="heading-large">Cookies</h1>
                <p class="govuk-body">
                    Cookies are small files saved on your phone, tablet or computer when you visit a website.
                </p>
                <p class="govuk-body">We use cookies to make the Public Procurement Gateway work and collect information
                    about how you use our service.</p>

                <h2 class="heading-medium" id="essential-cookies">Essential cookies</h2>
                <p class="govuk-body">
                    Essential cookies keep your information secure while you use the Public Procurement Gateway. We do
                    not need to ask permission to use them.
                </p>
                <div>
                    <table class="govuk-table">
                        <thead class="govuk-table__head">
                            <tr class="govuk-table__row">
                                <th scope="col" class="govuk-table__header">Name</th>
                                <th scope="col" class="govuk-table__header">Purpose</th>
                                <th scope="col" class="govuk-table__header">Expires</th>
                            </tr>
                        </thead>
                        <tbody class="govuk-table__body">
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">ppg_cookies_policy</td>
                                <td class="govuk-table__cell">Saves your cookie consent settings.</td>
                                <td class="govuk-table__cell">{{cookie_policy}} yr</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">ccs-sso-visitedsites</td>
                                <td class="govuk-table__cell">To handle backchannel logout (Function the Single Sign on
                                    System).</td>
                                <td class="govuk-table__cell">{{ccs_sso_visitedsites}} min</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">opbs</td>
                                <td class="govuk-table__cell">Opbs cookie (Function the Single Sign on System).</td>
                                <td class="govuk-table__cell">{{opbs}} min</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">ccs_sso</td>
                                <td class="govuk-table__cell">Session information (Function the Single Sign on System).
                                </td>
                                <td class="govuk-table__cell">{{ccs_sso}} min</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">conclave</td>
                                <td class="govuk-table__cell">Store the refresh token (Function the Single Sign on
                                    System).</td>
                                <td class="govuk-table__cell">Until Session exists</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">XSRF-TOKEN</td>
                                <td class="govuk-table__cell">For the protection of Cross-Site Request Forgery attacks.
                                </td>
                                <td class="govuk-table__cell">Until Session exists</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">XSRF-TOKEN-SVR</td>
                                <td class="govuk-table__cell">For the protection of Cross-Site Request Forgery attacks.
                                </td>
                                <td class="govuk-table__cell">Until Session exists</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">.AspNetCore.Antiforgery.GWNWkbbyKbw</td>
                                <td class="govuk-table__cell">For the protection of Cross-Site Request Forgery attacks.
                                </td>
                                <td class="govuk-table__cell">Until Session exists</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">auth0</td>
                                <td class="govuk-table__cell">Used to implement the Auth0 session layer.</td>
                                <td class="govuk-table__cell">{{auth0}} days</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">auth0_compat </td>
                                <td class="govuk-table__cell">Fallback cookie for single sign-on on browsers that don’t
                                    support the sameSite=None attribute.</td>
                                <td class="govuk-table__cell">{{auth0_compat}} days</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">did</td>
                                <td class="govuk-table__cell">Device identification for attack protection.</td>
                                <td class="govuk-table__cell">{{did}} hr</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">did_compat</td>
                                <td class="govuk-table__cell">Fallback cookie for anomaly detection on browsers that
                                    don’t support the sameSite=None attribute.</td>
                                <td class="govuk-table__cell">{{did_compat}} hr</td>
                            </tr>
                            <tr class="govuk-table__row">
                                <td class="govuk-table__cell">_cf_bm </td>
                                <td class="govuk-table__cell">This cookie is used by our authentication service Auth0 to
                                    distinguish between humans and bots. In this way, valid reports on the use of the
                                    website can be created.</td>
                                <td class="govuk-table__cell">{{_cf_bm}} min</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="analytics">
                    <h2 class="heading-medium" id="analytics-cookies">Analytics cookies (optional)</h2>
                    <div class="googleanalytics">
                        <h3 class="govuk-heading-s">Google Analytics</h3>
                        <p class="govuk-body">
                            With your permission, we use Google Analytics to collect data about how you use the Public
                            Procurement Gateway. This information helps us to improve our service.
                        </p>
                        <p class="govuk-body">
                            Google is not allowed to use or share our analytics data with anyone.
                        </p>
                        <p class="govuk-body">
                            Google Analytics stores anonymised information about:
                        </p>
                        <ul class="govuk-list govuk-list--bullet">
                            <li>how you got to the Public Procurement Gateway</li>
                            <li>the pages you visit on the Public Procurement Gateway and how long you spend on them
                            </li>
                            <li>any errors you see while using the Public Procurement Gateway</li>
                        </ul>
                        <div>
                            <table class="govuk-table">
                                <thead class="govuk-table__head">
                                    <tr class="govuk-table__row">
                                        <th scope="col" class="govuk-table__header">Name</th>
                                        <th scope="col" class="govuk-table__header">Purpose</th>
                                        <th scope="col" class="govuk-table__header">Expires</th>
                                    </tr>
                                </thead>
                                <tbody class="govuk-table__body">
                                    <tr class="govuk-table__row">
                                        <td class="govuk-table__cell">_ga</td>
                                        <td class="govuk-table__cell">This helps us count how many people visit CCS by
                                            tracking if you’ve visited before.</td>
                                        <td class="govuk-table__cell">2 yrs</td>
                                    </tr>
                                    <tr class="govuk-table__row">
                                        <td class="govuk-table__cell">_gid</td>
                                        <td class="govuk-table__cell">This helps us count how many people visit CCS by
                                            tracking if you’ve visited before.</td>
                                        <td class="govuk-table__cell">24 hrs</td>
                                    </tr>
                                    <tr class="govuk-table__row">
                                        <td class="govuk-table__cell">_gat_UA-47046847-22</td>
                                        <td class="govuk-table__cell">This helps us count how many people visit CCS by
                                            tracking if you’ve visited before.</td>
                                        <td class="govuk-table__cell">1 min</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="cookie-settings__form-wrapper">
                            <form data-module="cookie-settings">
                                <div class="govuk-form-group govuk-!-margin-top-6">
                                    <fieldset class="govuk-fieldset" aria-describedby="changed-name-hint">
                                        <legend class="govuk-fieldset__legend govuk-fieldset__legend--s">
                                            Do you want to accept analytics cookies for Google Analytics?
                                        </legend>
                                        <div class="govuk-radios govuk-radios--inline">
                                            <div class="govuk-radios__item">
                                                <input class="govuk-radios__input" id="cookies-analytics-yes"
                                                    name="cookies-analytics" type="radio" [value]="true"
                                                    [(ngModel)]="cookiesValue.additional" (change)="formEdited()">
                                                <label class="govuk-label govuk-radios__label"
                                                    for="cookies-analytics-yes">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="govuk-radios__item">
                                                <input class="govuk-radios__input" id="cookies-analytics-no"
                                                    name="cookies-analytics" type="radio" [value]="false"
                                                    [(ngModel)]="cookiesValue.additional" (change)="formEdited()">
                                                <label class="govuk-label govuk-radios__label"
                                                    for="cookies-analytics-no">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="glass-box">
                        <h3 class="govuk-heading-s">Glass box</h3>
                        <p class="govuk-body">
                            We use Glassbox software to collect information about how you use the Public Procurement
                            Gateway. We do this to help make sure the site is meeting the needs of its users and to help
                            us make improvements.
                        </p>
                        <p class="govuk-body">
                            Glassbox stores information about:
                        </p>
                        <ul class="govuk-list govuk-list--bullet">
                            <li>Browsing activity</li>
                            <li>Click-stream activity,</li>
                            <li>Session heatmaps and</li>
                            <li>Scrolls</li>
                        </ul>
                        <p class="govuk-body">
                            We don’t collect or store your personal information (e.g. your name or address) so this
                            information can’t be used to identify who you are.
                        </p>
                        <p class="govuk-body">
                            We don’t allow Glassbox to use or share our analytics data.
                        </p>
                        <div>
                            <table class="govuk-table">
                                <thead class="govuk-table__head">
                                    <tr class="govuk-table__row">
                                        <th scope="col" class="govuk-table__header">Name</th>
                                        <th scope="col" class="govuk-table__header cookiestable-padding">Purpose</th>
                                        <th scope="col" class="govuk-table__header">Expires</th>
                                    </tr>
                                </thead>
                                <tbody class="govuk-table__body">
                                    <tr class="govuk-table__row">
                                        <td class="govuk-table__cell">_cls_s</td>
                                        <td class="govuk-table__cell cookiestable-padding">The session identifying
                                            cookie. It also indicates if the visitor is new or existing.
                                            <ul class="cookiestable-padding">
                                                <li>
                                                    0: New
                                                </li>
                                                <li>
                                                    1: Existing
                                                </li>
                                            </ul>
                                        </td>
                                        <td class="govuk-table__cell">2 yrs</td>
                                    </tr>
                                    <tr class="govuk-table__row">
                                        <td class="govuk-table__cell">_cls_v</td>
                                        <td class="govuk-table__cell cookiestable-padding">The visitor identifying
                                            cookie.</td>
                                        <td class="govuk-table__cell">2 yrs</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="cookie-settings__form-wrapper">
                            <form data-module="cookie-settings">
                                <div class="govuk-form-group govuk-!-margin-top-6">
                                    <fieldset class="govuk-fieldset" aria-describedby="changed-name-hint">
                                        <legend class="govuk-fieldset__legend govuk-fieldset__legend--s">
                                            Do you want to accept analytics cookies for Glass box?
                                        </legend>
                                        <div class="govuk-radios govuk-radios--inline">
                                            <div class="govuk-radios__item">
                                                <input class="govuk-radios__input" id="cookies-glassbox-yes"
                                                    name="cookies-glassbox" type="radio" [value]="true"
                                                    [(ngModel)]="cookiesValue.glassbox" (change)="formEdited()">
                                                <label class="govuk-label govuk-radios__label"
                                                    for="cookies-glassbox-yes">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="govuk-radios__item">
                                                <input class="govuk-radios__input" id="cookies-glassbox-no"
                                                    name="cookies-glassbox" type="radio" [value]="false"
                                                    [(ngModel)]="cookiesValue.glassbox" (change)="formEdited()">
                                                <label class="govuk-label govuk-radios__label"
                                                    for="cookies-glassbox-no">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <button class="govuk-button" type="submit" (click)="OnSubmit()">Save cookie
                                    settings</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>