<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex" *ngIf="items$ | async as items">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">Manage your organisation</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link">Add new registry</span>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">Add new registry</h1>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-three-quarters">
      <div class="govuk-error-summary radio-form-error" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
        id="error-summary" data-module="govuk-error-summary" *ngIf="submitted && txtValue == ''">
        <h2 class="govuk-error-summary__title" id="error-summary-title">
          {{ 'ERROR_SUMMARY' | translate }}
        </h2>
        <div class="govuk-error-summary__body">
          <ul class="govuk-list govuk-error-summary__list">
            <li>
              <a href="javascript:;" (click)="setFocus(0)">
                {{ "ERROR_ENTER_REGISTRY_NUMBER" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="govuk-form-group">
        <fieldset class="govuk-fieldset">
          <legend class="govuk-fieldset__legend govuk-fieldset__legend--m">
            What type of registry do you want to add?
          </legend>
          <div class="govuk-radios">
            <div class="govuk-radios__item custom_radio" *ngFor="let item of newScheme | scheme"
            [class.govuk-form-group--error]="
              submitted &&
              (schemeSubjectObs | async) === item.scheme &&
              txtValue == ''
            ">
            <div *ngIf="checkShowStatus(item)">
              <input class="govuk-radios__input" type="radio" [attr.id]="item.scheme + '-RadioButton'" name="scheme"
                [value]="item.scheme" value="{{ item.scheme }}" [checked]="(schemeSubjectObs | async) === item.scheme"
                [(ngModel)]="scheme" (click)="onSelect(item)" />
              <label for="{{ item.scheme + '-RadioButton' }}" class="govuk-label govuk-radios__label">{{
                item.schemeName
                }}</label>

              <div *ngIf="(schemeSubjectObs | async) === item.scheme" id="{{ item.scheme + '-Div' }}"
                class="box-input">
                <label for="{{ item.scheme + '-TextBox' }}" class="govuk-hint govuk-radios__hint">
                  <div *ngIf="item.schemeName === 'Department for Education'; else otherSchema">
                    <span id="name-error" class="govuk-error-message" *ngIf="submitted && txtValue == ''">
                      <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                      Please enter a registry number
                    </span>
                    Enter your {{ item.schemeName }} Unique Reference Number (URN)
                  </div>
                  <ng-template #otherSchema>
                    <span id="name-error" class="govuk-error-message" *ngIf="submitted && txtValue == ''">
                      <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                      'Please enter a registry number' | translate }}
                    </span>
                    Enter your {{ item.schemeName }} Number
                  </ng-template>

                  <P class="govuk-body govuk-!-font-weight-regular custom_span"
                    *ngIf="item.scheme === 'GB-COH'">
                    <span> Go to the </span>
                    <a href="https://find-and-update.company-information.service.gov.uk/?" class="navigation-text govuk-link"
                      rel="noreferrer noopener" target="_blank">Companies House</a>
                    <span> website to search for your organisation details</span>
                  </P>
                  <P class="govuk-body govuk-!-font-weight-regular custom_span"
                    *ngIf="item.scheme === 'GB-CHC'">
                    <span> Go to the </span>
                    <a href="https://register-of-charities.charitycommission.gov.uk/charity-search" class="navigation-text govuk-link"
                      rel="noreferrer noopener" target="_blank">Charity Commission for England and Wales</a>
                    <span> website to search for your organisation details</span>
                  </P>
                  <P class="govuk-body govuk-!-font-weight\regular custom_span"
                    *ngIf="item.scheme === 'GB-SC'">
                    <span> Go to the </span>
                    <a href="https://www.oscr.org.uk/search/" class="navigation-text govuk-link" rel="noreferrer noopener"
                      target="_blank">Office of the Scottish Charity Regulator</a>
                    <span> website to search for your organisation details</span>
                  </P>
                  <P class="govuk-body govuk-!-font-weight-regular custom_span"
                    *ngIf="item.scheme === 'GB-NIC'">
                    <span> Go to the </span>
                    <a href="https://www.charitycommissionni.org.uk/charity-search/?pageNumber=1" class="navigation-text govuk-link"
                      rel="noreferrer noopener" target="_blank">Charity Commission for Northern Ireland</a>
                    <span> website to search for your organisation details</span>
                  </P>
                  <P class="govuk-body govuk-!-font-weight-regular custom_span"
                    *ngIf="item.scheme === 'GB-NHS'">
                    <span> Go to the </span>
                    <a href="https://odsportal.digital.nhs.uk/Organisation/Search" class="navigation-text govuk-link"
                      rel="noreferrer noopener" target="_blank">National Health Service Organisations Registry</a>
                    <span> website to search for your organisation details</span>
                  </P>
                  <P class="govuk-body govuk-!-font-weight-regular custom_span"
                    *ngIf="item.scheme === 'GB-EDU'">
                    <span> Go to the </span>
                    <a href="https://www.get-information-schools.service.gov.uk" class="navigation-text govuk-link"
                      rel="noreferrer noopener" target="_blank">Department for Education</a>
                    <span> website to search for your organisation details</span>
                  </P>
                  <P *ngIf="validationObj.activeElement === 'US-DUN'"
                    class="govuk-body govuk-!-font-weight-regular custom_span">
                    <span> Go to the </span>
                    <a href="https://www.dnb.co.uk/duns-number/lookup.html" class="navigation-text govuk-link"
                      rel="noreferrer noopener" target="_blank">Dun & Bradstreet</a>
                    <span> website to search for your organisation details</span>
                  </P>
                </label>
                <input #input id="{{ item.scheme + '-TextBox' }}" class="govuk-input govuk-input--width-10 text-input"
                  type="text" [(ngModel)]="txtValue" [class.govuk-input--error]="
                  submitted &&
                  (schemeSubjectObs | async) === item.scheme &&
                  txtValue == ''
                " />
              </div>
            </div>
          </div>
          </div>
        </fieldset>
      </div>
      <div class="govuk-button-group save-cancel-button-group">
        <button type="button" class="govuk-button" data-module="govuk-button" (click)="onSubmit('Continue')">{{'CONTINUE_BTN' | translate }}</button>
      </div>
    </div>
  </div>
</div>
