<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
      <div class="content flex">
        <a id="returnHomeLink" class="navigation-text" routerLink="/home"
          class="govuk-back-link">Back</a>
      </div> 
    </div>
  </div>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
      <div class="content-flex">
        <div class="content-left" *ngIf="userActivation == true">
          <div class="content flex">
            <h1 class="govuk-heading-xl page-title">You have activated your delegated access.</h1>
            <p class="govuk-body-l">You can switch between your original and delegating organisation in Manage my delegated access tile on your Public Procurement Dashboard.
            </p>
          </div>
        </div>
      </div>
      <div class="govuk-form-group" *ngIf="userActivation == false">
        <div class="govuk-error-summary govuk-grid-column-three-quarters"
            aria-labelledby="error-summary-title" role="alert" tabindex="-1"
            data-module="govuk-error-summary">
            <h2 class="govuk-error-summary__title" id="error-summary-title"> There is a problem</h2>
            <div class="govuk-error-summary__body">
                <ul class="govuk-list govuk-error-summary__list">
                    <a onClick="return false;" href="javascript:;"
                        >
                        <li>The activation link for the delegated access is expired. Please request a new link from the delegating organisation.</li>
                    </a>
                </ul>
            </div>
        </div>
    </div>
    </div>
  </div>