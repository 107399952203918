<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
    <div class="govuk-breadcrumbs">
      <ol class="govuk-breadcrumbs__list">
          <li class="govuk-breadcrumbs__list-item">
              <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
              <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
              <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
              <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
       </li>
       <li class="govuk-breadcrumbs__list-item" *ngIf="buyerFlow == 3">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{ 'BUYER_TYPE_BC' |
            translate }}</a>
        </li>
       <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' | translate }}</a>
       </li>
          <li class="govuk-breadcrumbs__list-item">
              <span class="govuk-breadcrumbs__link"> {{ 'NOT_FOUND' | translate }}</span>
          </li>
      </ol>
    </div>
    <p class="govuk-body-l">The registry you have selected is currently unavailable. Please try again later or select a different registry to register with.</p>
    <p><a id="searchWithDifferentIdLink" class="navigation-text" routerLink="/manage-org/register/search">Search with a different organisation ID</a></p>
  </div>