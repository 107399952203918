<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' |
                            translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-users">{{ 'MANAGE_YOUR_USER_ACCOUNTS'
                            |translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> Confirm password reset</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl">Confirm password reset</h1>
            <p class="govuk-body-l">Send password reset email to user {{userName}}</p>
            <!-- <h1 class="govuk-heading-xl"> {{ 'CONFIRM_RESET_PASSWORD_FOR' | translate }} {{userName}}</h1> -->
            <div class="govuk-button-group save-cancel-button-group">
                <button type="submit" (click)="onConfirmClick('Send password reset')" class="govuk-button" data-module="govuk-button">
                    Send password reset
                </button>
                <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button">
                    {{ 'CANCEL_BTN' | translate }}
                </button>
            </div>

        </div>

    </div>
</div>