<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
    </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item" *ngIf="buyerFlow == 3">
      <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{ 'BUYER_TYPE_BC' |
        translate }}</a>
    </li>
     <li class="govuk-breadcrumbs__list-item">
      <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item">
      <a class="govuk-breadcrumbs__link"  href="javascript:;" (click)="goBack('Confirm organisation details')">{{ 'CONFIRM_ORG_DETAILS' | translate }}</a>
     </li>
        <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link"> {{ 'ADD_REG' | translate }}</span>
        </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl">We found matching records for your organisation from the additional registries
    below</h1>
  <div>
    <div>
      <fieldset *ngIf="organisation?.additionalIdentifiers.length > 0" class="govuk-fieldset">
        <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
          Please unselect any that you do not want to include in your registration
        </legend>
        <div *ngFor="let ai of organisation?.additionalIdentifiers; let index = index" class="govuk-checkboxes"
          data-module="govuk-checkboxes">
          <div class="govuk-checkboxes__item">
            <input class="govuk-checkboxes__input" id="{{ 'chk-id-' + index }}" type="checkbox" checked="checked"
              (change)="onChange($event, ai)">
            <label class="govuk-label govuk-checkboxes__label" for="{{ 'chk-id-' + index }}">
              {{ ai?.id }} - {{ ai?.legalName }}
            </label>
            <div id="{{ 'chk-id-hint-' + index }}" class="govuk-hint govuk-checkboxes__hint">
              {{ getSchemaName(ai?.scheme) }}
            </div>
          </div>
        </div>
      </fieldset>
      <div class="govuk-form-group govuk-button-group button-group">
        <button type="button" class="govuk-button" data-module="govuk-button" (click)="onSubmit('Continue')">Continue</button>
        <!-- <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
          (click)="goBack('Back')">Back</button> -->
      </div>
    </div>
  </div>
</div>