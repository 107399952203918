<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/org-support/search">Organisation user support</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link">Confirm</a>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title" style="margin-top: 50px;">{{displayMessage}}</h1>
  <div class="govuk-button-group" style="margin-top: 40px;">
    <button type="submit" class=" govuk-button" [disabled]="!canContinue" data-module="govuk-button"
      (click)="onSubmitClick('Save and Continue')">Save and Continue</button>
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
      (click)="onCancelClick('Save and Continue')">Cancel</button>
  </div>
</div>