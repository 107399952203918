<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
      <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
          <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate}}</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link">Manage service eligibility</span>
          </li>
        </ol>
      </div>
      <h1 class="govuk-heading-xl page-title">Manage service eligibility</h1>
      <h2 *ngIf="showRoleView" style="margin-top: 15px; margin-bottom: 40px;">Search and select the organisation profile that you would like
        to manage the buyer status for</h2>
        <h2 *ngIf="!showRoleView" style="margin-top: 15px; margin-bottom: 40px;">Search and select the organisation profile that you would like to manage organisation type and services for</h2>
      <div class="govuk-form-group" id="search-form-group" [class.govuk-form-group--error]="searchSumbited && searchText ===''">
        <label class="govuk-label" for="search"> Search for organisation </label>
        <span id="first-name-error" class="govuk-error-message"
        *ngIf="searchSumbited && searchText ===''">
        <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> Enter an organisation’s name
      </span>
        <app-search-box [(searchText)]="searchText" (onSearchClickEvent)="onSearchClick()" [pageName]="'Manage Buyers-Suppliers'">
        </app-search-box>
      </div>
    </div>
  </div>
  
  <div class="govuk-grid-row" *ngIf="data && data.orgList.length !=0;else no_results">
    <div class="govuk-grid-column-full">
      <div>
        <app-govuk-table [headerTextKeys]="tableHeaders" [data]="data.orgList" [serverPageCount]="pageCount" 
          [dataKeys]="tableColumnsToDisplay" [isRadioVisible]="true" (radioClickEvent)="onSelectRow($event)"  
          [useServerPagination]="true" [serverPageCurrentPage]="currentPage" (changeCurrentPageEvent)="setPage($event)"></app-govuk-table>
      </div>
    </div>
  </div>

  <ng-template #no_results>
    <p class="govuk-body govuk-!-font-weight-bold">There are no matching results.</p>
  </ng-template>
  <div class="govuk-button-group">
    <button type="button" class="govuk-button"
      [class.govuk-button--disabled]="selectedOrgId === ''" data-module="govuk-button" (click)="onContinueClick('Continue')"
      [disabled]="selectedOrgId === ''">Continue</button>
    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
      (click)="onCancelClick('Cancel')">Cancel</button>
  </div>
</div>