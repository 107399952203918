<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" role="link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' |
                            translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" role="link" routerLink="/manage-groups">{{ 'MANAGE_GROUPS' | translate }}</a>
                    </li>
                    <li *ngIf="isEdit==true" class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" tabindex=0 role="link"  [routerLink]="['/manage-groups/view']" [queryParams]="{ data: getEditQueryData() }" (click)="onCancelClick('Edit group')">{{ 'EDIT_GROUP' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'DELETE_GROUP' | translate }}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl">Delete group : {{GroupDetails.groupName}}</h1>
            <p class="govuk-body">{{ 'CONFIRM_GROUP_DELETE' | translate }}{{ 'THIS_CANNOT_BE_UNDONE' | translate }}</p>
            <div class="govuk-button-group continue-cancel-button-group">
                <button type="submit" (click)="onDeleteConfirmClick('Confirm and delete')" class="govuk-button govuk-button--warning"
                    data-module="govuk-button">
                    {{ 'CONFIRM_DELETE_BTN' | translate }}
                </button>
                <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button">
                    {{ 'CANCEL_BTN' | translate }}
                </button>
            </div>

        </div>

    </div>
</div>