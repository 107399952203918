<!-- <div class="content flex" [@slide]="(sideNavVisible$ | async) ? 'close' : 'open'"> -->
<div class="user-profile-container">
    <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/home">
                    {{isOrgAdmin == true ? 'Administrator dashboard' : (isOrgAdmin == false ? 'Public Procurement Gateway dashboard' : '')}}
                </a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <span class="govuk-breadcrumbs__link"> {{'MANAGE_MY_ACCOUNT' | translate}}</span>
            </li>
        </ol>
    </div>
    <h1 class="govuk-heading-xl page-title">{{'MANAGE_MY_ACCOUNT' | translate}}</h1>
    <div class="govuk-error-summary form-error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
        id="error-summary" data-module="govuk-error-summary" *ngIf="submitted && ((formGroup.controls.firstName.invalid && formGroup.controls.firstName.touched) ||
            (formGroup.controls.lastName.invalid && formGroup.controls.lastName.touched))">
        <h2 class="govuk-error-summary__title" id="error-summary-title">
            {{ 'ERROR_SUMMARY' | translate }}
        </h2>
        <div class="govuk-error-summary__body">
            <ul class="govuk-list govuk-error-summary__list">
                <li
                    *ngIf="submitted && formGroup.controls.firstName.errors?.required  && formGroup.controls.firstName.touched">
                    <a href="javascript:;" onClick="return false;" (click)="setFocus(0)"> {{
                        'ERROR_FIRST_NAME' | translate }} </a>
                </li>
                <li
                    *ngIf="submitted && formGroup.controls.lastName.errors?.required  && formGroup.controls.lastName.touched">
                    <a href="javascript:;" onClick="return false;" (click)="setFocus(1)"> {{
                        'ERROR_LAST_NAME' | translate }}</a>
                </li>
                <li
                    *ngIf="submitted && (!formGroup.controls.firstName.errors?.required && !formGroup.controls.lastName.errors?.required) && (formGroup.controls.firstName.invalid || formGroup.controls.lastName.invalid)">
                    <a href="javascript:;" onClick="return false;" (click)="focusUserProfileInput()"> {{
                        'ENTER_NAME_WITHOUT_NUM_SPECIAL' | translate }}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="form">
        <form class="ui form profile mb-two" [formGroup]="formGroup" (submit)="onSubmit(formGroup)">
            <div class="ui segment">

                <div class="govuk-grid-row">
                    <div class="govuk-grid-column-full">
                        <div class="content flex">
                            <div class="content-left">
                                <div class="govuk-form-group" id="first-name-form-group"
                                    [class.govuk-form-group--error]="submitted && formGroup.controls.firstName.invalid && formGroup.controls.firstName.touched">
                                    <label class="govuk-label" for="first-name"> {{ 'FIRSTNAME' | translate }} </label>
                                    <span id="first-name-error" class="govuk-error-message"
                                        *ngIf="submitted && formGroup.controls.firstName.errors?.required && formGroup.controls.firstName.touched">
                                        <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                                        'ERROR_FIRST_NAME' | translate }}
                                    </span>
                                    <span id="firstname-error" class="govuk-error-message"
                                        *ngIf="submitted && !formGroup.controls.firstName.errors?.required && formGroup.controls.firstName.invalid">
                                        <span class="govuk-visually-hidden">Error:</span>{{
                                        'ENTER_NAME_WITHOUT_NUM_SPECIAL' | translate }}
                                    </span>
                                    <input #input formControlName="firstName" class="govuk-input" id="first-name"
                                        name="first-name" type="text"
                                        [class.govuk-input--error]="submitted && formGroup.controls.firstName.invalid && formGroup.controls.firstName.touched">
                                </div>
                                <div class="govuk-form-group" id="last-name-form-group"
                                    [class.govuk-form-group--error]="submitted && formGroup.controls.lastName.invalid && formGroup.controls.lastName.touched">
                                    <label class="govuk-label" for="last-name"> {{ 'LASTNAME' | translate }} </label>
                                    <span id="last-name-error" class="govuk-error-message"
                                        *ngIf="submitted && formGroup.controls.lastName.errors?.required && formGroup.controls.lastName.touched">
                                        <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                                        'ERROR_LAST_NAME'
                                        | translate }}
                                    </span>
                                    <span id="lastname-error" class="govuk-error-message"
                                        *ngIf="submitted && !formGroup.controls.lastName.errors?.required && formGroup.controls.lastName.invalid">
                                        <span class="govuk-visually-hidden">Error:</span>{{
                                        'ENTER_NAME_WITHOUT_NUM_SPECIAL' | translate }}
                                    </span>
                                    <input #input formControlName="lastName" class="govuk-input" id="last-name"
                                        name="last-name" type="text"
                                        [class.govuk-input--error]="submitted && formGroup.controls.lastName.invalid && formGroup.controls.lastName.touched">
                                </div>
                                <div class="govuk-form-group">
                                    <label class="govuk-label" for="email">{{ 'EMAIL' | translate }}</label>
                                    <div id="email-hint" class="govuk-hint">
                                        {{ 'USER_EMAIL_HINT' | translate }}
                                    </div>
                                    <input [readonly]="true" [class.disabled]="true" class="govuk-input" id="email"
                                        name="email" type="email" size="20" value="" autocomplete="email"
                                        spellcheck="false" [value]="userName">
                                </div>
                                <div class="govuk-radio">
                                    <app-govuk-radio [details]="userTypeDetails"
                                        (clickedData)="onUserTypeChanged($event)"></app-govuk-radio>
                                </div>

                                <div *ngIf="!isCustomMfaEnabled" class="govuk-form-group" id="user-mfa-form-group">
                                    <fieldset class="govuk-fieldset">
                                        <h2 class="govuk-heading-s">
                                            Additional security
                                        </h2>
                                        <p class="govuk-hints">{{ detailsData[0] }}</p>
                                        <div class="govuk-form-group govuk-checkboxes__item" id="user-mfa-form-group">
                                            <input [attr.disabled]=true class="govuk-checkboxes__input" id="mfaEnabled"
                                                formControlName="mfaEnabled" name="mfaEnabled" type="checkbox">
                                            <label class="govuk-label govuk-checkboxes__label" for="mfaEnabled">
                                                Additional security using digital device
                                            </label><br>
                                        </div>
                                    </fieldset>
                                </div>
                                <button *ngIf="!isCustomMfaEnabled" type="button"
                                    class="govuk-button govuk-button--secondary" data-module="govuk-button"
                                    [disabled]="!formGroup.controls.mfaEnabled.value"
                                    (click)="ResetAdditionalSecurity('Reset my additional security')">Reset my additional security</button>

                                <div class="govuk-form-group" *ngIf="isCustomMfaEnabled">
                                    <fieldset class="govuk-fieldset">
                                        <h2 class="govuk-heading-s">
                                            {{'TWO_FACTOR_AUTHENTICATION_ORG_REGISTERATION' | translate}}
                                        </h2>
                                        <p *ngIf="isMfaEnabledForUser" class="govuk-hint" style="color: black;">
                                            Currently <strong>On</strong> for this account using the
                                            <strong>{{authenticationType}}.</strong></p>
                                        <p *ngIf="!isMfaEnabledForUser" class="govuk-hint" style="color: black;">
                                            Currently <strong>Off</strong> for this account</p>
                                        <div>
                                            <details class="govuk-details" data-module="govuk-details">
                                                <summary class="govuk-details__summary">
                                                    <span class="govuk-details__summary-text">
                                                        {{'WHAT_IS_2FA' | translate}}
                                                    </span>
                                                </summary>
                                                <div class="govuk-details__text">
                                                    <p>
                                                        {{'2FA_HELPTEXT' | translate}}
                                                    </p>
                                                </div>
                                            </details>
                                        </div>
                                        <div  class="govuk-radios" data-module="govuk-radios">
                                            <div class="govuk-radios__item">
                                                <input #input class="govuk-radios__input" id="mfa-on" name="mfaEnabled" [attr.disabled] ="orgMfaRequired?true:null"
                                                    type="radio" [value]="true" formControlName="mfaEnabled"  (change)="onRadioChange(formGroup)" >
                                                <label class="govuk-label govuk-radios__label" for="mfa-on">
                                                    <strong> {{'MFA_ON' | translate}}</strong>
                                                </label>
                                            </div>
                                            <div class="govuk-radios__item">
                                                <input #input class="govuk-radios__input" id="mfa-off" name="mfaEnabled"[attr.disabled] ="orgMfaRequired?true:null"
                                                    type="radio" [value]="false" formControlName="mfaEnabled" (change)="onRadioChange(formGroup)" >
                                                <label class="govuk-label govuk-radios__label" for="mfa-off">
                                                    <strong> {{'MFA_OFF'| translate }} </strong>
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div *ngIf="isCustomMfaEnabled && isMfaEnabledForUser">
                                    <div id="reset-mfa-hint" class="govuk-hint" style="margin-top: 15px;">
                                        {{ 'RESET_MFA_HINT' | translate }}
                                    </div>
                                    <button type="button" (click)="onResetMfaClick('Reset my 2FA')" class="govuk-button"
                                    [disabled] = "!formGroup.controls.mfaEnabled.value"
                                        data-module="govuk-button">{{ 'RESET_MFA_BTN' |
                                        translate }}
                                    </button>
                                </div>

                                <div>
                                    <p class="govuk-body govuk-!-font-size-19">
                                        {{"SIGN_IN_PROVIDER"| translate}} : {{identityProviderDisplayName}}</p>
                                </div>
                                <br>
                                <button *ngIf="canChangePassword" type="button" (click)="onChangePasswordClick('Change password')"
                                    class="govuk-button govuk-button--secondary change-password-button"
                                    data-module="govuk-button">
                                    {{ 'CHANGE_PASSWORD_BTN' | translate }}
                                </button>
                            </div>

                            <div class="content-right">
                                <div class="card card-help govuk-details__text detail-text">
                                    <h3 class="card-title">Have an issue?</h3>
                                    <a class="navigation-text" [routerLink]="['/contact-admin']" [queryParams]="{isNewTab:true}">Contact my admin</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="GROUPS">
                    <h2 class="govuk-heading-s"> {{ 'GROUPS' | translate }}</h2>
                    <app-details [detailsData]="detailsData[1]"></app-details>
                    <p>
                </div>

                <div class="govuk-grid-row">
                    <div class="govuk-grid-column-two-thirds">
                        <app-accordion [headerTextKeys]="groupsMember.headerTextKey"
                            [headerText]="groupsMember.headerText" [noRoleText]="groupsMember.noRoleText"
                            [noDataGroupsMemberMessage]="groupsMember.noDataGroupsMemberMessage"
                            [accessTable]="groupsMember.accessTable" [data]="groupsMember.data"
                            [isAdmin]="groupsMember.isAdmin" [groupShow]="groupsMember.groupShow"
                            (checkBoxAddRoles)="groupsMemberCheckBoxAddRoles($event)"
                            (checkBoxRemoveRoles)="groupsMemberCheckBoxRemoveRoles($event)"></app-accordion>

                        <app-accordion *ngIf="noneGroupsMember.isAdmin"
                            [headerTextKeys]="noneGroupsMember.headerTextKey" [noRoleText]="noneGroupsMember.noRoleText"
                            [noDatanoneGroupsMemberMessage]="noneGroupsMember.noDatanoneGroupsMemberMessage"
                            [headerText]="noneGroupsMember.headerText" [accessTable]="noneGroupsMember.accessTable"
                            [data]="noneGroupsMember.data" [isAdmin]="noneGroupsMember.isAdmin"
                            [groupShow]="noneGroupsMember.groupShow"
                            (checkBoxAddRoles)="noneGroupsMemberCheckBoxAddRoles($event)"
                            (checkBoxRemoveRoles)="noneGroupsMemberCheckBoxRemoveRoles($event)"></app-accordion>
                    </div>
                </div>

                <div *ngIf="roleDataList.length == 0">
                    <div class="role_view" *ngIf="showRoleView">
                        <h2 class="govuk-heading-s"> {{ 'ROLES' | translate }}</h2>
                        <app-details [detailsData]="detailsData[2]"></app-details>
                    </div>
                    <div class="service_view" *ngIf="!showRoleView">
                        <h2 class="govuk-heading-s"> {{ 'SERVICES' | translate }}</h2>
                        <p class="govuk-hints">{{groupHint}}</p>
                        <br>
                    </div>
                    <p>{{'NONE'| translate}}
                    <p>
                </div>
                <div class="govuk-grid-row">
                    <div class="govuk-grid-column-two-thirds">
                        <div *ngIf="roleDataList.length > 0">
                            <div class="role_view" *ngIf="showRoleView">
                                <h2 class="govuk-heading-s"> {{ 'ROLES' | translate }}</h2>
                                <app-details [detailsData]="detailsData[2]"></app-details>
                                <div class="roles_table" *ngIf="isAdminUser === true">
                                    <span class="table_caption">To help you choose the correct roles use the table
                                        below:</span>
                                    <table style="width:100%">
                                        <tr>
                                            <th>For whom</th>
                                            <th>Task</th>
                                            <th>Roles to tick</th>
                                        </tr>
                                        <tr>
                                            <td rowspan="2" class="column_one_space">
                                                Organisational Administrators of Public Procurement Gateway (PPG)
                                            </td>
                                            <td class="litrel_space">Add an additional PPG administrator</td>
                                            <td class="roles_padding">
                                                <ul class="listing">
                                                    <li>Organisation Administrator - Dashboard Service</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="litrel_space" class="column_one_space">Add user to PPG</td>
                                            <td class="roles_padding">
                                                <ul class="listing">
                                                    <li>Organisation User - Dashboard Service</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowspan="2" class="column_one_space">
                                                Buyers
                                            </td>
                                            <td class="litrel_space">Enable Contract Award Service</td>
                                            <td class="roles_padding">
                                                <ul class="listing">
                                                    <li>eSourcing Service as a buyer</li>
                                                    <li>Contract Award Service (CAS) - add service</li>
                                                    <li>Contract Award Service (CAS) - add to dashboard</li>
                                                    <li>eSourcing Service - add service</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="litrel_space">Enable eSourcing</td>
                                            <td class="roles_padding">
                                                <ul class="listing">
                                                    <li>eSourcing Service as a buyer
                                                    </li>
                                                    <li>eSourcing Service - add service
                                                    </li>
                                                    <li>eSourcing Service - add to dashboard
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="column_one_space">Suppliers</td>
                                            <td class="litrel_space">Enable eSourcing</td>
                                            <td class="roles_padding">
                                                <ul class="listing">
                                                    <li>eSourcing Service as a supplier</li>
                                                    <li>eSourcing Service - add service</li>
                                                    <li>eSourcing Service - add to dashboard</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr>
                                    </table>
                                    <br>
                                </div>
                            </div>
                            <div class="service_view" *ngIf="!showRoleView">
                                <h2 class="govuk-heading-s"> {{ 'SERVICES' | translate }}</h2>
                                <p class="govuk-hints">{{groupHint}}</p>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="govuk-tabs" data-module="govuk-tabs">
                    <ul class="govuk-tabs__list">
                        <li class="govuk-tabs__list-item"
                            [ngClass]="{'govuk-tabs__list-item--selected' : tabConfig.userservices}"
                            (click)="tabChanged('user-service')">
                            <a class="govuk-tabs__tab" href="javascript:;" onClick="return false;">
                                Individual access
                            </a>
                        </li>
                        <li class="govuk-tabs__list-item"
                            [ngClass]="{'govuk-tabs__list-item--selected' : tabConfig.groupservices}"
                            (click)="tabChanged('group-service')">
                            <a class="govuk-tabs__tab" href="javascript:;" onClick="return false;">
                                Group access
                            </a>
                        </li>
                    </ul>
                    <div class="govuk-tabs__panel" id="user-service"
                        [ngClass]="{'govuk-tabs__panel--hidden' : !tabConfig.userservices}">
                        <h2 class="govuk-heading-m">Individual access</h2>
                        <div class="govuk-grid-row">
                            <div class="govuk-grid-column-full">
                                <p class="govuk-hints" *ngIf="isAdminUser == true">{{
                                    'ACCESS_TO_USER_SERVICE_MANAGE_MY_ACCOUNT'| translate }}</p>
                                <p class="govuk-hints" *ngIf="isAdminUser == false">{{ 'ACCESS_TO_REGULAR_USER_SERVICE'|
                                    translate }}</p>

                                <div *ngIf="roleDataList.length > 0 && isAdminUser==false">
                                    <div class="role_view" *ngIf="showRoleView">
                                        <app-govuk-table [headerTextKeys]="userRoleTableHeaders" [data]="roleDataList"
                                            [dataKeys]="userRoleColumnsToDisplay"
                                            [useClientPagination]="true"></app-govuk-table>
                                    </div>
                                    <div class="service_view" *ngIf="!showRoleView">
                                        <app-govuk-table [headerTextKeys]="userServiceTableHeaders"
                                            [data]="roleDataList" [dataKeys]="userServiceColumnsToDisplay"
                                            [useClientPagination]="true"></app-govuk-table>
                                    </div>
                                </div>
                                <div *ngIf="roleDataList.length > 0 && isAdminUser==true" class="govuk-checkboxes">
                                    <fieldset class="govuk-fieldset">
                                        <div class="govuk-checkboxes__item govuk-item-custom"
                                            *ngFor="let orgRole of roleDataList; let i=index"
                                            [ngClass]="{'govuk-item-custom-hide' : isHideRole(orgRole.roleKey)}">
                                            <div *ngIf="!isHideRole(orgRole.roleKey)">
                                                <input class="govuk-checkboxes__input"
                                                    id="{{'orgRoleControl_' + orgRole.roleId}}"
                                                    [attr.disabled]="getDisbleRole(orgRole.roleKey) ? true : null"
                                                    [formControlName]="'orgRoleControl_' + orgRole.roleId"
                                                    type="checkbox" value="hmrc"
                                                    (ngModelChange)="onUserRoleChecked(orgRole, $event)">
                                                <label *ngIf="orgRole.serviceName == null"
                                                    class="govuk-label govuk-checkboxes__label"
                                                    for="{{'orgRoleControl_' + orgRole.roleId}}"
                                                    [ngClass]="{'role_lable': showRoleView === false,'govuk-!-padding-top-0': orgRole.description != ''}">
                                                    {{orgRole.accessRoleName}}
                                                </label>
                                                <label *ngIf="orgRole.serviceName != null && showRoleView"
                                                    class="govuk-label govuk-checkboxes__label"
                                                    for="{{'orgRoleControl_' + orgRole.roleId}}">
                                                    {{orgRole.accessRoleName}} - {{orgRole.serviceName}}
                                                </label>
                                                <strong class="govuk-tag govuk-tag--grey"
                                                    *ngIf="checkIsPendingRole(orgRole)">
                                                    Pending Approval
                                                </strong>
                                                <p class="govuk-body description_para">{{orgRole.description}} </p>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="govuk-tabs__panel" id="group-service"
                        [ngClass]="{'govuk-tabs__panel--hidden' : !tabConfig.groupservices}">
                        <h2 class="govuk-heading-m">Group access</h2>
                        <p class="govuk-hints" *ngIf="orgUserGroupRoles.length > 0 && isAdminUser == true">{{
                            'ORG_ADMIN_GROUP_ACCESS'| translate }} <a class="navigation-text"
                                (click)="scrollContent('GROUPS')">manage group membership</a> {{'ORG_ADMIN_GROUP_MEMBER'
                            | translate}}</p>
                        <p class="govuk-hints" *ngIf="orgUserGroupRoles.length > 0 && isAdminUser == false ">{{
                            'ORG_USER_GROUP_ACCESS'| translate }}</p>

                        <p class="govuk-hints" *ngIf="orgUserGroupRoles.length <= 0 && isAdminUser == true">{{
                            'ORG_ADMIN_GROUP_ACCESS_NONE'| translate }} <a class="navigation-text"
                                (click)="scrollContent('GROUPS')">manage group membership</a>
                            {{'ORG_ADMIN_GROUP_ACCESS_NONE_CTN'| translate}}</p>
                        <p class="govuk-hints" *ngIf="orgUserGroupRoles.length <= 0 && isAdminUser == false">{{
                            'ORG_USER_GROUP_ACCESS_NONE'| translate }}</p>

                        <div *ngIf="orgUserGroupRoles.length > 0 && isAdminUser==false">
                            <div class="role_view" *ngIf="showRoleView">
                                <app-govuk-table [headerTextKeys]="userRoleTableHeaders" [data]="orgUserGroupRoles"
                                    [dataKeys]="userRoleColumnsToDisplay"
                                    [useClientPagination]="true"></app-govuk-table>
                            </div>
                            <div class="service_view" *ngIf="!showRoleView">
                                <app-govuk-table [headerTextKeys]="userServiceGroupTableHeaders"
                                    [data]="orgUserGroupRoles" [dataKeys]="userServiceGroupColumnsToDisplay"
                                    [useClientPagination]="true"></app-govuk-table>
                            </div>
                        </div>

                        <div *ngIf="orgUserGroupRoles.length > 0 && isAdminUser==true"
                            class="govuk-checkboxes govuk-checkboxes-custom-input">
                            <div class="govuk-checkboxes__item govuk-item-custom"
                                *ngFor="let orgRole of orgUserGroupRoles; let i=index">
                                <input class="govuk-checkboxes__input" id="{{'orgRoleControlGroup_' + orgRole.id}}"
                                    [attr.disabled]="true" [attr.checked]="true" type="checkbox" value="hmrc">
                                <label class="govuk-label govuk-checkboxes__label role_lable"
                                    [ngClass]="{'govuk-!-padding-top-0': orgRole.description != ''}"
                                    for="{{'orgRoleControlGroup_' + orgRole.id}}">
                                    {{orgRole.name}}
                                </label>
                                <strong class="govuk-tag govuk-tag--grey"
                                    *ngIf="orgRole.approvalStatus == 0 ? true : false">
                                    Pending Approval
                                </strong>
                                <p class="govuk-body description_para govuk-!-padding-top-0">{{orgRole.description}}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>



                <div id ="contact-details">
                    <h2 class="govuk-heading-s contact-detail"> {{ 'CONTACT_DETAILS' | translate }}</h2>
                    <app-details [detailsData]="detailsData[3]"></app-details>
                </div>
                <div class="govuk-grid-row">
                    <div class="govuk-grid-column-full">
                        <div>
                            <app-govuk-table [headerTextKeys]="contactTableHeaders" [data]="userContacts"
                                [dataKeys]="contactColumnsToDisplay" [isHyperLinkVisible]="true"
                                [hyperLinkText]="'EDIT'" (hyperLinkClickEvent)="onContactEditRow($event)"
                                [useClientPagination]="true">
                            </app-govuk-table>
                        </div>
                    </div>
                </div>
                <div class="govuk-button-group contact-button-group">
                    <button type="button" (click)="onContactAddClick(buttonText)" class="govuk-button govuk-button--secondary"
                        data-module="govuk-button">
                        {{ buttonText | translate }}
                    </button>
                    <!-- <button type="button" (click)="onContactAssignRemoveClick()"
                            class="govuk-button govuk-button--secondary" data-module="govuk-button">
                            {{ 'ADD_REMOVE_ORG_CONTACT_BTN' | translate }}
                        </button> -->
                </div>

                <div class="govuk-button-group save-cancel-button-group">
                    <button type="submit" class="govuk-button" (click)="pushDataLayerEvent('Save Change')" [disabled]="!isFormChanges" data-module="govuk-button">
                        {{ 'SAVE_BTN' | translate }}
                    </button>
                    <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
                        data-module="govuk-button">
                        {{ 'CANCEL_BTN' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- </div> -->