<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-groups">{{ 'MANAGE_GROUPS' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a href="javascript:;" class="govuk-breadcrumbs__link" [routerLink]="['/manage-groups/view']" [queryParams]="{ data: getQueryData()}"
                         (click)="onCancelClick('Edit group')">{{ 'EDIT_GROUP' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span *ngIf="isEdit== true" class="govuk-breadcrumbs__link">
                            {{'ADD_REMOVE_USERS'| translate }}</span>
                        <span *ngIf="isEdit!= true" class="govuk-breadcrumbs__link">
                            {{ 'ADD_USERS' | translate }}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <h1 *ngIf="isEdit== true" class="govuk-heading-xl page-title">
            {{ 'MANAGE_GROUP_USERS' | translate }} : {{groupName}}</h1>
        <h2 *ngIf="isEdit== true" class="govuk-heading-m">
            {{ 'SELECT_USERS_WANT_TO_ADD' | translate }} : {{groupName}}</h2>
        <h1 *ngIf="isEdit!= true" class="govuk-heading-xl page-title">{{ 'ADD_USERS_GROUP' | translate }} {{groupName}}</h1>
        <div>
            <div class="govuk-form-group" id="search-form-group" [class.govuk-form-group--error]="searchSumbited && searchingUserName ===''">
                <label class="govuk-label" for="search"> {{ 'SEARCH_FOR_USER' | translate }} </label>
                <span id="first-name-error" class="govuk-error-message"
                *ngIf="searchSumbited && searchingUserName ===''">
                <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> Enter a user’s name or email address
              </span>
                <app-search-box [(searchText)]="searchingUserName" (onSearchClickEvent)="onSearchClick()" [pageName]="'Add/Edit Users - Manage Groups'">
                </app-search-box>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
        <div class="user-table">
            <app-govuk-table [headerTextKeys]="usersTableHeaders" [data]="userGridSource"
                [dataKeys]="usersColumnsToDisplay" [isCheckBoxVisible]="true"
                (checkBoxClickEvent)="onCheckBoxClickRow($event)" [isHyperLinkVisible]="false"
                [useServerPagination]="true" [serverPageCount]="pageCount" [serverPageCurrentPage]="currentPage"
                (changeCurrentPageEvent)="setPage($event)">
            </app-govuk-table>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="govuk-button-group save-cancel-button-group">
            <button type="button" (click)="onContinueClick('Continue')"  [disabled] ="!isDataChanged" class="govuk-button" data-module="govuk-button">
                {{ 'CONTINUE_BTN' | translate }}
            </button>
            <div class="govuk-button-group save-cancel-button-group" >
                <button type="button" (click)="onCancelClick('Cancel and go to group')" class="govuk-button govuk-button--secondary"
                data-module="govuk-button" *ngIf="isEdit">
                Cancel and go to group
            </button>
            <button type="button" (click)="onCancelClick('Create group with no users and')" class="govuk-button govuk-button--secondary"
                data-module="govuk-button" *ngIf="!isEdit">
                Create group with no users and {{showRoleView === true ? 'roles': (showRoleView == false ? 'services' : '')}}
            </button>
        </div>
    </div>
</div>