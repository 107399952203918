<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div *ngIf="isAuthenticated == true" class="govuk-breadcrumbs">
      <ol class="govuk-breadcrumbs__list">
        <!-- <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' |
            translate}}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <span class="govuk-breadcrumbs__link"> {{ 'HELP_AND_SUPPORT' | translate }}</span>
        </li> -->
      </ol>
    </div>
  </div>
</div>
<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <h1 class="govuk-heading-xl">Page not found</h1>
    <!-- <h1 class="govuk-heading-xl"> {{ 'HELP_AND_SUPPORT' | translate }}</h1>
    <p class="govuk-body govuk-!-font-size-19"> {{ 'PAGE_DESIGN_IS_CURRENTLY_BEING_FINALISED' | translate }}</p> -->
  </div>
</div>