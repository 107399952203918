<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-groups">{{ 'MANAGE_GROUPS' | translate
                            }}</a>
                    </li>
                    <li *ngIf="isEdit== true" class="govuk-breadcrumbs__list-item">
                        <a href="javascript:;" class="govuk-breadcrumbs__link"  [routerLink]="['/manage-groups/view']" [queryParams]="{ data: getQueryData()}"
                         (click)="onCancelClick('Edit group')">{{ 'EDIT_GROUP' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" >
                        <span class="govuk-breadcrumbs__link" *ngIf="isEdit== true">
                            {{serviceRoleGroup.ADD_REMOVE_ROLES }}</span>

                            <span class="govuk-breadcrumbs__link" *ngIf="isEdit!= true">
                                {{ serviceRoleGroup.ADD_ROLES }}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <h1 *ngIf="isEdit== true" class="govuk-heading-xl page-title">
            {{ serviceRoleGroup.ADD_REMOVE_ROLES}} : {{groupName}}</h1>
        <h1 *ngIf="isEdit!= true" class="govuk-heading-xl page-title">{{ serviceRoleGroup.ADD_ROLES }}</h1>
        <h2 class="govuk-heading-m">
            {{ serviceRoleGroup.SELECT_ROLES_WANT_TO_ADD | translate }}</h2>
        <div *ngIf="showRoleView">
            <div class="govuk-form-group" id="search-form-group" [class.govuk-form-group--error]="searchSumbited && searchText ===''">
                <label class="govuk-label" for="search"> {{ 'SEARCH_FOR_ROLE' | translate }} </label>
                <span id="first-name-error" class="govuk-error-message"
                *ngIf="searchSumbited && searchText ===''">
                <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> Enter a role name
              </span>
                <app-search-box [(searchText)]="searchText" (onSearchClickEvent)="onSearchClick()" [pageName]="'Add/Edit Roles - Manage Groups'">
                </app-search-box>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <form [formGroup]="formGroup">
            <fieldset class="govuk-fieldset">
                <div class="govuk-checkboxes__item govuk-item-custom" *ngFor="let orgRole of roleGridSource; let i=index">
                    <input class="govuk-checkboxes__input" id="{{'orgRoleControl_' + orgRole.roleId}}"
                        [formControlName]="'orgRoleControl_' + orgRole.roleId" type="checkbox" value="hmrc" (ngModelChange)="onCheckBoxClickRow(orgRole,$event)">
                    <label *ngIf="orgRole.serviceName == null" class="govuk-label govuk-checkboxes__label govuk-!-padding-top-0"
                        for="{{'orgRoleControl_' + orgRole.roleId}}" [ngClass]="{'role_lable': showRoleView === false,'govuk-!-padding-top-0': orgRole.description != ''}">
                        {{orgRole.roleName}}
                    </label>
                    <label *ngIf="orgRole.serviceName != null && showRoleView" class="govuk-label govuk-checkboxes__label"
                        for="{{'orgRoleControl_' + orgRole.roleId}}">
                        {{orgRole.roleName}} - {{orgRole.serviceName}} 
                    </label>
                      <p *ngIf="!showRoleView" class="govuk-body description_para">{{orgRole.description}} </p>
                </div>
                
            </fieldset>
    </form>
    </div>

    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <div class="govuk-button-group save-cancel-button-group">
                <button type="button" (click)="onContinueClick('Continue')" class="govuk-button" data-module="govuk-button">
                    {{ 'CONTINUE_BTN' | translate }}
                </button>
                <button type="button" (click)="onCancelClick(serviceRoleGroup.CREATE_BTN)" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button" *ngIf="!isEdit">
                    {{ serviceRoleGroup.CREATE_BTN }}
                </button>
                <button type="button" (click)="onCancelClick('Cancel and go to group')" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button" *ngIf="isEdit">
                    Cancel and go to group 
                </button>
            </div>
        </div>
    </div>
</div>


