<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ "ADMINISTRATOR_DASHBOARD" | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-buyer-both">
                            Manage Buyer status requests</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link">
                            Approve right to buy status</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title header-bread-align">Confirm you want to accept right to buy status for the organisation {{routeDetails.organisationName}}</h1>
            <div>
                <p  class="govuk-body-l">
                    The organisation administrator(s) will be notified that the right to buy status was approved for the organisation
                </p>
            <div class="govuk-button-group save-cancel-button-group">
                <button type="submit" class="govuk-button govuk-button--primary" data-module="govuk-button"
                    (click)="confirm('Approve')">
                    Approve
                </button>
                <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
                    (click)="Back('Cancel')">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>