<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="govuk-breadcrumbs">
            <ol class="govuk-breadcrumbs__list">
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/manage-users/add-user-selection">{{ 'ADD_USERS' |
                        translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <span class="govuk-breadcrumbs__link"> {{ 'ADD_MULTIPLE_USERS' | translate }}</span>
                </li>
            </ol>
        </div>

        <h1 class="govuk-heading-xl page-title">{{ 'ADD_MULTIPLE_USERS' | translate }}</h1>

        <h2 class="govuk-heading-m page-title">{{ 'ADD_MULTIPLE_USERS_BY_UPLOADING_CSV' |
            translate }}</h2>

        <div class="govuk-error-summary form-error" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
            id="error-summary" data-module="govuk-error-summary"
            *ngIf="(errorRequired || errorInvalidFileFormat || errorServer || fileSizeExceedError) && submitted">
            <h2 class="govuk-error-summary__title" id="error-summary-title">
                {{ 'ERROR_SUMMARY' | translate }}
            </h2>
            <div class="govuk-error-summary__body">
                <ul class="govuk-list govuk-error-summary__list">
                    <li *ngIf="submitted && errorRequired">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{'ERROR_FILE_REQUIRED' | translate }}</a>
                    </li>
                    <li *ngIf="submitted && errorInvalidFileFormat">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{'ERROR_INVALID_FILE_FORMAT' | translate }}</a>
                    </li>
                    <li *ngIf="submitted && errorServer">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{ 'ERROR_FILE_INVALID'| translate }}</a>
                    </li>
                    <li *ngIf="submitted && fileSizeExceedError">
                        <a href="javascript:;" onClick="return false;" (click)="setFocus(0)">
                            {{ 'ERROR_FILE_SIZE_ERROR'| translate }} {{maxFileSize}}MB</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form">
            <form class="ui form profile mb-two">
                <div class="ui segment">

                    <div class="govuk-form-group"
                        [class.govuk-form-group--error]="submitted && ( fileSizeExceedError || errorRequired || errorInvalidFileFormat || errorServer)">
                        <label class="govuk-label" for="file-upload">
                            Upload the file
                        </label>
                        <span *ngIf="submitted && errorRequired" id="file-upload-error" class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_FILE_REQUIRED' | translate }}
                        </span>
                        <span *ngIf="submitted && errorInvalidFileFormat" id="file-upload-error"
                            class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_INVALID_FILE_FORMAT' | translate }}
                        </span>
                        <span *ngIf="submitted && errorServer" id="file-upload-error" class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> {{
                            'ERROR_FILE_INVALID' | translate }}
                        </span>
                        <span *ngIf="submitted && fileSizeExceedError" id="file-upload-error" class="govuk-error-message">
                            <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>
                            {{ 'ERROR_FILE_SIZE_ERROR'| translate }} {{maxFileSize}}MB
                        </span>
                        <input #input class="govuk-file-upload" id="file-upload" name="file-upload" type="file"
                            accept=".csv" (change)="readFile($event)"
                            [class.govuk-input--error]="submitted && ( errorRequired || errorInvalidFileFormat || errorServer)">
                    </div>
                    <div><a class="navigation-text template-link" href="{{bulkUploadTemplateUrl}}" target="_blank" (click)="onDownloadClick()" >{{
                            'DOWNLOAD_BULK_UPLOAD_TEMPLATE_FILE' | translate }} </a></div>
                    <div class="govuk-button-group save-cancel-button-group">
                        <button type="button" (click)="onContinueClick('Continue')" class="govuk-button"
                            data-module="govuk-button">
                            {{ 'CONTINUE_BTN' | translate }}
                        </button>
                        <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
                            data-module="govuk-button">
                            {{ 'CANCEL_BTN' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
