<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">

            <p class="govuk-body-l">
                {{ 'RESEND_LINK_SUCCESS' | translate }}{{ userName }} 
            </p>
            
            <a href="javascript:;" class="navigation-text" (click)="onNavigateLinkClick()"> {{
                'RETURN_TO_LOGIN_PAGE' | translate }} </a>
            <br>
        </div>
    </div>
</div>