<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ 'ADMINISTRATOR_DASHBOARD' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'MANAGE_GROUPS' | translate }}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title">{{ 'MANAGE_GROUPS' | translate }}</h1>
            <div class="manage-groups-bg">
                <p>Manage large numbers of users all at once. You can give your group a name, add users and assign them {{showRoleView === true ? 'roles': (showRoleView == false ? 'services' : '')}}.</p>
            </div>
            <button type="button" (click)="onAddClick('Create group')" class="govuk-button govuk-button--secondary"
                data-module="govuk-button">
                {{ 'CREATE_GROUP_BTN' | translate }}
            </button>

            <div class="govuk-form-group" id="search-form-group" [class.govuk-form-group--error]="searchSumbited && searchText ===''">
                <label class="govuk-label" for="search"> {{ 'SEARCH_FOR_GROUP' | translate }} </label>
                <span id="first-name-error" class="govuk-error-message"
                *ngIf="searchSumbited && searchText ===''">
                <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> Enter a group’s name
              </span>
                <app-search-box [(searchText)]="searchText" (onSearchClickEvent)="onSearchClick()" [pageName]="'Manage Groups'">
                </app-search-box>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
        <div class="user-table">
            <app-govuk-table [headerTextKeys]="groupsTableHeaders" [data]="groupList.groupList"
                [dataKeys]="groupsColumnsToDisplay" [isHyperLinkVisible]="true" [hyperLinkText]="'EDIT'"
                (hyperLinkClickEvent)="onEditRow($event)" [useClientPagination]="true"></app-govuk-table>
        </div>
    </div>
</div>