<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="content flex">
      <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
          <li class="govuk-breadcrumbs__list-item">
            <a class="navigation-text" class="govuk-breadcrumbs__link" routerLink="/home">Home</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
            <a class="navigation-text" class="govuk-breadcrumbs__link" routerLink="/forgot-password">Reset password</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link"> {{ 'SUCCESS' | translate }}</span>
          </li>
        </ol>
      </div>
      <h1 class="govuk-heading-xl page-title custom_heading">Please check your inbox</h1>
      <p class="govuk-body-l">
        {{ 'FORGOT_PASSWORD_SUCCESS' | translate }}{{ userName }}
      </p>
      <p class="govuk-body-l">
        {{ 'FORGOT_PASSWORD_DES' | translate }}
      </p>
      <details class="govuk-details" data-module="govuk-details">
        <summary class="govuk-details__summary">
          <span class="govuk-details__summary-text">
            I haven’t received an email.
          </span>
        </summary>
        <div class="govuk-details__text">
          <span>
           Please check your spam or junk folder.
          </span>
          <br>
          <br>
          <span>
            If you still cannot see the email then you may not have a PPG account. Please try
            <a href="javascript:;" class="navigation-text" routerLink="/manage-org/register">registering for a new account.</a>
          </span>
        </div>
      </details>

      <a href="{{ getSignOutEndpoint() }}" class="navigation-text" (click)="onNavigateLinkClick()"> {{
        'RETURN_TO_LOGIN_PAGE' | translate }} </a>
      <br>
    </div>
  </div>
</div>