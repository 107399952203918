<div class="content-flux">
    <div class="govuk-form-group">
        <fieldset class="govuk-fieldset">

            <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 class="govuk-fieldset__heading">
                {{'TWO_FACTOR_AUTHENTICATION_SETUP_YES' | translate}}
                </h1>
            </legend>
            <p class="govuk-body-l">Next time you log in you will need to use the code from your device.</p>
            <p class="govuk-body-l ">You will be redirected soon.</p>
        </fieldset>

    </div>
    <div class="govuk-2mfa-link">
        <a href="javascript:;" class="navigation-text" (click) = "onLogOutClick()">
            {{'LOGOUT' | translate }}<br>
        </a>

    </div>

</div>