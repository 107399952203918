<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-groups">{{ 'MANAGE_GROUPS' | translate
                            }}</a>
                    </li>
                    <li *ngIf="isEdit==true" class="govuk-breadcrumbs__list-item">
                        <a href="javascript:;" class="govuk-breadcrumbs__link" (click)="onGoToEditGroupClick()">
                            {{'EDIT_GROUP' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" >
                        <span class="govuk-breadcrumbs__link">{{ serviceRoleGroup.CONFIRM_ROLE }} {{ groupName }}</span>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <h1 *ngIf="isEdit==true" class="govuk-heading-xl page-title">{{ serviceRoleGroup.CONFIRM_FOLL_ROLE }}  {{groupName}}</h1>
        <h1 *ngIf="isEdit!=true" class="govuk-heading-xl page-title">{{ serviceRoleGroup.CONFIRM_FOLL_ROLE }}  {{groupName}}</h1>
    </div>
</div>

<div>
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <h2 class="govuk-heading-s">{{ serviceRoleGroup.ROLES_ADDED_TO_GROUP }}</h2>
            <div *ngIf="addingRoles.length == 0">
                <p>{{'NONE'| translate}}
                <p>
            </div>
        </div>
    </div>
    <div *ngIf="addingOrderRoles.length != 0" class="govuk-grid-row">
        <div class="govuk-grid-column-full">
            <div class="user-table">
                <app-govuk-table [headerTextKeys]="rolesTableHeaders" [data]="addingOrderRoles"
                    [dataKeys]="rolesColumnsToDisplay" [useClientPagination]="true">
                </app-govuk-table>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isEdit==true">
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <h2 class="govuk-heading-s">{{ serviceRoleGroup.ROLES_REMOVED_FROM_GROUP}}</h2>
            <div *ngIf="removingRoles.length == 0">
                <p>{{'NONE'| translate}}
                <p>
            </div>
        </div>
    </div>
    <div *ngIf="removingOrderdRoles.length != 0" class="govuk-grid-row">
        <div class="govuk-grid-column-full">
            <div class="user-table">
                <app-govuk-table [headerTextKeys]="rolesTableHeaders" [data]="removingOrderdRoles"
                    [dataKeys]="rolesColumnsToDisplay" [useClientPagination]="true">
                </app-govuk-table>
            </div>
        </div>
    </div>
</div>



<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="govuk-button-group save-cancel-button-group">
            <button *ngIf="addingRoles.length != 0 || isEdit" type="submit" (click)="onConfirmClick('Confirm')" class="govuk-button" data-module="govuk-button">
                {{ 'CONFIRM_BTN' | translate }}
            </button>
            <button *ngIf="addingRoles.length == 0 && !isEdit" type="submit" (click)="onConfirmClick(serviceRoleGroup.CREATE_GROUP_WITHOUT_ROLE)" class="govuk-button" data-module="govuk-button">
                {{serviceRoleGroup.CREATE_GROUP_WITHOUT_ROLE}}
            </button>
            <button type="button" (click)="onCancelClick(serviceRoleGroup.SELECT_SERVICE)" class="govuk-button govuk-button--secondary"
                data-module="govuk-button">
                {{serviceRoleGroup.SELECT_SERVICE}}
            </button>
        </div>
    </div>
</div>