<div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
        <div class="govuk-breadcrumbs">
            <ol class="govuk-breadcrumbs__list">
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/data-migration/upload">{{ 'DATA_MIGRATION' |
                        translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <span class="govuk-breadcrumbs__link"> {{ 'DATA_MIGRATION_ERROR_SUMMARY' | translate }}</span>
                </li>
            </ol>
        </div>
        <h1 class="govuk-heading-xl page-title">{{ 'DATA_MIGRATION_ERROR_SUMMARY_HEADING' | translate }}</h1>
        <div class="user-table">
            <app-govuk-table [headerTextKeys]="userUploadHistoryTable.usersTableHeaders"
                [data]="userUploadHistoryTable.data.errorList" [dataKeys]="userUploadHistoryTable.usersColumnsToDisplay"
                [isHyperLinkVisible]="false" [pageName]="userUploadHistoryTable.pageName" [hyperArrayVisible]="false"
                [useClientPagination]="true"
                [serverPageCurrentPage]="userUploadHistoryTable.currentPage"></app-govuk-table>
        </div>

        <a href="javascript:;" class="navigation-text" [routerLink]="['/data-migration/upload']" [queryParams]="{isNewTab:true}">
            Upload a new file</a>
        <br><br>
        <a href="javascript:;" class="navigation-text" [routerLink]="['/home']" [queryParams]="{isNewTab:true}">
            Return to dashboard</a>
    </div>
</div>