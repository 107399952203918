<div class="contact-assign-container">
    <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a href="javascript:;" class="govuk-breadcrumbs__link" routerLink="/home" (click)="onNavigateToHomeClick()">{{ 'ADMINISTRATOR_DASHBOARD' | translate}}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a href="javascript:;" class="govuk-breadcrumbs__link" routerLink="/manage-org/profile" (click)="onNavigateToOrgClick()">{{ 'MANAGE_YOUR_ORGANISATION'
                    |translate}}</a>
            </li>
            <li *ngIf="assigningSiteId != 0" class="govuk-breadcrumbs__list-item">
                <a href="javascript:;" class="govuk-breadcrumbs__link"  [routerLink]="['/manage-org/profile/site/edit']" [queryParams]="{ data: getEditQueryData() }"
                    (click)="onNavigateToSiteClick()">{{ 'EDIT_SITE' | translate
                    }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <span class="govuk-breadcrumbs__link"> {{ 'ASSIGN_CONTACTS' | translate }}</span>
            </li>
        </ol>
    </div>
    <h1 class="govuk-heading-xl page-title"> {{ 'ASSIGN_CONTACTS' | translate }}</h1>
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-full">
            <div>
                <app-govuk-table [headerTextKeys]="contactTableHeaders" [data]="contacts"
                    [dataKeys]="contactColumnsToDisplay" [isCheckBoxVisible]="true" (checkBoxClickEvent)="onCheckBoxClickRow($event)"
                    [useClientPagination]="true">
                </app-govuk-table>
            </div>
        </div>
    </div>
    <div class="govuk-button-group continue-button-group">
        <button type="button" [disabled]="selectedContacts.length == 0" class="govuk-button" data-module="govuk-button" (click)="onContinueClick('Continue')">
            {{ 'CONTINUE_BTN' | translate }}
        </button>
        <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
            data-module="govuk-button">
            {{ 'CANCEL_BTN' | translate }}
        </button>
    </div>
</div>