<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a *ngIf="this.isOrgAdmin" class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ 'ADMINISTRATOR_DASHBOARD' | translate}}
                        </a>
                        <a *ngIf="!this.isOrgAdmin" class="govuk-breadcrumbs__link" routerLink="/home">
                            {{"PUBLIC_PROCUREMENT_GATEWAY_DASHBOARD" | translate}}
                        </a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/profile">{{ 'MANAGE_MY_ACCOUNT' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'DELETE_CONTACT' | translate }}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl"> {{ 'CONFIRM_CONTACT_DELETE' | translate }}</h1>
            <p class="govuk-body">{{ 'THIS_CANNOT_BE_UNDONE' | translate }}</p>
            <div class="govuk-button-group continue-cancel-button-group">
                <button type="submit" (click)="onDeleteConfirmClick('Confirm and delete')" class="govuk-button govuk-button--warning"
                    data-module="govuk-button">
                    {{ 'CONFIRM_DELETE_BTN' | translate }}
                </button>
                <button type="button" (click)="onCancelClick('Cancel')" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button">
                    {{ 'CANCEL_BTN' | translate }}
                </button>
            </div>

        </div>

    </div>
</div>