<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex" *ngIf="item$ | async as i">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">Manage your organisation</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link">Add new registry</span>
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">Confirm your organisation details</h1>
  <br>
  <div>
    <div>
      <p><strong style="font-weight: 500;">{{ i?.name }}</strong></p>
      <p>
        {{ i?.address?.streetAddress }}<br *ngIf="i?.address?.streetAddress" />
        {{ i?.address?.locality }}<br *ngIf="i?.address?.locality" />
        {{ i?.address?.region }}<br *ngIf="i?.address?.region" />
        {{ i?.address?.postalCode }}<br *ngIf="i?.address?.postalCode" />
        {{ i?.address?.countryName }}
      </p>
      <br>
      <p><strong style="font-weight: 500;">{{ schemeName }} registration number</strong><br />{{ i?.identifier?.id }}
      </p>
      <br>
      <div class="govuk-form-group">
        <fieldset class="govuk-fieldset">
          <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
            Is this your organisation?
          </legend>
          <div class="govuk-radios">
            <div class="govuk-radios__item">
              <input id="lblurOrg" class="govuk-radios__input" type="radio" name="orgGroup" checked="checked"
                value="CorrectOrganisation" [(ngModel)]="detailValidityOption">
              <label for="lblurOrg" class="govuk-label govuk-radios__label">
                Yes, and the details are correct
              </label>
            </div>
            <div class="govuk-radios__item">
              <input id="wrngAddress" class="govuk-radios__input" type="radio" name="orgGroup" value="WrongAddress"
                [(ngModel)]="detailValidityOption">
              <label for="wrngAddress" class="govuk-label govuk-radios__label">
                Yes, but the address is wrong
              </label>
            </div>
            <div class="govuk-radios__item">
              <input id="notMyOrg" class="govuk-radios__input" type="radio" name="orgGroup" value="WrongOrganisation"
                [(ngModel)]="detailValidityOption">
              <label for="notMyOrg" class="govuk-label govuk-radios__label">
                No, this isn't my organisation
              </label>
            </div>
          </div>

        </fieldset>
      </div>
      <button type="button" class="govuk-button" data-module="govuk-button"
        (click)="onSubmit('Continue')">Continue</button>
    </div>
  </div>
</div>