<div class="user-profile-container">
    <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a *ngIf="this.isOrgAdmin" class="govuk-breadcrumbs__link" routerLink="/home">
                    {{"ADMINISTRATOR_DASHBOARD" | translate}}
                </a>
                <a *ngIf="!this.isOrgAdmin" class="govuk-breadcrumbs__link" routerLink="/home">
                    {{"PUBLIC_PROCUREMENT_GATEWAY_DASHBOARD" | translate}}
                </a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a href="javascript:;" class="govuk-breadcrumbs__link" routerLink="/profile">{{ "MANAGE_MY_ACCOUNT" |
                    translate }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <span class="govuk-breadcrumbs__link">{{
                    "CONTACT_AN_ADMIN" | translate
                    }}</span>
            </li>
        </ol>
    </div>
    <h1 class="govuk-heading-xl page-title">
        {{ "CONTACT_AN_ADMIN" | translate }}
    </h1>
    <div class="manage-groups-bg">
        <p>Use this list to find the administrators in your organisation.</p>
    </div>
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-full">
            <div class="user-table">
                <app-govuk-table [headerTextKeys]="usersTableHeaders" [data]="userListResponse.userList"
                    [dataKeys]="usersColumnsToDisplay" [isHyperLinkVisible]="true" [hyperLinkText]="'.'"  [pageName]="pageName"
                    (hyperLinkClickEvent)="openEmailWindow($event)" [useServerPagination]="true"
                    [serverPageCount]="pageCount" [serverPageCurrentPage]="currentPage"
                    (changeCurrentPageEvent)="setPage($event)"></app-govuk-table>
            </div>
        </div>
    </div>
    <button type="button" (click)="goBack('Back')" class="govuk-button govuk-button--secondary">{{

        "BACK" | translate

        }}</button>
</div>