<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="user-profile-container">
      <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
          <li class="govuk-breadcrumbs__list-item">
              <a *ngIf="!this.isOrgAdmin" class="govuk-breadcrumbs__link" routerLink="/home">
                {{"PUBLIC_PROCUREMENT_GATEWAY_DASHBOARD" | translate}}
            </a>
            <a *ngIf="this.isOrgAdmin" class="govuk-breadcrumbs__link"  routerLink="/home">    
                {{ 'ADMINISTRATOR_DASHBOARD' | translate }}
            </a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link">Manage your delegated access</span>
          </li>
        </ol>
      </div>
      <div>
        <h1 class="govuk-heading-xl page-title header-bread-align">Manage your delegated access</h1>
        <div class="manage-groups-bg">
          <p class="govuk-body-l"> 
            Switch between your primary and delegating Organisation
          </p>
        </div>
        <div>
          <div class="govuk-form-group">
            <fieldset class="govuk-fieldset">
              <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 class="govuk-fieldset__heading">
                  Available Organisations:
                </h1>
              </legend>
              <div class="govuk-radios" data-module="govuk-radios">
                <div class="govuk-radios__item">
                  <input [(ngModel)]="primaryRoleSelected" class="govuk-radios__input" id="originorganisationname"
                    name="originorganisationname" type="radio" value="primaryselected" (click)="setPrimaryOrg()">
                  <label class="govuk-label govuk-radios__label" for="originorganisationname">
                    {{userDetails.originOrganisationName}} (My organisation)
                  </label>
                </div>
                <div class="govuk-radios__item" *ngFor="let org of organisationList">
                  <input [(ngModel)]="secondaryRoleSelected" class="govuk-radios__input" id="{{org.delegatedOrgName}}"
                    name="{{org.delegatedOrgName}}" type="radio" value="{{org.delegatedOrgId}}"
                    (click)="setSecondaryOrg(org)">
                  <label class="govuk-label govuk-radios__label" for="{{org.delegatedOrgName}}">
                    {{org.delegatedOrgName}} - Access until {{org.endDate | date: 'dd/MM/yyyy'}}
                  </label>
                </div>
              </div>
            </fieldset>
          </div>

        </div>
        <div class="govuk-button-group save-cancel-button-group">
          <button type="submit" class="govuk-button" data-module="govuk-button" (click)="onSubmit('Switch organisations')" [disabled]="isDisabled">
            Switch organisations
          </button>
          <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button"
            (click)="Cancel('Cancel')">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>