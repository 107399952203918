<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <p class="govuk-body-l" *ngIf="reason === 'wrong'">Kindly contact your registry authority to update your organisation details</p>
  <p class="govuk-body-l" *ngIf="reason === 'generic'">An unexpected error has occured. Please try again in a few minutes</p>
  <p class="govuk-body-l" *ngIf="reason === 'notfound'">We could not find any matching records for your search. Please check the details and  try again</p>
  <p class="govuk-body-l" *ngIf="reason === 'unknown'">This Organisation is already registered for Public Procurement Gateway. Contact your Organisation Administrator</p>
  
  <p class="govuk-body-l" *ngIf="reason === 'user'">An unexpected error has occured. we were unable to register a user</p>
  <p class="govuk-body-l" *ngIf="reason === 'contact'">An unexpected error has occured. we were unable to add a contact</p>
  <p class="govuk-body-l" *ngIf="reason === 'conclave'">User already exists on Public Procurement Gateway with this email address. Please try with another email address</p>
  <!-- <p><a routerLink="/manage-org/profile/{{organisationId}}/registry/search">Search with a different organisation ID</a></p> -->
  <!-- <p><a (click)="goBack()">Search with a different organisation ID</a></p> -->
  <p *ngIf="reason !== 'generic'"><a class="navigation-text" routerLink="/manage-org/registry/search">Search with a different organisation ID</a></p>
  <!-- <p *ngIf="reason !== 'generic'"><a class="navigation-text" href="https://www.crowncommercial.gov.uk/contact" target="_blank">Contact CCS</a></p> -->
  <p *ngIf="reason === 'generic'"><a class="navigation-text"  href="javascript:;" (click)="goBack()" >Back</a></p>
</div>
