<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'DUP_ORG' | translate }}</span>
                    </li>
                </ol>
            </div>
            <div>
                <h1 class="govuk-heading-xl page-title">
                    Duplicate organisations registered</h1>
                <p class="govuk-body-l">More than one organisation with that name is registered. Please provide us with
                    one of
                    the identifying options below to proceed.</p>
                <app-cii-options (onOrgSeleceted)="onOrgSelected($event)"></app-cii-options>
            </div>
        </div>
    </div>
</div>