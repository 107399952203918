<div class="contact-assign-site-search-container">
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' |
                            translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">{{
                            'MANAGE_YOUR_ORGANISATION'
                            |translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a href="javascript:;" class="govuk-breadcrumbs__link" (click)="onCancelClick()">{{ 'CONTACT_ASSIGN_SELECTION'
                            |translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'SEARCH_SITES' | translate }}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title"> {{ 'SELECT_SITE_FOR_CONTACTS' | translate }}</h1>
            <p class="govuk-heading-s ">{{ 'SEARCH_AND_SELECT_SITE' | translate }}</p>

            <div class="govuk-form-group" id="search-form-group" [class.govuk-form-group--error]="searchSumbited && searchSiteName ===''">
                <label class="govuk-label" for="search"> {{ 'SEARCH_SITE_BY_NAME' | translate }} </label>
                <span id="first-name-error" class="govuk-error-message"
                *ngIf="searchSumbited && searchSiteName ===''">
                <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> Enter a site name
              </span>
                <app-search-box [(searchText)]="searchSiteName" (onSearchClickEvent)="onSearchClick()" [pageName]="'Assign a site s contacts to your organisation account'">
                </app-search-box>
            </div>
        </div>
    </div>

    <div class="govuk-grid-row">
        <div class="govuk-grid-column-full">
            <div class="site-table">
                <app-govuk-table [headerTextKeys]="siteTableHeaders" [data]="siteData" [dataKeys]="siteColumnsToDisplay"
                    [isRadioVisible]="true" (radioClickEvent)="onSelectRow($event)" [useClientPagination]="true">
                </app-govuk-table>
            </div>
        </div>
    </div>

    <div class="govuk-button-group continue-button-group">
        <button type="button" [disabled]="selectedSiteId == 0" class="govuk-button" data-module="govuk-button"
            (click)="onContinue('Continue')">
            {{ 'CONTINUE_BTN' | translate }}
        </button>
        <button type="button" (click)="onBack('Cancel')" class="govuk-button govuk-button--secondary"
            data-module="govuk-button">
            {{ 'CANCEL_BTN' | translate }}
        </button>
    </div>

</div>