<div>
  <div class="site-top-nav">
    <div class="row">
      <div class="col-md-4">
        <img src="assets/img/logo/White/CCS_WHITE_SML_AW space-01.png" class="nav-logo" />
        <div
          style="display: none; position: inherit; left: 4.43%; top: 11.87%; width: 204px; height: 100px; color: #ffffff; font-size: 20px; font-weight: 300; border-left: 1px solid white; padding-left: 5px; padding-top: 40px; line-height: 21px;">
          <div>Crown<br />Commercial<br />Service</div>
        </div>
      </div>
      <div *ngIf="(this.authService.userAutnenticated$ | async) || this.isAuthenticated" class="sign-out">
        <a (click)="signout()">Sign out</a>
      </div>
      <div class="col-md-8">
        <nav class="navbar navbar-expand navbar-light">
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
              <li *ngIf="!((this.authService.userAutnenticated$ | async) || this.isAuthenticated)" class="nav-item active">
                <a class="nav-link" routerLink="/home">Sign in</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/help-support">Help and support</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div class="v-info-banner">
    <span class="beta-v-bg">BETA</span>
    <span class="beta-v-info">This is a new
      service - your <a href="javascript:;" style="text-decoration: underline;">feedback</a> will help us to improve it.</span>
  </div>
</div>