<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a *ngIf="this.isOrgAdmin" href="javascript:;" class="govuk-breadcrumbs__link" routerLink="/home">
                            {{'ADMINISTRATOR_DASHBOARD' | translate}}
                        </a>
                        <a *ngIf="!this.isOrgAdmin" href="javascript:;" class="govuk-breadcrumbs__link" routerLink="/home">
                            {{"PUBLIC_PROCUREMENT_GATEWAY_DASHBOARD" | translate}}
                        </a>
                    </li>
                    <li *ngIf="operation === operationEnum.MyAccountUpdate || operation === operationEnum.MyAccountContactCreate
                        || operation === operationEnum.MyAccountContactUpdate || operation === operationEnum.MyAccountContactDelete
                        || operation === operationEnum.PasswordChanged" class="govuk-breadcrumbs__list-item">
            <a href="javascript:;" class="govuk-breadcrumbs__link" routerLink="/profile">{{
              'MANAGE_MY_ACCOUNT' | translate
              }}</a>
          </li>
          <li *ngIf="operation === operationEnum.UserCreateWithIdamRegister || operation === operationEnum.UserCreate
                        || operation === operationEnum.UserUpdate || operation === operationEnum.UserUpdateWithIdamRegister
                        || operation === operationEnum.UserPasswordChange || operation === operationEnum.UserDelete
                        || operation === operationEnum.UserDeactivate || operation === operationEnum.UserReactivate"
                        class="govuk-breadcrumbs__list-item">
                        <a href="javascript:;" class="govuk-breadcrumbs__link" routerLink="/manage-users">{{
                            'MANAGE_YOUR_USER_ACCOUNTS' |
                            translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation === operationEnum.PasswordChanged">
                      <a class="govuk-breadcrumbs__link" href="javascript:;" (click)="goBack()">{{ 'CHANGE_PASSWORD_BTN' | translate }}</a>
                  </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation != operationEnum.UserPasswordChange">
                        <span class="govuk-breadcrumbs__link">{{ 'SUCCESS' | translate }}</span>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation === operationEnum.UserPasswordChange">
                        <span class="govuk-breadcrumbs__link">Password reset link sent</span>
                    </li>
                </ol>
            </div>
            <div *ngIf="operation === operationEnum.MyAccountUpdate">
              <legend  *ngIf = "isCustomMfaEnabled"class="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 class="govuk-heading-xl govuk-fieldset__heading">
                {{'CHANGES_SAVED' | translate}}
                </h1>
               </legend>
                <p class="govuk-body-l">{{ 'MY_ACCOUNT_UPDATE_SUCCESS' | translate }}</p>
                <div class="govuk-form-group" *ngIf="approveRequiredRole != 'null' && showRole">
                    <fieldset class="govuk-fieldset">
                      <legend  class="govuk-fieldset__legend" style="font-size: 22px;margin-top:30px;" *ngIf="approveRequiredRole.length > 0">The following {{showRoleView === true ? 'role': (showRoleView == false ? 'service' : '')}} will be assigned after manual validation has been conducted:
                      </legend>
                      <div class="govuk-checkboxes" *ngIf="showRoleView">
                        <ng-container *ngFor="let role of approveRequiredRole">
                          <div class="govuk-__item">
                            <label class="govuk-label">{{role.accessRoleName}}
                              <span
                              *ngIf=" role.serviceName &&
                                role.roleKey != 'JAEGGER_SUPPLIER' &&
                                role.roleKey != 'ACCESS_JAGGAER' &&
                                role.roleKey != 'CAT_USER' &&
                                role.roleKey != 'ACCESS_CAAAC_CLIENT' &&
                                role.roleKey != 'JAEGGER_BUYER' &&
                                role.roleKey != 'JAGGAER_USER'
                              "
                              >- {{ role.serviceName }}</span
                            ></label>
                          </div>
                        </ng-container>
                      </div>
                    </fieldset>
                    <div class="service_view" *ngIf="!showRoleView">
                      <ng-container *ngFor="let role of approveRequiredRole">
                        <div class="govuk-__item">
                          <label class="govuk-label">{{role.accessRoleName}}</label>
                        </div>
                      </ng-container>
                  </div>
                  </div>
                
                  <a [routerLink]="['/profile']" [queryParams]="{isNewTab:true}" class="navigation-text">
                      {{ 'RETURN_TO_MANAGE_MY_ACCOUNT' | translate }} <br></a> 
                      <a [routerLink]="'/home'" class="navigation-text">
                        {{ 'RETURN_TO_DASHBOARD' | translate }}</a>
            </div>
            <div
                *ngIf="operation === operationEnum.MyAccountContactCreate || operation === operationEnum.MyAccountContactUpdate">
                <p class="govuk-body-l">{{ 'USER_CONTACT_UPDATE_SUCCESS' | translate }}</p>
               
                <a [routerLink]="['/profile']" [queryParams]="{isNewTab:true}" class="navigation-text">
                  {{ 'RETURN_TO_MANAGE_MY_ACCOUNT' | translate }}</a> 
            </div>
            <div *ngIf="operation === operationEnum.MyAccountContactDelete">
                <p class="govuk-body-l">{{ 'USER_CONTACT_DELETE_SUCCESS' | translate }}</p>
               
                <a [routerLink]="['/profile']" [queryParams]="{isNewTab:true}" class="navigation-text">
                  {{ 'RETURN_TO_MANAGE_MY_ACCOUNT' | translate }}</a> 
            </div>
            <div *ngIf="operation === operationEnum.PasswordChanged">
                <p class="govuk-body-l"><strong>{{ 'PASSWORD_CHANGE_SUCCESS' | translate }}</strong></p>
                <p class ="govuk-body-l">{{'SIGN_IN_AGAIN' | translate}}</p>
                <p> {{ 'YOU_CAN_NOW' | translate }} <a href="javascript:;" class="navigation-text"
                        (click)="onNavigateToSignInClick()">
                        {{'SING_IN_TO_YOUR_ACCOUNT' | translate }} </a> </p>
            </div>
            <div *ngIf="operation === operationEnum.UserCreateWithIdamRegister 
                || operation === operationEnum.UserUpdateWithIdamRegister">
                <p class="govuk-body-l">{{ 'THE_USER' | translate }}{{ userName }}{{
                    'INSTRUCTIONS_WILL_BE_EMAILED_TO_SETUP_ACCOUNT_PASSWORD' | translate }}</p>
                    <div class="govuk-form-group" *ngIf="approveRequiredRole != 'null' && showRole">
                        <fieldset class="govuk-fieldset">
                          <legend  class="govuk-fieldset__legend" style="font-size: 22px;margin-top:30px;" *ngIf="approveRequiredRole.length > 0">The following {{showRoleView === true ? 'role': (showRoleView == false ? 'service' : '')}} will be assigned after manual validation has been conducted:
                          </legend>
                          <div class="govuk-checkboxes" *ngIf="showRoleView">
                            <ng-container *ngFor="let role of approveRequiredRole">
                              <div class="govuk-__item">
                                <label class="govuk-label">{{role.accessRoleName}}
                                  <span
                                  *ngIf=" role.serviceName &&
                                    role.roleKey != 'JAEGGER_SUPPLIER' &&
                                    role.roleKey != 'ACCESS_JAGGAER' &&
                                    role.roleKey != 'CAT_USER' &&
                                    role.roleKey != 'ACCESS_CAAAC_CLIENT' &&
                                    role.roleKey != 'JAEGGER_BUYER' &&
                                    role.roleKey != 'JAGGAER_USER'
                                  "
                                  >- {{ role.serviceName }}</span
                                ></label>
                              </div>
                            </ng-container>
                          </div>
                        </fieldset>
                        <ng-container *ngFor="let role of approveRequiredRole">
                          <div class="govuk-__item">
                            <label class="govuk-label">{{role.accessRoleName}}</label>
                          </div>
                        </ng-container>
                      </div>
                <a [routerLink]="['/manage-users']" [queryParams]="{isNewTab:true}" class="navigation-text" (click)="onNavigateToManageUserClick()">
                    {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate }}</a>
            </div>
            <div *ngIf="operation === operationEnum.UserCreate">
                <p class="govuk-body-l">{{ 'THE_USER' | translate }}{{ userName }}
                    {{ 'HAS_BEEN_CREATED_SUCCESSFULLY' | translate }}</p>
                <a [routerLink]="['/manage-users']" [queryParams]="{isNewTab:true}" class="navigation-text" (click)="onNavigateToManageUserClick()">
                    {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate}}</a>
            </div>
            <div *ngIf="operation === operationEnum.UserUpdate">
                <p class="govuk-body-l">{{ 'USER_UPDATE_SUCCESS' | translate }}</p>
                    <div class="govuk-form-group" *ngIf="approveRequiredRole != 'null' && showRole">
                        <fieldset class="govuk-fieldset">
                          <legend  class="govuk-fieldset__legend" style="font-size: 22px;margin-top:30px;" *ngIf="approveRequiredRole.length > 0">The following {{showRoleView === true ? 'role': (showRoleView == false ? 'service' : '')}} will be assigned after manual validation has been conducted:
                          </legend>
                          <div class="govuk-checkboxes" *ngIf="showRoleView">
                            <ng-container *ngFor="let role of approveRequiredRole">
                              <div class="govuk-__item">
                                <label class="govuk-label">{{role.accessRoleName}}
                                  <span
                                  *ngIf=" role.serviceName &&
                                    role.roleKey != 'JAEGGER_SUPPLIER' &&
                                    role.roleKey != 'ACCESS_JAGGAER' &&
                                    role.roleKey != 'CAT_USER' &&
                                    role.roleKey != 'ACCESS_CAAAC_CLIENT' &&
                                    role.roleKey != 'JAEGGER_BUYER' &&
                                    role.roleKey != 'JAGGAER_USER'
                                  "
                                  >- {{ role.serviceName }}</span
                                ></label>
                              </div>
                            </ng-container>
                          </div>
                        </fieldset>
                        <ng-container *ngFor="let role of approveRequiredRole">
                          <div class="govuk-__item">
                            <label class="govuk-label">{{role.accessRoleName}}</label>
                          </div>
                        </ng-container>
                      </div>
                <a [routerLink]="['/manage-users']" [queryParams]="{isNewTab:true}" class="navigation-text" (click)="onNavigateToManageUserClick()">
                    {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate }}</a>
            </div>
            <div *ngIf="operation === operationEnum.UserPasswordChange">
                <h1 class="govuk-heading-xl">Password reset link sent</h1>
                <p class="govuk-body-l">{{ 'RESET_EMAIL_HAS_BEEN_SENT_TO' | translate }}{{ userName }}</p>
                <a [routerLink]="['/manage-users']" [queryParams]="{isNewTab:true}" class="navigation-text" (click)="onNavigateToManageUserClick()">
                    {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate}}</a>
                <!-- <div class="govuk-body govuk-!-font-size-19 help-support">{{ 'IF_USER_DOES_NOT_RECEIVE_IT_VIEW' |
                    translate}}
                    <a href="javascript:;" class="navigation-text" routerLink="/help-support"> {{ 'HELP_AND_SUPPORT' | translate}}</a>
                </div> -->
            </div>
            <div *ngIf="operation === operationEnum.UserDelete">
                <p class="govuk-body-l">{{ 'USER_DELETE_SUCCESS' | translate }}</p>
                <!-- <a href="javascript:;" class="navigation-text" (click)="onNavigateToManageUserClick()">
                    {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate }}</a> -->
                    <a [routerLink]="['/manage-users']" [queryParams]="{isNewTab:true}" class="navigation-text" (click)="onNavigateToManageUserClick()">
                      {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate }}</a> 
            </div>
            <div *ngIf="operation === operationEnum.UserDeactivate">
              <p class="govuk-body-l">{{ 'USER_DEACTIVATE_SUCCESS' | translate }}</p>
              <a [routerLink]="['/manage-users']" [queryParams]="{isNewTab:true}" class="navigation-text" (click)="onNavigateToManageUserClick()">
                {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate }}</a>
            </div>
            <div *ngIf="operation === operationEnum.UserReactivate">
              <p class="govuk-body-l">{{ 'USER_REACTIVATE_SUCCESS' | translate }} <br><br> The user will be reactivated now.</p>
              <a [routerLink]="['/manage-users']" [queryParams]="{isNewTab:true}" class="navigation-text" (click)="onNavigateToManageUserClick()">
                {{ 'RETURN_MANAGE_YOUR_USER_ACCOUNTS' | translate }}</a>
            </div>
            <br>
        </div>
    </div>
</div>
