<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="govuk-breadcrumbs">
            <ol class="govuk-breadcrumbs__list">
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <a class="govuk-breadcrumbs__link" routerLink="/manage-users/add-user-selection">{{ 'ADD_USERS' |
                        translate
                        }}</a>
                </li>
                <li class="govuk-breadcrumbs__list-item">
                    <span class="govuk-breadcrumbs__link"> {{ 'BULK_UPLOAD_STATUS' | translate }}</span>
                </li>
            </ol>
        </div>

        <h1 *ngIf="!statusCheckComplete" class="govuk-heading-xl page-title">{{ 'CHECKING_BULK_UPLOAD_STATUS' |
            translate }}</h1>
        <h1 *ngIf="statusCheckComplete && errorDetails.length === 0" class="govuk-heading-xl page-title">{{
            'VALIDATION_SUCCESS_WITHOUT_ERRORS' | translate }}</h1>
        <h1 *ngIf="statusCheckComplete && errorDetails.length !== 0" class="govuk-heading-xl page-title">{{
            'VALIDATION_SUCCESS_WITH_ERRORS' | translate }}</h1>

        <h2 *ngIf="!statusCheckComplete" class="govuk-heading-m page-title">{{ 'WAIT_UNTIL_VALIDATE_FILE' |
            translate }}</h2>
    </div>
</div>

<div *ngIf="statusCheckComplete">
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <h2 class="govuk-heading-s">{{ 'ERROR_DETAILS' | translate }}</h2>
            <div *ngIf="errorDetails.length == 0">
                <p>{{'NONE'| translate}}
                <p>
                <p>{{'YOU_WILL_BE_RECEIVED_AN_EMAIL_ONCE_MIGRATION_COMPLETED'| translate}}
                <p>
            </div>
        </div>
    </div>
    <div *ngIf="errorDetails.length != 0" class="govuk-grid-row">
        <div class="govuk-grid-column-full">
            <div class="user-table">
                <app-govuk-table [headerTextKeys]="errorTableHeaders" [data]="errorsGridInfoList"
                    [dataKeys]="errorColumnsToDisplay" [useClientPagination]="true">
                </app-govuk-table>
            </div>
        </div>
    </div>
</div>

<div>
    <div *ngIf="errorDetails.length != 0" class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <div><a routerLink="/manage-users/bulk-users" class="navigation-text template-link">{{ 'UPLOAD_NEW_FILE' | translate }} </a></div>
        </div>
    </div>
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <div><a routerLink="/manage-users" class="navigation-text template-link">{{ 'GO_TO_MANAGE_USERS' | translate }} </a></div>
        </div>
    </div>
</div>