<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-groups">{{ 'MANAGE_GROUPS' | translate
                            }}</a>
                    </li>
                    <li *ngIf="isEdit==true" class="govuk-breadcrumbs__list-item">
                        <a href="javascript:;" class="govuk-breadcrumbs__link" (click)="onNavigateToGroupClick()">
                            {{ 'EDIT_GROUP' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation=== operationEnum.GroupAdd">
                        <span class="govuk-breadcrumbs__link">Group created successfully</span>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation!= operationEnum.GroupAdd" >
                        <span class="govuk-breadcrumbs__link">Success</span>
                    </li>
                </ol>
            </div>
            <div>
                 <h1 *ngIf="operation=== operationEnum.GroupAdd"  class="govuk-heading-xl page-title ng-tns-c324-9 ng-star-inserted">Group {{GroupName}} created</h1>
                 <h1 *ngIf="operation=== operationEnum.GroupDelete"  class="govuk-heading-xl page-title ng-tns-c324-9 ng-star-inserted">{{GroupName}} deleted</h1>

                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupAdd && showRoleView" >
                    {{ 'GROUP_ADD_SUCCESS' | translate }} {{userCount}} {{ 'USERS_SIMPLE' | translate }} {{ 'AND_SIMPLE' | translate }} {{roleCount}} {{ 'ROLES_SIMPLE' | translate }}
                </p> 
                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupAdd && !showRoleView" >
                    {{ 'GROUP_ADD_SUCCESS' | translate }} {{userCount}} {{ 'USERS_SIMPLE' | translate }} {{ 'AND_SIMPLE' | translate }} {{roleCount}} {{ 'SERVICE_SIMPLE' | translate }}
                </p>
                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupNameUpdate" >{{ 'GROUP_NAME_UPDATE_SUCCESS' | translate }}</p>
                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupUserAdd" >{{ 'GROUP_USER_ADD_SUCCESS' | translate }}</p>
                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupUserUpdate" >{{ 'GROUP_USER_UPDATE_SUCCESS' | translate }}</p>
                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupRoleUpdate && showRoleView" >{{ 'GROUP_ROLE_UPDATE_SUCCESS' | translate }}</p>
                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupRoleUpdate && !showRoleView" >{{ 'GROUP_SERVICE_UPDATE_SUCCESS' | translate }}</p>
                <p class="govuk-body-l" *ngIf="accordionStatus">Please note that additional verification is needed for some of the users to be given access to Fleet Portal service. You can review the details below.</p>
                <div class="govuk-accordion-custom">
                    <div class="govuk-accordion__section_custom  govuk-!-margin-bottom-7">
                        <div class="govuk-accordion__section-heading-custom" *ngIf="accordionStatus">
                            <a class="navigation-text" id="accordion-default-heading-1" href="javascript:void(0)" (click)="toggleAccordion('accordin1')">
                            List of users who were given access to Fleet Portal 
                            </a>
                            <a class="accordian-count navigation-text" href="javascript:void(0)"  (click)="toggleAccordion('accordin1')">{{accordinData.showAccordin1 == false ? 'show all' :  'hide all'}} ({{verifiedUser.rowCount}})</a>
                        </div>
                        <div id="accordion-default-content-1" *ngIf="accordinData.showAccordin1" class="govuk-accordion__section-content-custom">
                            <div>
                                <div class="govuk-grid-row">
                                    <div class="govuk-grid-column-two-thirds">
                                        <div *ngIf="verifiedUser.groupUser.length == 0">
                                            <p>{{'NONE'| translate}}
                                            <p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="verifiedUser.groupUser.length != 0" class="govuk-grid-row">
                                    <div class="govuk-grid-column-full">
                                        <div class="user-table">
                                            <app-govuk-table [headerTextKeys]="tableSetting.usersTableHeaders" [data]="verifiedUser.groupUser"
                                            [dataKeys]="tableSetting.usersColumnsToDisplay" [isHyperLinkVisible]="false"
                                            [useServerPagination]="true" [serverPageCount]="verifiedUser.pageCount"
                                            [serverPageCurrentPage]="verifiedUser.currentPage" (changeCurrentPageEvent)="setPageApprovedUsers($event)"></app-govuk-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="govuk-accordion__section_custom  govuk-!-margin-bottom-7">
                        <div class="govuk-accordion__section-heading-custom" *ngIf="accordionStatus">
                            <a class="navigation-text" id="accordion-default-heading-1" href="javascript:void(0)" (click)="toggleAccordion('accordin2')"  >
                                List of users who require additional verification for Fleet Portal access
                            </a>
                            <a class="accordian-count navigation-text" href="javascript:void(0)"  (click)="toggleAccordion('accordin2')">{{accordinData.showAccordin2 == false ? 'show all' :  'hide all'}} ({{pendingVerificationUser.rowCount}})</a>
                        </div>
                        <div id="accordion-default-content-2" *ngIf="accordinData.showAccordin2"  class="govuk-accordion__section-content-custom">
                            <div>
                                <div class="govuk-grid-row">
                                    <div class="govuk-grid-column-two-thirds">
                                        <div *ngIf="pendingVerificationUser.groupUser.length == 0">
                                            <p>{{'NONE'| translate}}
                                            <p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="pendingVerificationUser.groupUser.length != 0" class="govuk-grid-row">
                                    <div class="govuk-grid-column-full">
                                        <div class="user-table">
                                            <app-govuk-table [headerTextKeys]="tableSetting.usersTableHeaders" [data]="pendingVerificationUser.groupUser"
                                            [dataKeys]="tableSetting.usersColumnsToDisplay" [isHyperLinkVisible]="false"
                                            [useServerPagination]="true" [serverPageCount]="pendingVerificationUser.pageCount"
                                            [serverPageCurrentPage]="pendingVerificationUser.currentPage" (changeCurrentPageEvent)="setPagePendingUsers($event)"></app-govuk-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
                <p class="govuk-body-l" *ngIf="operation=== operationEnum.GroupDelete" >{{ 'GROUP_DELETE_SUCCESS' | translate }} {{GroupName}}</p>

                <ng-container *ngIf="operation === operationEnum.GroupAdd || isEdit">
                    <a [routerLink]="['/manage-groups/view']"  [queryParams]="{ data: getQueryData() }"  class="navigation-text" (click)="onNavigateToGroupClick()">View {{ operation === operationEnum.GroupAdd ? 'new group' : 'group' }}</a><br><br>
                  </ng-container>
               
                <a href="javascript:;"  class="navigation-text" [routerLink]="['/manage-groups']" [queryParams]="{isNewTab:true}">
                    Return to Manage Groups homepage</a>
                    <br><br>
                    <a href="javascript:;"  class="navigation-text" [routerLink]="['/home']" [queryParams]="{isNewTab:true}">
                        Return to dashboard</a>   
                <!-- <a *ngIf="operation=== operationEnum.GroupDelete" class="navigation-text" routerLink="/manage-groups">
                    {{ 'RETURN_TO_MANAGE_GROUPS' | translate }}</a> -->
            </div>
        </div>
    </div>
</div>