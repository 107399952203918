<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' |
                            translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-users">{{ 'MANAGE_YOUR_USER_ACCOUNTS'
                            |translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'DEACTIVATE_USER' | translate }}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl"> {{ 'CONFIRM_USER_DEACTIVATE' | translate }}</h1>
            <div class="govuk-button-group continue-cancel-button-group">
                <button type="submit" (click)="onDeactivateConfirmClick()" class="govuk-button govuk-button--warning"
                    data-module="govuk-button">
                    {{ 'CONFIRM_DEACTIVATE_BTN' | translate }}
                </button>
                <button type="button" [routerLink]="['/manage-users/add-user/details']" [queryParams]="{data: getQueryData()}" (click)="onCancelClick()" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button">
                    {{ 'CANCEL_BTN' | translate }}
                </button>
            </div>

        </div>

    </div>
</div>