<div class="contact-assign-container">
    <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' | translate}}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">{{ 'MANAGE_YOUR_ORGANISATION'
                    |translate}}</a>
            </li>
            <li *ngIf="assigningSiteId != 0" class="govuk-breadcrumbs__list-item">
                <a href="javascript:;" class="govuk-breadcrumbs__link"  [routerLink]="['/manage-org/profile/site/edit']" [queryParams]="{ data: getEditQueryData() }" 
                    (click)="onNavigateToSiteClick()"> {{ 'EDIT_SITE' | translate
                    }}</a>
            </li>
            <li class="govuk-breadcrumbs__list-item">
                <span class="govuk-breadcrumbs__link"> {{ 'ERROR' | translate }}</span>
            </li>
        </ol>
    </div>
    <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
            <p class="govuk-body-l">
                {{ errorCode | translate }}
            </p>
            <a [routerLink]="['/manage-org/profile']" [queryParams]="{isNewTab:true}" *ngIf="assigningSiteId == 0" class="navigation-text">
                {{'RETURN_MANAGE_YOUR_ORGANISATION' | translate }} </a>
            <a href="javascript:;" *ngIf="assigningSiteId != 0" class="navigation-text"
                (click)="onNavigateToSiteClick()">
                {{ 'RETURN_EDIT_SITE' | translate }} </a>
        </div>
    </div>
</div>
