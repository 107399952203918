<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item" *ngIf="isCustomMfaEnabled">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-reg-organisation-mfa">{{ '2FA_SETUP' | translate }}</a>
    </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item" *ngIf="buyerFlow == 3">
      <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{ 'BUYER_TYPE_BC' |
        translate }}</a>
    </li>
     <li class="govuk-breadcrumbs__list-item">
      <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item">
      <a class="govuk-breadcrumbs__link"  href="javascript:;" (click)="goConfirmOrgPage()">{{ 'CONFIRM_ORG_DETAILS' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item" *ngIf="pageAccessMode == 0">
      <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/error/wrong-details">{{ 'WRONG_ORG_DETAILS' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item" *ngIf="pageAccessMode == 2">
      <a class="govuk-breadcrumbs__link" href="javascript:;" (click)="goToRegSchema()">{{ 'ADD_REG' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item">
      <a class="govuk-breadcrumbs__link"   routerLink="/manage-org/register/user">{{ 'CREATE_ADMIN_ACC' | translate }}</a>
     </li>
        <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link"> {{ 'CONFIRM_EMAIL' | translate }}</span>
        </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title" style="
  margin-top: 30px;
  margin-bottom: 30px;
">
    <div class="govuk-heading-xl-custom" *ngIf="!resendActivationEmailMode; else elseBlock">Confirm your email address</div>
    <ng-template #elseBlock>
      Email resend confirmation
    </ng-template>
  </h1>
  <div>
    <p *ngIf="!resendActivationEmailMode; else elseBlockResend" class="text-align"> We've sent an email to {{emailAddress}} to confirm your
      address. Please click on the link in that email to
      continue.</p>
    <ng-template #elseBlockResend>
      <p class="text-align">
        We've resent the activation email to {{emailAddress}} to confirm your address. Please click on the link in that
        email to
        continue.
      </p>
    </ng-template>
  </div>
  <br>
  <p><strong>The link in the email will become invalid after 36 hours and can only be clicked on once</strong></p>
  <p *ngIf="!resendActivationEmailMode"><a  [routerLink]="['/manage-org/register/confirm']" [queryParams]="{ rs: true }"  id="resendLink" class="navigation-text" (click)="resendActivationLink()">If you have not received
      an email, please
      click here to resend</a></p>
  <p><a id="homeLink" class="navigation-text" href="https://www.crowncommercial.gov.uk">Return to Crown Commercial Service homepage</a></p>
</div>