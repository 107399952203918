<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" [routerLink]="['/home']" [queryParams]="{isNewTab:true}" >
                            {{ "ADMINISTRATOR_DASHBOARD" | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" [routerLink]="['/delegated-access']" [queryParams]="{isNewTab:true}">
                            Delegated access</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="RouteData.pageaccessmode ==='remove'">
                        <span class="govuk-breadcrumbs__link">Remove delegated access</span>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="RouteData.pageaccessmode ==='resent'">
                        <span class="govuk-breadcrumbs__link">Confirm resend activation link</span>
                    </li>
                </ol>
            </div>
            <div class="manage-groups-bg page-title"  *ngIf="RouteData.pageaccessmode ==='remove'">
                <h1 class="header-bread-align govuk-heading-l page-title">
                    Confirm you want to remove delegated access for user {{RouteData.userName}}
                </h1>
                <p class="govuk-body-l">This cannot be undone</p>
                <div class="govuk-button-group save-cancel-button-group">
                    <button class="govuk-button govuk-button--warning" data-module="govuk-button" (click)="ConfirmRemoveUser('Confirm and remove')">
                        Confirm and remove
                      </button>
                    <button type="button" class="govuk-button govuk-button--secondary" data-module="govuk-button" routerLink="/delegated-access"  (click)="Cancel('Cancel')">
                        Cancel
                    </button>
                </div>
            </div>
            <div class="manage-groups-bg page-title"  *ngIf="RouteData.pageaccessmode ==='resent'">
                <h1 class="header-bread-align govuk-heading-xl page-title">
                    Confirm resend activation link for a delegated access
                </h1>
                <p class="govuk-body-l">Send new activation link for a delegated access to user {{RouteData.userName}}</p>
                <div class="govuk-button-group save-cancel-button-group">
                    <button type="submit" class="govuk-button" data-module="govuk-button"
                        (click)="ConfirmResentLink('Resend activation link')">
                        Resend activation link
                    </button>
                    <button type="button" class="govuk-button govuk-button--secondary"
                        data-module="govuk-button" routerLink="/delegated-access" (click)="Cancel('Cancel')">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>