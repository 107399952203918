<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ 'ADMINISTRATOR_DASHBOARD' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'MANAGE_YOUR_USER_ACCOUNTS' | translate }}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title">{{ 'MANAGE_YOUR_ORGANISATION_USER_ACCOUNTS' | translate }}</h1>
            <p class="govuk-heading-s ">{{ 'MANAGE_USER_DESCRIPTION' | translate }}</p>

            <button type="button" (click)="onAddClick('Add user')" class="govuk-button govuk-button--secondary"
                data-module="govuk-button">
                {{ isBulkUpload ? 'Add user' : 'ADD_USERS_BTN' | translate }}
            </button>

            <div class="govuk-form-group" id="search-form-group" [class.govuk-form-group--error]="searchSumbited && searchingUserName ===''">
                <label class="govuk-label" for="search"> {{ 'SEARCH_USERS_BY_EMAIL' | translate }} </label>
                <span id="first-name-error" class="govuk-error-message"
                *ngIf="searchSumbited && searchingUserName ===''">
                <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span> Enter a user’s name or email address
              </span>
                <app-search-box [(searchText)]="searchingUserName" (onSearchClickEvent)="onSearchClick()" [pageName]="'Manage User Accounts'">
                </app-search-box>
            </div>
        </div>
    </div>
</div>

<div class="govuk-grid-row">
    <div class="govuk-grid-column-full">
        <div class="user-table">
            <app-govuk-table [headerTextKeys]="usersTableHeaders" [data]="userList.userList"
                [dataKeys]="usersColumnsToDisplay" [isHyperLinkVisible]="true" [hyperLinkText]="'EDIT'"
                (hyperLinkClickEvent)="onEditRow($event)" [useServerPagination]="true" [serverPageCount]="pageCount"
                [serverPageCurrentPage]="currentPage" (changeCurrentPageEvent)="setPage($event)"></app-govuk-table>
        </div>
    </div>
</div>