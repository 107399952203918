<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">{{
          "ADMINISTRATOR_DASHBOARD" | translate
        }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/org-support/search">Organisation user support</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link">Error</span>
      </li>
    </ol>
  </div>
  <div class="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1"
  data-module="govuk-error-summary">
  <h2 class="govuk-error-summary__title" id="error-summary-title">
    There is a problem
  </h2>
  <div class="govuk-error-summary__body">
    <ul class="govuk-list govuk-error-summary__list">
      <li *ngIf="errorCode=='ERROR_CANNOT_REMOVE_ADMIN_ROLE_OR_GROUP_OF_LAST_ADMIN'; else elseBlock">
        Cannot unassign admin role of the last administrator of the organisation.
      </li>
      <ng-template #elseBlock>
        <li>An unexpected error has occurred. Please try again in a few minutes.</li>
      </ng-template>
    </ul>
  </div>
</div>

<p class="contact-ccs"><a href="{{contactUrl}}" target="_blank">Contact CCS</a></p>
<p><a routerLink="/org-support/search">Back</a></p>
</div>
