<div class="govuk-grid-row">
  <div class="govuk-grid-column-two-thirds">
    <div class="content flex">
      <div class="govuk-breadcrumbs">
        <ol class="govuk-breadcrumbs__list">
          <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
          </li>
          <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link"> {{ 'CREATE_ACC' | translate }}</span>
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="govuk-grid-row">
  <div class="govuk-grid-column-full">
    <div class="content-flex">
      <div class="content-left">
        <div class="content flex">
          <h1 class="govuk-heading-xl page-title">Create an account</h1>
          <p class="govuk-body-l">Welcome to the Crown Commercial Service Public Procurement Gateway (PPG).
            <br>This is the new digital home for all of your public procurement services and information.
          </p>
          <p>First, you need to create an administrator account.</p>
          <p>This will allow you to go on and register your organisation to use our services.</p>
          <br>

          <p><strong>Before you start</strong></p>
          <p>We recommend opening the guidance below in another tab.</p>
          <div class="govuk-details__text detail-text">
            <p><strong>Guidance for using the Public Procurement Gateway</strong></p>
            <a href="https://www.crowncommercial.gov.uk/public-procurement-gateway-guides" rel="noreferrer noopener"
              target="_blank" class="navigation-text">See our guide for new users, it has all the
              information you need to get you started.</a>
          </div>

          <p><strong> You will need </strong>a valid company email address and one of the following: </p>
          <ul class=gov-uk-actual-ul>
            <li>Companies House registered number</li>
            <li>Dun and Bradstreet number</li>
            <li>Charity Commission for England and Wales number</li>
            <li>Office of the Scottish Charity Regulator (OSCR) number</li>
            <li>The Charity Commission for Northern Ireland number</li>
            <li>National Health Service Organisations Registry number</li>
            <li>Department for Education Unique Reference Number (URN)</li>
          </ul>
          <a id="startNowButton" href="javascript:;"  (click)="onClick()" role="button" draggable="false" class="govuk-button govuk-button--start" data-module="govuk-button">
            Start now
            <svg class="govuk-button__start-icon" xmlns="http://www.w3.org/2000/svg" width="17.5" height="19" viewBox="0 0 33 40" aria-hidden="true" focusable="false">
              <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z" />
            </svg></a>
        </div>
      </div>
      <!-- <div class="content-right">
        <div class="card card-help">
          <h2 class="card-title">Guides</h2>
          <a href="https://www.crowncommercial.gov.uk/public-procurement-gateway-guides" class="navigation-text"
            rel="noreferrer noopener" target="_blank">Learn about the Public Procurement Gateway, and how to set up an
            account.</a>
        </div>
      </div> -->
    </div>
  </div>
</div>