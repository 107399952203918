<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{
                            'ENTER_DETAIL' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'REG_ORG' | translate }}</span>
                    </li>
                </ol>
            </div>
            <div>
                <h1 class="govuk-heading-xl page-title">
                    Register your organisation</h1>
                <p class="govuk-body">The following screens will take you through the process step by step.</p>
                <p class="govuk-body">
                    To add some basic information takes around ten minutes. To fully register will take longer,
                    as we may need extra information.
                </p>
                <p class="govuk-body">
                    We will tell you what we need when we reach those sections.
                </p>
                <p>
                <div class="govuk-form-group">
                    <button id="continueButton" type="button" class="govuk-button" data-module="govuk-button"
                        (click)="onContinueNotRegistered('Continue')">Continue</button>
                </div>
            </div>
        </div>
    </div>
</div>