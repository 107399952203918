<div class="table-scrolls">
    <div class="table_custom" *ngIf="pageName != 'eventLog'">
    <table class="govuk-table" *ngIf="tableVisibleData.length != 0; else no_results">
        <thead class="govuk-table__head">
            <tr class="govuk-table__row">
                <th scope="col" class="govuk-table__header app-custom-class" *ngFor="let header of headerTextKeys">
                    <span [ngClass]="{'hide_dots': header  === '.'}">{{ header | translate }}</span>
                </th>                
            </tr>
        </thead>
        <tbody class="govuk-table__body">
            <tr class="govuk-table__row" *ngFor="let dataRow of tableVisibleData; let index = index">
                <td class="govuk-table__cell">
                    <span>
                        {{helperservice.convertToLocalDateTime(dataRow.dateOfUpload) | date: 'dd/MM/yyyy'}} <br>
                        <span>{{ helperservice.convertToLocalDateTime(dataRow.dateOfUpload) | date: 'HH:mm'}}</span>
                    </span>
                </td>
                <td class="govuk-table__cell">{{dataRow.name}}</td>
                <td class="govuk-table__cell">{{dataRow.fileName}}</td>
                <td class="govuk-table__cell">{{dataRow.status}}</td>
                <td class="govuk-table__cell table-links custom_table_data" (click)="onTableRowClick(dataRow, index,$event)" > <a class="navigation-text"  [routerLink] = "dataRow.routeLink" [queryParams] ="dataRow.routeData"
                        *ngFor="let childLink of dataRow.link; let index = index">{{childLink}}</a></td>
            </tr>
        </tbody>
    </table>
   </div>
   <div class="table_custom" *ngIf="pageName == 'eventLog'">
    <table class="govuk-table" *ngIf="tableVisibleData.length != 0; else no_results">
        <thead class="govuk-table__head">
            <tr class="govuk-table__row">
                <th scope="col" class="govuk-table__header app-custom-class">Owner</th>
                <th scope="col" class="govuk-table__header app-custom-class">Event</th>
                <th scope="col" class="govuk-table__header app-custom-class">Date</th>
            </tr>
        </thead>
        <tbody class="govuk-table__body">
            <tr class="govuk-table__row" *ngFor="let dataRow of tableVisibleData; let index = index">
                <td class="govuk-table__cell">
                    <div *ngIf="dataRow.actionedBy === 'Admin' && dataRow.eventType != 'DelegationTerminationByDormancy'">
                        <span>{{dataRow.actionedByFirstName}} {{dataRow.actionedByLastName}}</span><br>
                        <span>{{dataRow.actionedByUserName}}</span>
                    </div>
                    <div *ngIf="dataRow.actionedBy != 'Admin' && dataRow.eventType != 'DelegationTerminationByDormancy'">
                        <span>Automatic</span>
                    </div>
                    <div *ngIf="dataRow.eventType === 'DelegationTerminationByDormancy'">
                        <span>Automated dormancy action</span>
                    </div>
                    </td>
                <td class="govuk-table__cell">
                    <span>{{dataRow.eventLog.name}}</span>
                    <div *ngFor="let columnData of dataRow.eventLog.column">
                        <span >{{columnData.name}} : {{ helperservice.convertToLocalDateTime(columnData.date)  | date: 'dd/MM/yyyy'}}</span>
                    </div>
                </td>
                <td class="govuk-table__cell"> 
                    <span> {{helperservice.convertToLocalDateTime(dataRow.actionedOnUtc) | date: 'dd/MM/yyyy'}}</span><br>
                    <span >{{helperservice.convertToLocalDateTime(dataRow.actionedOnUtc) | date: 'HH:mm'}}</span>
                  </td>
            </tr>
        </tbody>
    </table>
   </div>
    <ng-template #no_results>
        <p class="govuk-body govuk-!-font-weight-bold">
            There are no matching results.
        </p>
    </ng-template>
    <div *ngIf="
    (useServerPagination == true || useClientPagination == true) &&
    pageCount &&
    pageCount > 1 &&
    tableVisibleData.length != 0
  " class="pagination-area" aria-label="Pagination">
  <ul>
    <li *ngIf="currentPage != 1" (click)="onSetPageClick(currentPage - 1)">
      <a  href="javascript:;"  aria-label="Previous" class="govuk-pagination__link-title">
        <svg class="govuk-pagination__icon govuk-pagination__icon--prev" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
          <path d="m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z"></path>
        </svg>
        <span>{{ "PREVIOUS" | translate }}</span>
      </a>
    </li>
    <li *ngFor="let page of getPaginationDataForCustom(); let i = index"  [class.active]="page == currentPage" [ngClass]="{'custom-listing': page === '...'}" (click)="onSetPageClick(page)">
      <a *ngIf="page !== '...'" href="javascript:;"  >
        {{ page }}</a>
        <span  *ngIf="page === '...'">{{ page }}</span>
    </li>
    <li  *ngIf="currentPage != pageCount" class="inline-list" (click)="onSetPageClick(currentPage + 1)">
      <a href="javascript:;" 
        aria-label="Next" class="govuk-pagination__link-title">
        <span>{{ "NEXT" | translate }}</span>
        <svg class="govuk-pagination__icon govuk-pagination__icon--next" xmlns="http://www.w3.org/2000/svg" height="13" width="15" aria-hidden="true" focusable="false" viewBox="0 0 15 13">
          <path d="m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z"></path>
        </svg>
      </a>
    </li>
  </ul>
    </div>
</div>