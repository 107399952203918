<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">Administrator dashboard</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">Manage your organisation</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <span class="govuk-breadcrumbs__link">Success</span>
        </li>
    </ol>
  </div>
  <p class="govuk-body-l success_msg">You have successfully removed additional registries from your organisation</p>
  <p><a [routerLink]="['/manage-org/profile']" [queryParams]="{isNewTab:true}" class="navigation-text gov-uk-font-a" style="color: #005ea5;">{{'RETURN_MANAGE_YOUR_ORGANISATION' | translate}}</a></p>
</div>
