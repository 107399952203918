<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{
                            'ENTER_DETAIL' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link"
                            routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'ORG_ADMIN' | translate }}</span>
                    </li>
                </ol>
            </div>
            <h1 class="govuk-heading-xl page-title">
                Set up an administrator for your organisation
            </h1>
            <p>You need <strong>at least</strong> one administrator for your account.
            </p>

            <details class="govuk-details" data-module="govuk-details">
                <summary class="govuk-details__summary">
                    <span class="govuk-details__summary-text">
                        More information on being an administrator
                    </span>
                </summary>
                <div class="govuk-details__text">
                    <p>
                        Being an administrator means you have full rights to all settings and services on this account.
                        You can:


                    </p>
                    <ul class="gov-uk-actual-ul">
                        <li>add, access and delete any data </li>
                        <li>add other administrators</li>
                        <li>
                            set up 'users' - people who can use the service, but with limited access compared to you
                        </li>
                    </ul>
                    <p>
                        We recommend consulting your colleagues and planning who should be administrators. Once you have
                        chosen,
                        be sure to share who is doing the role with the people who will need to know within your team.
                    </p>
                </div>
            </details>

            <div class="govuk-form-group">
                <div class="govuk-radios">
                    <div class="govuk-radios__item" id="orgAdminRadioSelection">
                        <input class="govuk-radios__input" type="radio" id="orgAdminRadioButton" value="useradmin"
                            [(ngModel)]="adminSelectionMode" />
                        <label for="orgAdminRadioButton" class="govuk-label govuk-radios__label">
                            I will be an administrator for this organisation
                        </label>
                    </div>
                    <div class="govuk-radios__item" id="nominateAdminRadioSelection">
                        <input class="govuk-radios__input" type="radio" id="nominateAdminRadioButton"
                            value="nominateadmin" [(ngModel)]="adminSelectionMode" />
                        <label for="nominateAdminRadioButton" class="govuk-label govuk-radios__label">
                            Nominate a colleague to be an administrator
                        </label>
                    </div>
                    <div class="govuk-radios__item" id="unknownAdminRadioSelection">
                        <input class="govuk-radios__input" type="radio" id="unknownAdminRadioButton" value="unkownadmin"
                            [(ngModel)]="adminSelectionMode" />
                        <label for="unknownAdminRadioButton" class="govuk-label govuk-radios__label">
                            We do not know who our administrator will be yet
                        </label>
                    </div>
                </div>
            </div>
            <div class="govuk-form-group">
                <button id="continueButton" type="button" class="govuk-button" data-module="govuk-button"
                    (click)="onContinueClick('Continue')">Continue</button>
            </div>
            <p><a id="homeLink" class="navigation-text" href="https://www.crowncommercial.gov.uk">Return to Crown
                    Commercial Service homepage</a></p>
        </div>

    </div>
</div>