<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">
                            {{ 'ADMINISTRATOR_DASHBOARD' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">
                            {{ 'MANAGE_YOUR_ORGANISATION' | translate }}</a>
                    </li>
                    <li *ngIf="siteId != 0" class="govuk-breadcrumbs__list-item">
                        <a href="javascript:;" class="govuk-breadcrumbs__link" [routerLink]="['/manage-org/profile/site/edit']" [queryParams]="{ data: getQueryData() }" 
                            (click)="onNavigateToSiteClick()">{{ 'EDIT_SITE' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation != operationEnum.CreateOrgContact && !siteCreate && operation != operationEnum.CreateSiteContact && operation != operationEnum.DeleteSiteContact">
                        <span class="govuk-breadcrumbs__link" >Success</span>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation == operationEnum.CreateSiteContact && !siteCreate">
                        <span class="govuk-breadcrumbs__link" >Site contact successfully added</span>
                    </li>
                     <li class="govuk-breadcrumbs__list-item" *ngIf="operation == operationEnum.DeleteSiteContact && !siteCreate">
                        <span class="govuk-breadcrumbs__link" >Site contact successfully deleted</span>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation === operationEnum.CreateOrgContact">
                        <span class="govuk-breadcrumbs__link">Contact created successfully</span>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation === operationEnum.CreateSiteContact && siteCreate">
                        <span class="govuk-breadcrumbs__link" >Site {{siteInfo.siteName}} successfully created</span>
                    </li>
                    <li class="govuk-breadcrumbs__list-item" *ngIf="operation === operationEnum.CreateSite">
                        <span class="govuk-breadcrumbs__link" >Site {{siteInfo.siteName}} successfully created</span>
                    </li>
                </ol>
            </div>
             <h1 *ngIf="operation === operationEnum.CreateOrgContact">Contact created</h1>
             <h1 *ngIf="operation === operationEnum.CreateSite">You have successfully added site {{siteInfo.siteName}}</h1>
            <p class="govuk-body-l" *ngIf="operation === operationEnum.CreateOrgContact">
                {{ 'ADD_ORG_CONTACT_SUCCESS' | translate }}
            </p>
            <p class="govuk-body-l" *ngIf="operation === operationEnum.UpdateOrgContact">
                {{ 'UPDATE_ORG_CONTACT_SUCCESS' | translate }}
            </p>
            <p class="govuk-body-l" *ngIf="operation === operationEnum.DeleteOrgContact">
                {{'DELETE_ORG_CONTACT_SUCCESS' | translate}}
            </p>
            <!-- <p class="govuk-body-l" *ngIf="operation === operationEnum.CreateSite">
                {{ 'ADD_SITE_SUCCESS' | translate }}
            </p> -->
            <p class="govuk-body-l" *ngIf="operation === operationEnum.UpdateSite">
                {{ 'UPDATE_SITE_SUCCESS' | translate }}
            </p>
            <p class="govuk-body-l" *ngIf="operation === operationEnum.DeleteSite">
                {{ 'DELETE_SITE_SUCCESS' | translate }}
            </p>
            <div *ngIf="operation === operationEnum.CreateSiteContact">
                <p class="govuk-body-l" *ngIf="siteCreate">You have successfully added site {{siteInfo.siteName}}</p>
                <p class="govuk-body govuk-!-font-size-19 gov_success_msg">
                    {{ 'ADD_SITE_CONTACT_SUCCESS' | translate }}
                </p>
            </div>
            <p class="govuk-body-l" *ngIf="operation === operationEnum.UpdateSiteContact">
                {{ 'UPDATE_SITE_CONTACT_SUCCESS' | translate }}
            </p>
            <p class="govuk-body-l" *ngIf="operation === operationEnum.DeleteSiteContact">
                {{ 'DELETE_SITE_CONTACT_SUCCESS' | translate }}
            </p>
           
            <a [routerLink]="['/manage-org/profile']" [queryParams]="{isNewTab:true}"  class="navigation-text"> {{
                    'RETURN_MANAGE_YOUR_ORGANISATION' | translate }} </a>  &nbsp;&nbsp;&nbsp;&nbsp;
            
            <a [routerLink]="['/manage-org/profile/site/edit']"  [queryParams]="{ data: getQueryData() }" *ngIf="siteId != 0" class="navigation-text" (click)="onNavigateToSiteClick()">
                Return to {{siteInfo.siteName}} details </a>
            <br>
        </div>
    </div>
</div>