<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="user-profile-container">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'ADMINISTRATOR_DASHBOARD' |
                            translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/profile">{{
                            'MANAGE_YOUR_ORGANISATION'
                            |translate}}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'REMOVE_IDP' | translate }}</span>
                    </li>
                </ol>
            </div>


            <h1 class="govuk-heading-xl"> {{ 'CONFIRM_REMOVE_SIGN_IN_PROVIDER' | translate }}</h1>
            <p class="govuk-body">
                Removing sign in provider(s) {{idpNames}} will affect the users within your organisation. <br/>
                All users will receive an email notifying them that {{idpNames}} will no longer be available as a sign-in option. <br/>
                The users who are currently logged in using the removed {{idpNames}} will be:
            </p>

            <ul class="list-content">
                <li>
                    Logged out within 10 minutes after you action this and their workflows may be interrupted
                </li>
                <li>
                    Required to configure a new sign-in provider when they next sign-in
                </li>
            </ul>
            <p class="govuk-body">
                To minimise the impact to your users, consider doing this action out of hours.
            </p>

            <p class="govuk-body">{{ 'DO_YOU_WANT_TO_PROCEED' | translate }}</p>
            <div class="govuk-button-group continue-cancel-button-group">
                <button type="submit" (click)="onRemoveIdpConfirmClick('Yes')" class="govuk-button"
                    data-module="govuk-button">
                    {{ 'YES_DELETE_BTN' | translate }}
                </button>
                <button type="button" (click)="onCancelClick('No')" class="govuk-button govuk-button--secondary"
                    data-module="govuk-button">
                    {{ 'NO_DELETE_BTN' | translate }}
                </button>
            </div>

        </div>

    </div>
</div>