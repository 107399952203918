<div class="content-flex">
    <div class="govuk-form-group">
        <fieldset class="govuk-fieldset">
            <legend class="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 class="govuk-fieldset__heading">
                {{'SET_UP_APP' | translate}}
                </h1>
            </legend>
            <p class="govuk-body-l">
                {{'USE_AUTHENTICATOR_DESC' | translate }}

            </p>
            <details class="govuk-details" data-module="govuk-details">
                <summary class="govuk-details__summary">
                    <span class="govuk-details__summary-text">
                        {{'QR_CODE_SCAN_ERROR' | translate}}
                    </span>
                </summary>
                <div class="govuk-details__text">
                    You can enter the secret key into your authenticator app instead.Secret key: {{secretCode}}
                </div>
            </details>
            <qrcode [qrdata]="mfaQrCode" [width]="256" [errorCorrectionLevel]="'M'" [allowEmptyString]="true">
            </qrcode>
            <form [formGroup]="formGroup" (ngSubmit)="onContinueBtnClick(otp.value)">
                <div class="govuk-text-input-group" id="otp-form-group"
                    [class.govuk-form-group--error]="submitted &&formGroup.controls.otp.invalid">
                    <span id="invalid-otp-error" class="govuk-error-message"
                        *ngIf="submitted && formGroup.controls.otp.invalid">
                        <span class="govuk-visually-hidden">{{ 'ERROR_PREFIX' | translate }}</span>Please provide valid
                        code
                    </span>
                    <label class="govuk-label" for="authenticator-otp"> Enter the 6-digit security code shown in the authenticator
                        app.</label>
                    <input class="govuk-input govuk-input govuk-input--width-20" maxlength="6" id="authenticator-otp" name="otp"
                        type="text" formControlName="otp" #otp (input)="clearError()"
                        [class.govuk-input--error]="submitted &&formGroup.controls.otp.invalid">
            </div>
            <div class="govuk-button-group">
                <button type="submit" class="govuk-button"
                (click)="pushDataLayerEvent('Continue')" [disabled]="formGroup.controls.otp.invalid" data-module="govuk-button">
                    {{ 'CONTINUE_BTN' | translate }}
                </button>
                <button type="button" class="govuk-button govuk-button--secondary" (click)="onBackBtnClick('Back')"
                    data-module="govuk-button">
                    {{ 'BACK' | translate }}
                </button>
            </div>
            <div class="govuk-2mfa-link">
                <a href="javascript:;" class="navigation-text" (click)="onNavigateToMFAClick()">
                    {{'BACK_TO_2MFA' | translate }}
                </a>
            </div>
            </form>

        </fieldset>
    </div>
   

</div>
