<app-environment-banner></app-environment-banner>
<app-cookies-banner></app-cookies-banner>
<header class="header-global" id="totop">
    <div class="ccs-width-container">
        <div class="nav-wrapper">
            <a [href]="home" class="logo"><span class="header_logo" alt="Go to the CCS homepage">
            </span>
            <span class="visually-hidden">Go to the CCS homepage</span>
        </a>
            <nav class="global-navigation" data-toggle="nav"
                data-app-uri="http://www.crowncommercial.gov.uk/contact/sendToSalesforce"
                data-app-pathinfo="/contact/sendToSalesforce" aria-hidden="false">

                <ul class="nav-list nav-list--primary">
                    <li class="nav-list__item header-link"
                        *ngIf="!((this.authService.userAutnenticated$ | async) || this.isAuthenticated)">
                        <a href="javascript:;" class="nav-list__link  " (click)="signoutAndRedirect()">Sign in</a>
                    </li>
                    <li class="nav-list__item header-link"
                        *ngIf="(this.authService.userAutnenticated$ | async) || this.isAuthenticated">
                        <a href="javascript:;" class="nav-list__link  " (click)="signoutAndRedirect()">Sign out</a>
                    </li>
                    <!-- <li class="nav-list__item header-link">
                        <a class="nav-list__link" routerLink="/help-support">Help and support</a>
                    </li> -->
                </ul>
            </nav>
        </div>
    </div>
</header>
<div class="govuk-width-container">
    <div class="govuk-phase-banner">
        <p class="govuk-phase-banner__content">
            <strong class="govuk-tag govuk-phase-banner__content__tag">
                beta
            </strong>
            <span class="govuk-phase-banner__text">This is a new service – your <a class="navigation-text"
                    target="_blank"
                    href="https://crowncommercial.qualtrics.com/jfe/form/SV_3xDq7uNywwujVRA">feedback</a> will help us
                to improve it.</span>
        </p>
    </div>
    <br>
    <main class="govuk-main-wrapper">
        <div style="margin-top: -35px;">
            <router-outlet>
                <div *ngIf="loadingIndicatorService.isLoading | async" class="overlay">
                    <div class="center" [class.loader]="loadingIndicatorService.isLoading | async">
                    </div>
                </div>
            </router-outlet>
        </div>
    </main>
</div>

<footer class="govuk-footer footer-global footer" role="contentinfo">
    <div class="govuk-width-container ">
        <div class="govuk-footer__meta">
            <div class="govuk-footer__meta-item">
                <a class="govuk-footer__link logo"[href]="home">
                    <span class="header_logo" alt="Go to the CCS homepage"></span>
                    <span class="visually-hidden">Go to the CCS homepage</span>
                </a>
            </div>
            <div class="govuk-footer__meta-item govuk-footer__meta-item--grow right-content">
                <ul class="govuk-list">
                    <li>
                        <ul class="govuk-footer__inline-list">
                            <li class="govuk-footer__inline-list-item">
                                <a href="https://twitter.com/gov_procurement" class="govuk-footer__link">
                                    <div class="twitter_icon">
                                       
                                    </div>
                                    <span class="visuallyhidden">Follow us on Twitter</span>
                                </a>
                            </li>
                            <li class="govuk-footer__inline-list-item">
                                <a href="https://www.linkedin.com/company/government-procurement-service/"
                                    class="govuk-footer__link">
                                    <div class="linked_icon">
                                    </div>
                                    <span class="visuallyhidden">Connect with us on LinkedIn</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul class="govuk-footer__inline-list">
                            <li class="govuk-footer__inline-list-item">
                                <a class="govuk-footer__link" referrerpolicy="unsafe-url" href="{{ccsContactUrl}}">Contact CCS</a>
                            </li>
                            <li class="govuk-footer__inline-list-item">
                                <a class="govuk-footer__link" routerLink="/cookies">Cookies</a>
                            </li>
                            <li class="govuk-footer__inline-list-item">
                                <a class="govuk-footer__link"
                                    href="https://www.gov.uk/government/publications/crown-commercial-service-privacy-notice">Privacy
                                    Policy</a>
                            </li>
                             <li class="govuk-footer__inline-list-item">
                                <a class="govuk-footer__link"
                                  routerLink="/terms-of-use">Terms of Use</a>
                            </li> 
                            <li class="govuk-footer__inline-list-item">
                                <a class="govuk-footer__link" routerLink="/accessibility-statement">Accessibility
                                    Statement</a>
                            </li>
                            <li class="govuk-footer__inline-list-item">
                                <a class="govuk-footer__link"
                                    href="https://www.nationalarchives.gov.uk/information-management/re-using-public-sector-information/uk-government-licensing-framework/crown-copyright/">©
                                    Crown copyright</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="(this.authService.userAutnenticated$ | async) || this.isAuthenticated">
        <iframe aria-hidden="true" style="position: absolute;width:0;height:0;border:0;" [src]="opIFrameURL"
            id="opIFrame"></iframe>
        <iframe aria-hidden="true" style="position: absolute;width:0;height:0;border:0;" [src]="rpIFrameURL"
            id="rpIFrame"></iframe>
    </div>
</footer>