<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{ 'ENTER_DETAIL' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
            <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
        </li>
        <li class="govuk-breadcrumbs__list-item">
          <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' | translate }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/type">{{ 'ORG_TYPE' | translate }}</a>
     </li>
     <li class="govuk-breadcrumbs__list-item" *ngIf="buyerFlow == 3">
        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/buyer-type">{{ 'BUYER_TYPE_BC' |
          translate }}</a>
      </li>
     <li class="govuk-breadcrumbs__list-item">
      <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/search">{{ 'ORG_DETAILS' | translate }}</a>
     </li>
        <li class="govuk-breadcrumbs__list-item">
            <span class="govuk-breadcrumbs__link"> {{ 'NOT_FOUND' | translate }}</span>
        </li>
    </ol>
  </div>
  <div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
  <p class="govuk-body-l">We have not been able to retrieve the organisation's details from Companies House, 
    please either select an alternative registration service or contact the Service Desk.</p>
  </div>
  </div>
  <p><a id="searchWithDifferentIdLink" class="navigation-text" routerLink="/manage-org/register/search">Search with a different organisation ID</a></p>
  <p><a id="submitClaimLink" href="https://www.crowncommercial.gov.uk/contact" target="_blank">Contact CCS</a></p>
</div>