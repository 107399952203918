<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="content flex" *ngIf="org$ | async as o">
  <div class="govuk-breadcrumbs">
    <ol class="govuk-breadcrumbs__list">
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/home">{{
          "ADMINISTRATOR_DASHBOARD" | translate
        }}</a>
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <a class="govuk-breadcrumbs__link" routerLink="/buyer-supplier/search"
          >Manage service eligibility</a
        >
      </li>
      <li class="govuk-breadcrumbs__list-item">
        <span class="govuk-breadcrumbs__link"
          >Review service roles for the organisation</span
        >
      </li>
    </ol>
  </div>
  <h1 class="govuk-heading-xl page-title">
    Review service roles for the organisation
  </h1>
  <div class="govuk-notification-banner" role="region" aria-labelledby="govuk-notification-banner-title" data-module="govuk-notification-banner" *ngIf="autoValidationPending">
    <div class="govuk-notification-banner__header">
      <h2 class="govuk-notification-banner__title" id="govuk-notification-banner-title">
        Organisation Under Review
      </h2>
    </div>
    <div class="govuk-notification-banner__content">
      <p class="govuk-notification-banner__heading">
        This organisation is currently awaiting verification of its buyer status. You won't be able to manage this organisation's eligibility until the verification process is complete.
      </p>
    </div>
  </div>
  <div class="govuk-form-group">
    <fieldset class="govuk-fieldset">
      <legend class="govuk-fieldset__legend">
        Update organisation type
      </legend>
        <div class="govuk-radios">
            <div class="govuk-radios__item" id="orgAdminRadioSelection" >
                <input class="govuk-radios__input" type="radio" id="supplierRadioButton" value="0"
                    [(ngModel)]="adminSelectionMode" (click)="onSelect(0,'html')" [attr.disabled]="autoValidationPending"/>
                <label for="supplierRadioButton" class="govuk-label govuk-radios__label">
                  Supplier
                </label>
            </div>
            <div class="govuk-radios__item" id="nominateAdminRadioSelection">
                <input class="govuk-radios__input" type="radio" id="buyerRadioButton" value="1"
                    [(ngModel)]="adminSelectionMode"  (click)="onSelect(1,'html')" [attr.disabled]="autoValidationPending" />
                <label for="buyerRadioButton" class="govuk-label govuk-radios__label">
                  Buyer
                </label>
            </div>
            <div class="govuk-radios__item" id="unknownAdminRadioSelection">
                <input class="govuk-radios__input" type="radio" id="bothRadioButton" value="2"
                    [(ngModel)]="adminSelectionMode" (click)="onSelect(2,'html')"  [attr.disabled]="autoValidationPending"/>
                <label for="bothRadioButton" class="govuk-label govuk-radios__label">
                  Both
                </label>
            </div>
        </div>
    </fieldset>
  </div>
  <div
  class="govuk-form-group"
  style="margin-top: 30px"
>
  <fieldset class="govuk-fieldset">
    <legend class="govuk-fieldset__legend">
      Roles for this organisation, select/unselect where appropriate
    </legend>
    <div class="govuk-checkboxes" *ngIf="orgEligableRoles$ | async as o">
      <ng-container *ngFor="let role of roles">
        <div
          class="govuk-checkboxes__item"
          *ngIf="role.subscriptionTypeEligibility === 0 &&  role.orgTypeEligibility !== 0 && tradeEligibilityStatus(role) && role.isDeleted === false">
          <input
            type="checkbox"
            [value]="role.roleId"
            [checked]="role.enabled"
            (change)="onChange($event, role?.enabled, role)"
            class="govuk-checkboxes__input"
            id="orgRoleControl_{{ role.roleId }}"
            [attr.disabled]="autoValidationPending"
          />
          <label
            class="govuk-label govuk-checkboxes__label"
            for="orgRoleControl_{{ role.roleId }}"
            >{{ role.roleName }}
            <span
                *ngIf=" role.serviceName &&
                  role.roleKey != 'JAEGGER_SUPPLIER' &&
                  role.roleKey != 'ACCESS_JAGGAER' &&
                  role.roleKey != 'CAT_USER' &&
                  role.roleKey != 'ACCESS_CAAAC_CLIENT' &&
                  role.roleKey != 'JAEGGER_BUYER' &&
                  role.roleKey != 'JAGGAER_USER'
                "
                >- {{ role.serviceName }}</span
              ></label>
        </div>
      </ng-container>
    </div>
    <legend class="govuk-fieldset__legend" style="margin-top: 30px">
      Service roles for this organisation, select/unselect where appropriate
    </legend>
    <div class="govuk-checkboxes" *ngIf="orgEligableRoles$ | async as o">
      <ng-container *ngFor="let role of roles">
        <div
          class="govuk-checkboxes__item"
          *ngIf="
            role.subscriptionTypeEligibility === 1 &&
            role.orgTypeEligibility !== 0 &&
            tradeEligibilityStatus(role) && role.isDeleted === false 
          "
        >
          <input
            type="checkbox"
            [value]="role.roleId"
            [checked]="role.enabled"
            (change)="onChange($event, role?.enabled, role)"
            class="govuk-checkboxes__input"
            id="orgRoleControl_{{ role.roleId }}"
            [attr.disabled]="autoValidationPending"
          />
          <label
            class="govuk-label govuk-checkboxes__label"
            for="orgRoleControl_{{ role.roleId }}">
                    {{role.roleName}}
                    <span
                    *ngIf=" role.serviceName &&
                      role.roleKey != 'JAEGGER_SUPPLIER' &&
                      role.roleKey != 'ACCESS_JAGGAER' &&
                      role.roleKey != 'CAT_USER' &&
                      role.roleKey != 'ACCESS_CAAAC_CLIENT' &&
                      role.roleKey != 'JAEGGER_BUYER' &&
                      role.roleKey != 'JAGGAER_USER'
                    "
                    >- {{ role.serviceName }}</span
                  ></label>
        </div>
      </ng-container>
    </div>
  </fieldset>
</div>
  <div class="govuk-button-group" style="margin-top: 40px">
    <button
     *ngIf="!autoValidationPending"
      type="button"
      class="govuk-button"
      data-module="govuk-button"
      (click)="onSubmitClick('Continue')"
    >
      Continue
    </button>
    <button
      type="button"
      class="govuk-button govuk-button--secondary"
      data-module="govuk-button"
      (click)="onCancelClick('Cancel')"
    >
      Cancel
    </button>
  </div>
</div>