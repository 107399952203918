<div class="cookies_section">
<div class="govuk-cookie-banner " data-nosnippet role="region" aria-label="Cookies on [name of service]" *ngIf="cookiesData.coockiebanner">
    <div class="govuk-cookie-banner__message govuk-width-container">
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
          <h2 class="govuk-cookie-banner__heading govuk-heading-m">Cookies on Public Procurement Gateway</h2>
          <div class="govuk-cookie-banner__content">
            <p class="govuk-body">We use some essential cookies to make this service work.</p>
            <p class="govuk-body">We’d like to set additional cookies so we can remember your settings, understand how people use the service and make improvements.</p>
          </div>
        </div>
      </div>
      <div class="govuk-button-group">
        <button value="accept" type="button" name="cookies" class="govuk-button" (click)="acceptCookies('Accept additional cookies')">
          Accept additional cookies
        </button>
        <button value="reject" type="button" name="cookies" class="govuk-button" (click)="rejectCookies('Reject additional cookies')">
          Reject additional cookies
        </button>
        <a class="govuk-link" routerLink="/cookies">View cookies</a>
      </div>
    </div>
  </div>

                <!-- accepted additional cookies. -->

  <form *ngIf="cookiesData.acceptAnalyticsCookies">
    <div class="govuk-cookie-banner " data-nosnippet role="region" aria-label="Cookies on [name of service]" >
      <div class="govuk-cookie-banner__message govuk-width-container">
        <div class="govuk-grid-row">
          <div class="govuk-grid-column-two-thirds">
            <div class="govuk-cookie-banner__content">
              <p class="govuk-body">You’ve accepted additional cookies. You can <a class="govuk-link" routerLink="/cookies">change your cookie settings</a> at any time.</p>
            </div>
          </div>
        </div>
        <div class="govuk-button-group">
          <button type="button"  class="govuk-button" (click)="hideCookies('Hide this message')">
            Hide this message
          </button>
        </div>
      </div>
    </div>
  </form>

                <!-- reject additional cookies. -->

  <form *ngIf="cookiesData.rejectAnalyticsCookies">
    <div class="govuk-cookie-banner " data-nosnippet role="region" aria-label="Cookies on [name of service]" >
      <div class="govuk-cookie-banner__message govuk-width-container">
        <div class="govuk-grid-row">
          <div class="govuk-grid-column-two-thirds">
            <div class="govuk-cookie-banner__content">
              <p class="govuk-body">You’ve rejected analytics cookies. You can <a class="govuk-link" routerLink="/cookies">change your cookie settings</a> at any time.</p>
            </div>
          </div>
        </div>
        <div class="govuk-button-group">
          <button  type="button"  class="govuk-button" (click)="hideCookies('Hide this message')">
            Hide this message
          </button>
        </div>
      </div>
    </div>
  </form>
</div>