<!--<app-sidenav [collapsed]="sideNavVisible$ | async"></app-sidenav>-->
<div class="govuk-grid-row">
    <div class="govuk-grid-column-two-thirds">
        <div class="content flex">
            <div class="govuk-breadcrumbs">
                <ol class="govuk-breadcrumbs__list">
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/home">{{ 'REGITERATION_HOME' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register">{{ 'CREATE_ACC' | translate
                            }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/initial-search">{{
                            'ENTER_DETAIL' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link"
                            routerLink="/manage-org/register/initial-search-status/new">{{ 'REG_ORG' | translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <a class="govuk-breadcrumbs__link" routerLink="/manage-org/register/newreg">{{ 'ORG_ADMIN' |
                            translate }}</a>
                    </li>
                    <li class="govuk-breadcrumbs__list-item">
                        <span class="govuk-breadcrumbs__link"> {{ 'FIND_ADMIN' | translate }}</span>
                    </li>
                </ol>
            </div>
            <div>
                <h1 class="govuk-heading-xl page-title">
                    How to find your administrator</h1>
                <p>
                    It will be someone who works for your organisation. We can't tell you who your administrator is, but
                    you should ask:
                </p>

                <ul class="govuk-list govuk-list--bullet gov-uk-actual-ul">
                    <li>the person who gave you your work email account</li>
                    <li>someone in your IT or HR department</li>
                    <li>your direct line manager</li>
                    <li>someone in your procurement team</li>
                    <li>the person who manages your email service or website</li>
                </ul>
                <p class="govuk-body-l"></p>
                <div class="govuk-form-group">
                    <button id="continueButton" type="button" class="govuk-button" data-module="govuk-button"
                        (click)="goBack('Go back')">Go back</button>
                    <p><a id="homeLink" class="navigation-text" href="https://www.crowncommercial.gov.uk/">Return
                            to Crown Commercial Service homepage</a></p>
                </div>
            </div>
        </div>
    </div>